<template>
  <v-container>
    <v-row class="my-0">
      <!-- Cuadro de adjuntar -->
      <v-col cols="12" md="4" lg="3">
        <!-- Archivos adjuntos -->
        <p class="primary--text body-2 front-weight-black">
          {{ $t('attachments') }}: {{ total }}
        </p>

        <input
          id="repository--add-input"
          class="d-none"
          accept="image/*"
          multiple
          outlined
          type="file"
          @change="selectFile"
        />
        <div v-if="repositoryBtn.add">
          <div class="repository--image-container mt-3">
            <i-image
              :style="{
                height: '16em !important'
              }"
              :file.sync="imagedrop"
              :repository="true"
              v-model="imageUrl"
            />

            <!-- Botón de adjuntar archivos -->
            <label
              v-if="repositoryBtn.add"
              class="repository--image-add"
              for="repository--add-input"
              :title="$t('attach_images')"
            >
              <v-icon color="primary">fa-camera</v-icon>
            </label>
          </div>
          <!-- Barra de progreso -->
          <div
            v-if="imagedrop !== null"
            class="progress-bar progress-bar-info my-3 rounded"
            aria-valuemax="100"
            aria-valuemin="0"
            role="progressbar"
            :aria-valuenow="progress"
            :style="{ width: progress + '%' }"
          >
            {{ progress }}%
          </div>
        </div>
        <div
          v-if="repositoryBtn.edit && repositoryBtn.add && imagedrop !== null"
        >
          <ValidationProvider
            vid="nameDrop"
            :name="$tc('name', 1)"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              color="secondary"
              outlined
              :error-messages="errors[0]"
              :label="$t('editing', { value: $tc('name', 1) })"
              v-model="nameImage"
            />
          </ValidationProvider>
          <p>
            <v-btn color="primary" @click="submit(imagedrop, undefined)">
              {{ $tc('upload_image') }}
            </v-btn>
          </p>
        </div>
      </v-col>

      <!-- Lista de imágenes -->
      <v-col cols="12" md="8" lg="9">
        <div
          v-if="labels.length > 0 && repositoryBtn.labelView"
          class="d-flex g-1 pb-3"
        >
          <v-chip color="secondary" text-color="white" @click="selectLabel()">
            {{ `${$tc('all', 1)} ` }}
          </v-chip>
          <v-chip
            v-for="label in labels"
            color="secondary"
            text-color="white"
            :close="label.canDelete && repositoryBtn.labelDelete"
            :key="'chips-' + label.pk"
            @click="selectLabel(label.pk)"
            @click:close="removeChip(label)"
          >
            {{ label.nameLabel }}
          </v-chip>
        </div>
        <i-card-list
          id="repo-buttons"
          class="mt-0 px-2"
          api="suborder.imageRepository"
          app="suborder.imageRepository"
          donEdit
          dontCreate
          dontHeader
          :method="'show'"
          :opt="{
            subOrder: true,
            label: select_label !== '' ? select_label : null
          }"
          :reload.sync="reload"
          :requestOpts="{ pk: subOrderPk }"
          :select="selected"
          :title="$tc('settingTemplate.image', 2)"
          :totalItems.sync="total"
        >
          <template v-slot:[`item`]="{ item, showMode }">
            <!-- Nombre -->
            <p class="primary--text mb-1">
              <span class="font-weight-black">
                {{ `${$tc('name', 1)}: ` }}
              </span>
              <span
                class="subtitle-2"
                v-if="!(item.pk == toEdit?.pk && isEditable)"
              >
                {{ item.nameImage }}
              </span>
              <v-text-field
                v-else
                v-model="item.nameImage"
                outlined
                color="secondary"
              />
            </p>

            <!-- Tipo -->
            <p class="primary--text mb-1">
              <span class="font-weight-black">
                {{ `${$tc('type', 1)}: ` }}
              </span>
              <span class="subtitle-2">
                {{ item.imageType }}
              </span>
            </p>

            <!-- Tamaño -->
            <p class="primary--text mb-1">
              <span class="font-weight-black">
                {{ `${$tc('settingTemplate.size.size', 1)}: ` }}
              </span>
              <span
                v-if="item.size >= 1000 && item.size < 100000"
                class="subtitle-2"
              >
                {{ Number(item.size / 1000).toFixed(1) }} {{ `KB` }}
              </span>
              <span v-if="item.size >= 100000" class="subtitle-2">
                {{ Number(item.size / (1000 * 1024)).toFixed(1) }}{{ `MB` }}
              </span>
            </p>

            <!-- Imagen -->
            <div
              v-if="item !== undefined && showMode !== 'simple'"
              @click="downloadImage(false, item)"
            >
              <v-skeleton-loader
                v-if="load || loadingIMG"
                type="image"
                tile
              ></v-skeleton-loader>
              <i-image
                v-else
                :style="{
                  width: '100% !important',
                  maxWidth: '12em !important',
                  height: '12em !important'
                }"
                :file.sync="item.image"
                :preview="true"
                :readonly="true"
                v-model="item.image"
              />
            </div>

            <!-- Etiqueta de la imagen -->
            <div class="d-flex g-1 py-3">
              <v-chip
                v-if="
                  repositoryBtn.edit &&
                  repositoryBtn.labelView &&
                  item.label !== null &&
                  labels[labels.findIndex((m) => m.pk === item.label)]
                "
                :close="repositoryBtn.labelDelete"
                @click:close="
                  repositoryBtn.labelDelete ? removeChip2(item) : ''
                "
              >
                {{
                  labels[labels.findIndex((m) => m.pk === item.label)].nameLabel
                }}
              </v-chip>
            </div>
            <div
              v-if="item.pk == toEdit?.pk && isEditable"
              class="d-flex flex-row g-3 align-items-center mb-3"
              :key="item.pk"
            >
              <diallabel
                v-model="dialog2"
                :to-edit="toEdit2"
                :subOrder="subOrderPk"
                @done="addLabels"
              />
              <v-autocomplete
                class="secondary--text"
                autocomplete="off"
                item-text="nameLabel"
                item-value="pk"
                key="label-input"
                outlined
                :clearable="labels.length > 10"
                hide-details
                :items="labels"
                :label="$tc('label', 1)"
                :loading="isLoading"
                :append-icon="labels.length > 10 ? 'fa-search' : ''"
                :prepend-inner-icon="repositoryBtn.labelAdd ? 'fa-plus' : ''"
                @click:prepend-inner="open()"
                v-model="imageRepository.label"
              >
              </v-autocomplete>
              <i-btn
                v-if="item.pk == toEdit?.pk && isEditable"
                class="mr-3"
                color="primary"
                icon="fa-save"
                outlined
                small
                :title="$tc('save', 1)"
                @click="submit(undefined, item)"
              />
            </div>
            <i-btn
              class="mr-3"
              color="secondary"
              icon="fa-download"
              outlined
              small
              :title="$t('download')"
              @click="downloadImage(true, item)"
            />
            <i-btn
              v-if="repositoryBtn.edit"
              class="mr-3"
              color="info"
              outlined
              small
              :icon="'mdi-pencil'"
              :title="$tc('edit', 1)"
              @click="edit(item)"
            />
          </template>
        </i-card-list>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import diallabel from './dialLabel.vue'
export default {
  components: {
    diallabel
  },
  props: {
    subOrderPk: {
      type: Number
    },
    dontClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedFiles: undefined,
      message: '',
      total: 0,
      reload: false,
      load: false,
      isLoading: false,
      loadingIMG: false,
      dialog: false,
      toEdit: null,
      dialog2: false,
      toEdit2: null,
      imagedrop: null,
      imageUrl: null,
      label: '',
      labels: [],
      select_label: '',
      nameImage: '',
      selected: 0,
      progress: 0,
      imageRepository: {
        pk: '',
        label: '',
        image: null,
        subOrder: this.subOrderPk,
        nameImage: '',
        size: 0,
        imageType: ''
        //date: new Date().toISOString().substr(0, 10),
        //lastModifiedDate: file.lastModifiedDate
      }
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    repositoryBtn() {
      return {
        add:
          this.getPermissions([`'sales.add_imagerepository'`]) || this.isAdmin,
        edit:
          this.getPermissions([`'sales.change_imagerepository'`]) ||
          this.isAdmin,
        labelAdd:
          this.getPermissions([`sales.add_repositorylabels`]) || this.isAdmin,
        labelDelete:
          this.getPermissions([`sales.delete_repositorylabels`]) ||
          this.isAdmin,
        labelView:
          this.getPermissions([`sales.view_repositorylabels`]) || this.isAdmin
      }
    },
    query() {
      let query = this.$api
      this.api.split('.').forEach((element) => (query = query[element]))
      return query
    },
    isEditable() {
      return this.dialog && this.repositoryBtn.edit
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    edit(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    open(item) {
      this.toEdit2 = item === undefined ? null : item
      this.dialog2 = true
    },
    selectLabel(label) {
      this.select_label = label
    },
    async submit(file, item) {
      let form_data = new FormData()
      if (file === null) {
        this.$toast.error(
          `${this.$tc('add', 2)} ${this.$tc('settingTemplate.image', 1)}`
        )
        return
      }
      if (file === undefined) {
        if (item?.label) form_data.append('label', item.label)
        form_data.append('subOrder', this.subOrderPk)
        form_data.append('nameImage', item.nameImage)
      } else {
        form_data.append('image', file)
        if (item?.label) form_data.append('label', '')
        form_data.append('subOrder', this.subOrderPk)
        form_data.append(
          'nameImage',
          this.nameImage !== '' ? this.nameImage : file.name.split('.')[0]
        )
        form_data.append('imageType', file.name.split('.').pop())
        form_data.append('size', file.size)
        //form_data.append('date', new Date().toISOString().substr(0, 10))
        //form_data.append('lastModifiedDate', file.lastModifiedDate)
      }
      for (var key in this.imageRepository) {
        if (this.imageRepository[key] == null) {
          continue
        }
        form_data.append(
          key,
          key !== 'dimensions'
            ? this.imageRepository[key]
            : JSON.stringify(this.imageRepository[key])
        )
        if (
          key == 'image' &&
          (this.imageRepository.image == null ||
            String(this.imageRepository.image).indexOf(';base64') != -1 ||
            String(this.imageRepository.image).indexOf('http') != -1)
        ) {
          form_data.delete('image')
        }
      }
      try {
        this.load = true
        file === undefined
          ? await this.$api.suborder.imageRepository.edit({
              pk: item.pk,
              form: form_data
            })
          : await this.$api.suborder.imageRepository.create({
              form: form_data
            })
        this.progress = (event.loaded / event.total) * 100
        if (file === undefined) {
          this.$toast.success(
            `${this.$tc('settingTemplate.image', 1)} ${this.$tc('edited', 2)}`
          )
        }
        this.getLabels()
        this.getFiles()
        this.dialog = false
      } finally {
        this.load = false
      }
      this.nameImage = ''
      this.imageUrl = null
      this.imagedrop = null
    },
    async getFiles() {
      try {
        this.load = true
        let response = await this.$api.suborder.imageRepository.show({
          pk: this.subOrderPk,
          opt: {
            params: {
              subOrder: true
            }
          }
        })
        this.imageRepository = response.data
      } finally {
        this.load = false
      }
    },
    /**
     * addLabels
     * Método para agregar nueva etiqueta a la lista, si se proporciona un
     * evento.
     *
     * @param {Object} evt - Evento que puede contener una nueva etiqueta
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    addLabels(evt) {
      this.labels.push(evt)
      this.imageRepository.label = evt.pk
    },
    async getLabels() {
      try {
        this.load = true
        let response = await this.$api.suborder.labels.show({
          pk: this.subOrderPk,
          opt: {
            params: {
              subOrder: true
            }
          }
        })
        this.labels = response.data
      } finally {
        this.load = false
      }
    },
    selectFile() {
      this.selectedFiles = event.target.files
      this.selected = this.selectedFiles.length
      this.uploadFiles()
    },
    uploadFiles() {
      this.imageUrl = this.imagedrop
      this.message = ''
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.submit(this.selectedFiles[i], undefined)
        this.getFiles()
      }
    },
    downloadImage(down, item) {
      this.downloadFile({
        api: 'suborder.imageRepository',
        method: 'image',
        filename: item.nameImage,
        action: down ? 'download' : 'window',
        ext: item.imageType,
        opt: {
          pk: item.subOrder,
          params: item.nameImage + '.' + item.imageType
        }
      })
    },
    removeChip(item) {
      this.showConfirmation({
        show: true,
        model: this.$tc('label', 1),
        title: this.$tc('delete', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.$tc('label', 1),
          action: this.$tc('delete', 2)
        })}`,
        confirm: () => this.$api.suborder.labels.remove({ pk: item.pk })
      })
    },
    removeChip2(item) {
      item.label = ''
      this.submit(undefined, item)
    },
    remove(pk) {
      this.showConfirmation({
        show: true,
        model: this.$tc('settingTemplate.image', 2),
        title: this.$tc('delete', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.$tc('settingTemplate.image', 2),
          action: this.$tc('delete', 2)
        })}`,
        confirm: () => this.query['remove']({ pk })
      })
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters['confirmation/getConfirmationFinished'],
      async (val) => {
        if (val === this.$tc('label', 1)) {
          this.getLabels()
          this.deleting = false
          this.$store.dispatch('confirmation/confirmationFinished', false)
        }
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  },
  mounted() {
    this.getFiles()
    this.getLabels()
  }
}
</script>
<style lang="sass">
.v-application
  #repo-buttons
    .v-btn.v-btn--outlined.v-size--small
      margin: 5px 0
      margin-left: 0!important
      margin-right: 5px!important

.repository
  &--image
    &-container
      position: relative
      z-index: 203
      #image
        padding-right: 50px
    &-add
      top: calc(50% - 20px)
      right: 0
      margin-right: 10px
      position: absolute
      border: 2px solid var(--secundary)
      width: 40px
      height: 40px
      font-size: 0.75rem
      display: inline-flex
      justify-content: center
      align-items: center
      border-radius: 50%
      z-index: 204
      &:hover
        cursor: pointer
</style>
