<template>
  <v-row>
    <!-- Diálogo de marca -->
    <dialbrand
      v-model="dialog"
      :to-edit="toEdit"
      :brandroot="selectedBrand"
      @done="addBrand"
      ref="dialbrand"
    />

    <!-- Marca -->
    <v-col cols="12" sm="12" class="py-3">
      <v-skeleton-loader v-if="loading" type="list-item-avatar" tile />
      <ValidationProvider
        vid="brand"
        :name="$tc('brand', 1)"
        rules="required"
        v-slot="{ errors }"
      >
        <v-autocomplete
          autocomplete="off"
          @click:prepend-inner="openBrand()"
          v-show="!loading"
          :items="brands"
          :clearable="brands.length > 10"
          item-text="name"
          item-value="pk"
          outlined
          v-model="main"
          color="secondary"
          :label="$tc('brand', 1)"
          :error-messages="errors[0]"
          :append-icon="brands.length > 10 ? 'fa-search' : ''"
          :prepend-inner-icon="brandBtn.add ? 'fa-plus' : ''"
        />
      </ValidationProvider>
    </v-col>

    <!-- Modelo -->
    <v-col cols="12" sm="12" class="py-0">
      <v-skeleton-loader v-if="loading" type="list-item-avatar" tile />
      <ValidationProvider
        vid="model"
        :name="$tc('model', 1)"
        rules="required"
        v-slot="{ errors }"
      >
        <v-autocomplete
          autocomplete="off"
          @click:prepend-inner="openBrand(brandSelected)"
          v-show="!loading"
          :disabled="!main"
          :items="models"
          :clearable="models.length > 10"
          item-text="name"
          item-value="pk"
          outlined
          v-model="modelMain"
          color="secondary"
          :label="$tc('model', 1)"
          :error-messages="errors[0]"
          :append-icon="models.length > 10 ? 'fa-search' : ''"
          :prepend-inner-icon="canAddModel ? 'fa-plus' : ''"
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import dialbrand from '../../components/brand/modal.vue'

export default {
  components: {
    dialbrand
  },
  props: {
    value: [Number, String],
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      brands: [],
      models: [],
      brandSelected: null,
      modelSelected: null,
      selectedBrand: null,
      main: null,
      modelMain: null,
      dialog: false,
      toEdit: null
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    /**
     * canAddModel
     * Computado que determina si se puede agregar un nuevo modelo.
     * Retorna true si se está en la vista principal, la creación no está
     * deshabilitada y si el usuario tiene permisos para agregar marcas/modelos
     * de equipo o si es administrador.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    canAddModel() {
      return this.main && this.brandBtn.add
    },
    /**
     * brandBtn
     * Computado que determina si se puede agregar una nueva marca/modelo.
     * Retorna true si el usuario tiene permisos para agregar marcas/modelos
     * de equipo o si es administrador.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    brandBtn() {
      return {
        add: this.getPermissions(['equipements.add_brandmodel']) || this.isAdmin
      }
    }
  },
  watch: {
    /**
     * value
     * Observador que se activa inmediatamente y maneja cambios en el valor del
     * modelo.
     * Si el valor es válido, carga el modelo correspondiente; de lo contrario,
     * establece modelMain como null.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    value: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.loadModel(val)
        } else {
          this.modelMain = null
        }
      }
    },
    /**
     * main
     * Método asíncrono que maneja el valor de la marca. Si el valor es válido,
     * carga la marca correspondiente; de lo contrario, establece brandSelected
     * como null y emite un evento de entrada con null.
     * Además, llama al método getBrand con el argumento 'sustrate'.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async main(val) {
      if (val) {
        await this.loadBrand(val)
      } else {
        this.brandSelected = null
        this.$emit('input', null)
      }
      this.getBrand('sustrate')
    },
    /**
     * modelMain
     * Método que emite el valor del modelo principal. Si el valor es nulo,
     * emite un evento de entrada con null.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    modelMain(val) {
      this.$emit('input', val || null)
    }
  },
  methods: {
    /**
     * addBrand
     * Método para agregar una nueva marca al array de marcas.
     * Si la marca tiene un valor mayor a 0, se establece modelMain con el
     * identificador de la marca; de lo contrario, se establece main.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    addBrand(evt) {
      this.brands.push(evt)
      if (evt.brand > 0) {
        this.modelMain = evt.pk
        if (this.val === undefined) this.getBrand()
      } else this.main = evt.pk
    },
    /**
     * openBrand
     * Método para abrir el diálogo de selección de marca.
     * Establece la marca seleccionada y el objeto a editar.
     * Si el valor es undefined, se inicializa en null.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    openBrand(val) {
      this.selectedBrand = val ?? null
      this.toEdit = val === undefined ? null : val
      this.dialog = true
    },
    /**
     * getBrand
     * Método asíncrono para consultar la lista de marcas.
     * Configura los parámetros de la solicitud según el valor proporcionado.
     * Actualiza el array de marcas o modelos según el contexto.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getBrand(val) {
      const params = {
        size: 10,
        parent: true,
        brand: val ? this.main : undefined
      }
      try {
        const response = await this.$api.equipment.brand.list({
          opt: { params }
        })
        if (val === undefined) {
          this.brands = this.brandSelected ? [this.brandSelected] : []
        } else {
          this.models = this.modelSelected ? [this.modelSelected] : []
        }
        this.updateItems(val, response.data.results)
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * updateItems
     * Método para actualizar el array de marcas o modelos.
     * Combina los resultados obtenidos y elimina duplicados.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    updateItems(val, results) {
      if (val === undefined) {
        this.brands = Array.from(new Set(this.brands.concat(results)))
      } else {
        this.models = Array.from(new Set(this.models.concat(results)))
      }
    },
    /**
     * loadModel
     * Método asíncrono para cargar un modelo.
     * Si el modelo no se encuentra, realiza una solicitud a la API para
     * obtenerlo.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async loadModel(val) {
      let model = this.models.find((m) => m.pk === val)
      if (!model && typeof val === 'number') {
        const template = await this.$api.equipment.brand.show({ pk: val })
        model = template.data
        this.models = [model]
        this.modelMain = model.pk
      }
      if (model) {
        await this.loadBrand(model.brand)
      }
    },
    /**
     * loadBrand
     * Método asíncrono para cargar una marca.
     * Si la marca no se encuentra en el array, realiza una solicitud a la API
     * para obtenerla.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async loadBrand(brandId) {
      let cat = this.brands.find((m) => m.pk === brandId)
      if (!cat) {
        const categ = await this.$api.equipment.brand.show({ pk: brandId })
        cat = categ.data
      }
      this.brandSelected = cat
      this.brands = [cat]
      this.main = cat?.pk
    }
  },
  mounted() {
    this.getBrand()
  }
}
</script>

<style scoped>
.p-0 {
  padding: 0 !important;
}
</style>
