<template>
  <v-container fluid class="kanban">
    <!-- Plantilla de proceso de produccion -->
    <v-chip-group
      active-class="primary--text"
      class="mb-5 pb-3"
      column
      v-model="template"
    >
      <v-chip
        outlined
        v-for="template in templates"
        :key="template.pk"
        :value="template.pk"
      >
        {{
          `${template.template ? template.template + ' - ' : ''}${
            template.name
          }`
        }}
      </v-chip>
    </v-chip-group>
    <v-row>
      <v-col
        v-for="index in skeletonsCount"
        v-show="loadStatus"
        :key="'skeleton-' + index"
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <v-skeleton-loader type="article" class="mx-auto"></v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- Estados de suborden -->
    <div class="statusContainer">
      <v-row>
        <v-col
          class="pt-1"
          v-for="(main_status, key) in main_statuses"
          :key="`status-${key}`"
          cols="12"
        >
          <status
            :filter="main_status.pk"
            :preStatuses="statuses"
            :template="template"
          ></status>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import status from './status.vue'

export default {
  components: {
    status
  },
  data() {
    return {
      templates: [],
      load: false,
      loading: false,
      loadStatus: false,
      template: null,
      statuses: [],
      main_statuses: [],
      newStatus: '',
      oldStatus: '',
      product: ''
    }
  },
  computed: {
    /**
     * skeletonsCount
     * Devuelve una lista de números que representan los "skeletons".
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    skeletonsCount() {
      return [1, 2, 3, 4, 6, 7, 8, 9]
    },
    /**
     * Mapeo de getters de Vuex.
     * Proporciona acceso a información de sesión y empresa.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    ...mapGetters({
      isAdmin: 'session/isAdmin',
      me: 'session/me',
      company: 'company/getCompanyData'
    })
  },
  mounted() {
    this.getTemplates()
  },
  watch: {
    /**
     * Observador para el cambio de la plantilla.
     * Llama a las funciones para obtener el estado de la orden.
     *
     * @param {Object} val - Valor actual de la plantilla.
     */
    template: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getOrderStatus()
          this.getMainOrderStatus()
        } else {
          this.statuses = []
        }
      }
    }
  },
  created() {
    const service = this.$dragula.$service
    service.eventBus.$on('drop', this.handleDrop)
  },
  methods: {
    /**
     * handleDrop
     * Maneja el evento de soltar un bloque, actualizando el estado del producto.
     *
     * @param {Object} block - Información del bloque soltado.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    handleDrop(block) {
      // Obtener el producto y el nuevo estado
      this.product = Number(block.el.dataset.key)
      this.newStatus = Number(block.container.dataset.status)

      // Obtener el cuerpo del email si está disponible
      if (this.$refs['getbodyemail']) {
        this.$refs['getbodyemail'].getBodyEmail()

        // Obtener el estado anterior
        this.oldStatus = this.getOldStatus()
      }

      // Verificar si hay estados disponibles
      if (this.statuses.length > 0) {
        const order = this.statuses.find((item) => item.pk === this.newStatus)
        if (order) {
          const name = this.extractLastWord(order.name)
          // Definir el formulario basado en el estado
          this.form = {
            product: this.product,
            preprocess: this.getPreprocessStatus(name)
          }
          this.$api.dashboard.edit({ pk: this.newStatus, form: this.form })
        } else {
          //
        }
      }
    },

    /**
     * getOldStatus
     * Obtiene el estado anterior basado en el código del producto.
     *
     * @returns {String} Nombre del estado anterior o una cadena vacía si no se
     *                   encuentra.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getOldStatus() {
      return (
        this.statuses.find((status) =>
          status.products.some((item) => item.code === this.email['code'])
        )?.name || ''
      )
    },

    /**
     * getPreprocessStatus
     * Determina el estado de preproceso, basado en el nombre del estado.
     *
     * @param {String} name - Nombre del estado actual.
     * @returns {String} Estado de preproceso correspondiente.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getPreprocessStatus(name) {
      switch (name) {
        case 'sended_quote':
          return 'sended_quote'
        case 'canceled':
          return 'canceled'
        default:
          return 'in_process'
      }
    },
    /**
     * extractLastWord
     * Extrae el estado en preproceso.
     *
     * @param {String} string - Cadena de la cual extraer la última palabra.
     * @returns {String} Última palabra de la cadena.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    extractLastWord(string) {
      const words = string.split(' ')
      return words[words.length - 1] || ''
    },
    /**
     * getOrderStatus
     * Obtiene el estado del pedido para cada producto en el carrito.
     * Actualiza el estado en el dashboard.
     *
     * @param {String} state - Estado a verificar.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getOrderStatus() {
      try {
        this.loadStatus = true
        const res = await this.$api.dashboard.list({
          pk: this.template,
          opt: {
            params: {
              user: this.isAdmin ? null : this.me.id
            }
          }
        })
        this.statuses = res.data
      } catch (error) {
        console.error('Error fetching order status:', error)
      } finally {
        this.loadStatus = false
      }
    },
    /**
     * getMainOrderStatus
     * Obtiene el estado principal de la orden.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getMainOrderStatus() {
      try {
        this.load = true
        const response = await this.$api.sale.status.list({
          opt: {
            params: {
              template: this.template
            }
          }
        })
        this.main_statuses = response.data
      } catch (error) {
        console.error('Error fetching main order status:', error)
      } finally {
        this.load = false
      }
    },
    /**
     * getTemplates
     * Obtiene las plantillas de proceso de producción disponibles.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getTemplates() {
      try {
        this.loading = true
        const response = await this.$api.product.template.list({
          opt: {
            params: {
              order: true,
              company: this.company.pk
            }
          }
        })
        this.templates = response.data.filter((item) => item.orderNumber > 0)
        this.template = this.templates.length > 0 ? this.templates[0].pk : null
      } catch (error) {
        console.error('Error fetching templates:', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
