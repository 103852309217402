var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{attrs:{"open-on-click":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{attrs:{"offset-x":"30","offset-y":"10","color":"secondary","bottom":"","content":_vm.notifications.length == ''
            ? '0' // Muestra '0' si no hay notificaciones
            : _vm.notifications.length > 99
            ? '+99' // Muestra '+99' si hay más de 99 notificaciones
            : _vm.notifications.length}},[_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","text":""}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require('@/assets/color-icons/settings/users.png'),"height":"32","width":"32"},on:{"click":_vm.getDesignerNotification}})],1)],1)]}}])},[(_vm.notifications.length > 0)?_c('v-list',{style:({
        'max-height': '70vh',
        'overflow-x': 'scroll'
      })},_vm._l((_vm.notifications),function(item,index){return _c('v-list-item',{key:index,staticClass:"mb-2 ma-0",style:({
          'font-weight': 700,
          width: '100%'
        }),attrs:{"text-color":"primary"},on:{"click":function($event){return _vm.open(item)}}},[_c('v-col',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('designer'))+": "),_c('span',[_vm._v(" "+_vm._s(item.user_data.name)+" ")])]),_c('v-list-item-subtitle',{staticClass:"text--primary"},[_c('span',[_vm._v(" "+_vm._s(item.suborder_number)+" ")])]),_c('v-list-item-subtitle',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$tc('suborder', 1))+": "),_c('span',[_vm._v(" "+_vm._s(item.suborder_name)+" ")])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('suborderstatus'))+": "),_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.suborder_status))+" ")])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('assignament_date'))+": "),_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.assignament_date))+" ")])])],1)],1)}),1):_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('no_notifications')))])],1)],1)],1),_c('dialprod',{ref:"dialprod",on:{"prod":function($event){return _vm.getSuborder($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }