<route>
{
  "meta": {
    "permission": [
      "product.add_productiondetails",
      "product.change_productiondetails"
    ]
  }
}
</route>

<template>
  <i-container
    dontSave
    :opts="opts"
    :mode="container"
    @input="$emit('input', $event)"
  >
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar dontSave :loading="load" :title="modalTitle">
            <template v-slot:arrowRight>
              <i-btn
                v-if="validateNextButton"
                icon="fa-arrow-right"
                text
                :title="$t('next')"
                @click="next()"
              >
              </i-btn>
            </template>
            <template v-slot:arrowLeft>
              <i-btn
                v-if="step > 1"
                icon="fa-arrow-left"
                text
                :title="$t('back')"
                @click="step -= 1"
              ></i-btn>
            </template>
            <template v-slot:submit>
              <i-btn
                v-if="validateSubmitButton"
                icon="fa-save"
                text
                :loading="isLoading3"
                :title="$tc('save', 1)"
                @click="submit((closed = true))"
                @keyup.enter="submit((closed = true))"
              ></i-btn>
            </template>
          </i-toolbar>
          <v-card-text>
            <v-stepper style="z-index: 0" class="elevation-0" v-model="step">
              <v-stepper-header class="elevation-0">
                <v-stepper-step :step="1" editable>
                  {{ $t('basic') }}
                </v-stepper-step>
                <template v-if="validateSecondStep">
                  <v-divider></v-divider>
                  <v-stepper-step :step="2" editable>
                    {{ $tc('cost', 1) }}
                  </v-stepper-step>
                </template>
                <template v-if="validateThirdStep" editable>
                  <v-divider></v-divider>
                  <v-stepper-step :step="3">
                    {{ $tc('sales_prices', 1) }}
                  </v-stepper-step>
                </template>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content :step="1">
                  <v-row class="my-0">
                    <v-col cols="12" md="8">
                      <v-row class="my-0">
                        <!-- Producto -->
                        <v-col
                          v-if="product && mode === 'item'"
                          class="pt-0"
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <ValidationProvider
                            vid="prod"
                            :name="$tc('product', 1)"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              autocomplete="off"
                              class="secondary--text"
                              disabled
                              item-value="pk"
                              key="prod-input"
                              outlined
                              :error-messages="errors[0]"
                              :label="$tc('product', 1)"
                              v-model="product.name"
                            >
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>

                        <!-- Plantilla -->
                        <v-col
                          v-if="detail.pk && mode === 'item'"
                          class="pt-0"
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <v-text-field
                            autocomplete="off"
                            disabled
                            outlined
                            v-model="detail.template_name"
                            class="secondary--text"
                            :label="$t('template')"
                          >
                          </v-text-field>
                        </v-col>

                        <!-- Nombre -->
                        <v-col class="pt-0" cols="12" md="6" lg="4">
                          <ValidationProvider
                            vid="detail-name"
                            :name="
                              $tc(
                                detail.name === 'deliverytime' ? 'days' : 'name'
                              )
                            "
                            :rules="{
                              required: true,
                              numeric: detail.name === 'deliverytime'
                            }"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-if="!load"
                              autocomplete="off"
                              outlined
                              :error-messages="errors[0]"
                              :label="
                                $t(
                                  detail.name === 'deliverytime'
                                    ? 'days'
                                    : 'name'
                                )
                              "
                              key="detail-name-input"
                              v-model="detail.name"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>

                        <!-- Tipo de precio -->
                        <v-col
                          v-if="mode === 'item'"
                          class="pt-0"
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <ValidationProvider
                            vid="price"
                            rules="required"
                            :name="$tc('price', 3)"
                            v-slot="{ errors }"
                          >
                            <v-select
                              autocomplete="off"
                              outlined
                              :disabled="mode === 'subitem'"
                              :error-messages="errors[0]"
                              :items="[
                                { state: $t('base'), abbr: 'base' },
                                { state: $t('formula'), abbr: 'formula' },
                                { state: $t('matrix'), abbr: 'matrix' },
                                { state: $t('without_price'), abbr: 'bool' }
                              ]"
                              item-text="state"
                              item-value="abbr"
                              :label="$tc('price', 3)"
                              v-model="detail.price_type"
                            ></v-select>
                          </ValidationProvider>
                        </v-col>

                        <!-- Lista de fórmulas -->
                        <v-col
                          v-if="
                            detail.price_type === 'formula' && mode === 'item'
                          "
                          class="pt-0"
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <dialformula
                            container="modal"
                            v-model="formulafield"
                            @updated="addFormula"
                          ></dialformula>
                          <ValidationProvider
                            vid="formula"
                            :name="$tc('formula', 1)"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-autocomplete
                              autocomplete="off"
                              outlined
                              :disabled="mode === 'subitem'"
                              v-model="detail.formula"
                              :clearable="formulas.length > 10"
                              :error-messages="errors[0]"
                              :items="formulas"
                              class="secondary--text"
                              item-text="name"
                              item-value="pk"
                              key="formula-input"
                              :append-icon="
                                formulas.length > 10 ? 'fa-search' : ''
                              "
                              :prepend-inner-icon="
                                getPermissions([`product.add_priceformula`]) ||
                                isAdmin
                                  ? 'fa-plus'
                                  : ''
                              "
                              :label="$tc('formula', 1)"
                              @click:prepend-inner="formulafield = true"
                            >
                            </v-autocomplete>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!-- Selección múltiple -->
                    <v-col v-if="mode === 'item'" class="pt-0" cols="12" md="4">
                      <h2 class="title">{{ $t('attributes') }}</h2>

                      <!-- Con subitems -->
                      <v-switch
                        class="v-switch mt-0"
                        color="primary"
                        key="subitems-input"
                        :label="$tc('with_subitems')"
                        v-model="detail.subitems"
                      ></v-switch>

                      <!-- Selección múltiple -->
                      <v-switch
                        class="v-switch mt-0"
                        color="primary"
                        key="multi_choice-input"
                        :disabled="!detail.subitems"
                        :label="$tc('multi_choice')"
                        v-model="detail.multi_choice"
                      ></v-switch>

                      <!-- Costo por dimensiones -->
                      <v-switch
                        v-if="detail.price_type === 'formula'"
                        class="v-switch mt-0"
                        color="primary"
                        key="haveDimensions-input"
                        :label="$tc('cost_by_dimensions')"
                        v-model="detail.have_dimensions"
                      ></v-switch>

                      <!-- Permitir multiplicar por la cantidad de producto -->
                      <v-switch
                        class="v-switch mt-0"
                        color="primary"
                        key="is_multipliable-input"
                        :disabled="detail.price_type === 'bool'"
                        :label="$tc('is_multipliable')"
                        v-model="detail.is_multipliable"
                      ></v-switch>
                    </v-col>
                  </v-row>

                  <!-- Ejemplo de aplicación de la fórmula -->
                  <v-row>
                    <v-col cols="12">
                      <v-alert
                        v-if="
                          detail.pk && formula != null && detail.formula != null
                        "
                        class="font-weight-black title"
                        color="primary"
                        dismissible
                        outlined
                        type="info"
                        :value="true"
                      >
                        {{ $t('example') }}
                        <v-row>
                          <v-col cols="12">
                            <ValidationProvider
                              vid="priceString"
                              :name="$tc('price', 1)"
                              rules=""
                              v-slot="{ errors }"
                            >
                              <i-formula
                                showFormulaString
                                :error-messages="errors[0]"
                                :formula="detail.formula"
                                @change="example.price = $event"
                                v-model="example.priceString"
                              />
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content class="px-0 pt-0" :step="2">
                  <!-- Costo del detalle de producción -->
                  <v-row class="my-0">
                    <v-col
                      v-if="
                        detail !== null &&
                        detail.pk !== '' &&
                        detail.price_type !== 'matrix' &&
                        detail.price_type !== 'bool'
                      "
                      class="pt-0"
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        vid="detailcost"
                        :name="$tc('cost', 1)"
                        :rules="{
                          required:
                            detail.pk !== '' && detail.price_type !== 'matrix',
                          min_value: 0.01
                        }"
                        v-slot="{ errors }"
                      >
                        <i-money
                          v-model="detail.cost"
                          :error-messages="errors[0]"
                          :key="'detail-cost-input'"
                          :label="$tc('cost', 1)"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <!-- Matriz principal del proceso de producción -->
                  <v-row v-if="detail.price_type === 'matrix'" class="my-0">
                    <v-col class="pt-0" cols="6" md="9" xl="10">
                      <v-select
                        v-show="templateMatrix === null && product === null"
                        outlined
                        :clearable="matrixs.length > 10"
                        :disabled="newMainMatrix != null"
                        autocomplete="off"
                        name="matrix"
                        key="matrix"
                        :items="matrixs"
                        v-model="detail.main_matrix"
                        item-text="name"
                        item-value="pk"
                        color="secondary"
                        :label="$tc('matrix', 1)"
                        :append-icon="matrixs.length > 10 ? 'fa-search' : ''"
                      />
                    </v-col>

                    <!-- Botones de editar y copiar -->
                    <v-col
                      v-if="product === null && templateMatrix === null"
                      cols="6"
                      md="3"
                      xl="2"
                    >
                      <div class="d-flex g-2">
                        <i-btn
                          v-if="detail.main_matrix > 0"
                          color="primary"
                          icon="mdi-pencil"
                          outlined
                          :title="$tc('edit', 1)"
                          @click="
                            openMatrix(
                              newMainMatrix != null
                                ? newMainMatrix.pk
                                : detail.main_matrix
                            )
                          "
                        ></i-btn>
                        <i-btn
                          color="primary"
                          icon="fa-copy"
                          outlined
                          :title="$t('copy')"
                          @click="
                            copyTemplateMatrix(
                              newMainMatrix != null
                                ? newMainMatrix.pk
                                : detail.main_matrix
                            )
                          "
                        ></i-btn>
                      </div>

                      <!-- Modal de editar matrix -->
                      <dialmatrix
                        v-if="newMainMatrix !== null"
                        container="modal"
                        v-model="dialog"
                        :productionId="detail.template"
                        :productionName="detail.template_name"
                        :to-edit.sync="newMainMatrix"
                      />
                    </v-col>

                    <!-- Rangos de plantilla de matriz principal -->
                    <v-col v-if="validateCopyMatrix" class="px-0" cols="12">
                      <i-matrix
                        :productId="product !== null ? product.pk : null"
                        :showMatrix="true"
                        :matrixUse="product != null ? 'product' : 'details'"
                        :detailId="mode === 'subitem' ? detail.item : detail.pk"
                        :subdetailId="mode === 'subitem' ? detail.pk : null"
                        :name="detail.name"
                        :new_template="
                          product !== null
                            ? product.matrix
                            : templateMatrix != null
                            ? templateMatrix.pk
                            : null
                        "
                      >
                      </i-matrix>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content :step="3">
                  <v-row>
                    <!-- Precios de venta por tipo de cliente -->
                    <v-col
                      v-if="
                        detail !== null &&
                        detail.price_type !== 'bool' &&
                        step === 3
                      "
                      cols="12"
                    >
                      <prices
                        :mode="product === null ? 'process' : 'detail'"
                        :cost="detail.cost"
                        :detail="mode === 'subitem' ? detail.item : detail.pk"
                        :subdetail="mode === 'subitem' ? detail.pk : null"
                        :template="detail.template"
                        :product="product !== null ? product.pk : null"
                      />
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </i-container>
</template>
<script>
import { mapGetters } from 'vuex'
import dialmatrix from '../matrix/_pk.vue'
import dialformula from '../formula/_pk.vue'
import prices from '../../components/product/price.vue'
export default {
  props: {
    container: {
      type: String,
      default: 'container'
    },
    value: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      default: null
    },
    mode: {
      type: String
    },
    process: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'details_production'
    }
  },
  components: {
    prices,
    dialformula,
    dialmatrix
  },
  data() {
    return {
      formulafield: false,
      step: 1,
      load: false,
      prod: null,
      prods: [],
      formulas: [],
      formula: null,
      loading: false,
      isLoading: false,
      isLoading2: false,
      isLoading3: false,
      dialog: false,
      newMainMatrix: null,
      templateMatrix: null,
      matrixs: [],
      matrix: null,
      detail: {
        pk: '',
        item: '',
        product: '',
        formula: '',
        template: null,
        price_type: '',
        name: '',
        cost: 0,
        have_dimensions: false,
        is_multipliable: true,
        multi_choice: false,
        subitems: false,
        main_matrix: null
      },
      example: {
        priceString: '',
        price: 0
      },
      detailsContainer: null
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      company: 'company/getCompanyData',
      isAdmin: 'session/isAdmin'
    }),
    opts() {
      switch (this.container) {
        case 'modal':
          return {
            title: '',
            value: this.value,
            load: this.isLoading2,
            maxWidth: '80%',
            dontSave: this.step < 4
          }
        default:
          return { fluid: true }
      }
    },
    /**
     * modalTitle
     * Computado para generar el título de la modal
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    modalTitle: function () {
      if (this.container === 'modal' && this.detail && this.detail.pk) {
        return `${this.$t('edit')} ${this.$tc(this.detail.name, 1)}`
      }
      return `${this.$t('add', { value: this.$tc('new', 1) })}`
    },
    /**
     * validateNextButton
     * Computado para validar la flecha de ir adelante
     * El botón no se muestra para los siguientes casos:
     * - Si no tiene nombre
     * - Si es booleano
     * - Si step es mayor a 2
     * - Si tiene subitems
     * - Si costo es 0.00
     * - Los items/subitems tipo matrix no tienen paso 3
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    validateNextButton: function () {
      if (!this.detail.name) return false
      if (this.detail.price_type === 'bool') return false
      if (this.step > 2) return false
      if (this.detail.subitems) return false
      if (this.step > 1 && this.detail.cost === '0.00') return false
      if (this.step > 1 && this.detail.price_type === 'matrix') return false
      return true
    },
    /**
     * validateSubmitButton
     * Computado para validar el botón de guardar formulario
     * El botón se muestra para los siguientes casos:
     * - Está en el paso 1 y es booleano
     * - Está en el paso 1 y tiene subitems
     * - Está en el paso 2 y tiene tipo de precio es matrix
     * - Está en el paso 3
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    validateSubmitButton: function () {
      if (this.step === 1 && this.detail.price_type === 'bool') return true
      if (this.step === 1 && this.detail.subitems) return true
      if (this.step === 2 && this.detail.price_type === 'matrix') return true
      if (this.step === 3) return true
      return false
    },
    /**
     * validateSecondStep
     * Computado para validar el paso 2
     * El paso 2 no se muestra para los siguientes casos:
     * - Si es nuevo
     * - Si no tiene nombre
     * - Si es booleano
     * - Si es tiene subitems
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    validateSecondStep: function () {
      if (!this.detail.pk) return false
      if (!this.detail.name) return false
      if (this.detail.price_type === 'bool') return false
      if (this.detail.subitems) return false
      return true
    },
    /**
     * validateThirdStep
     * Computado para validar el paso 3
     * El paso 3 no se muestra para los siguientes casos:
     * - Si es nuevo
     * - Si es booleano
     * - Si es tiene subitems
     * - Si costo es 0.00
     * - Si tipo de precio es matrix
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    validateThirdStep: function () {
      if (!this.detail.pk) return false
      if (this.detail.price_type === 'bool') return false
      if (this.detail.subitems) return false
      if (this.detail.cost === '0.00') return false
      if (this.detail.price_type === 'matrix') return false
      return true
    },
    /**
     * validateCopyMatrix
     * Computado para validar la clonación de la matriz
     * La matriz se muestra para los siguientes casos:
     * - Se ha creado el detalle de producción (Item o subItem)
     * - El tipo de precio es matriz
     * - Está en el paso 2
     * - El switch de clonar está en verdadero
     * - Se ha creado una nueva plantilla de matriz
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    validateCopyMatrix: function () {
      if (this.detail === null) return false
      if (this.detail.price_type !== 'matrix') return false
      if (this.step !== 2) return false
      if (this.templateMatrix === null && this.product === null) return false
      return true
    }
  },
  methods: {
    next() {
      if (
        this.step === 2 &&
        this.detail !== null &&
        this.detail.cost !== '0.00'
      ) {
        if (this.detail.price_type !== 'matrix') this.createPrices()
        this.detail.price_type = this.process.price_type
        this.submit()
        this.step += 1
      }
      if (
        this.step === 1 &&
        this.detail.price_type === 'matrix' &&
        this.process.main_matrix > 0 &&
        this.product === null
      ) {
        this.copyTemplateMatrix(this.process.main_matrix)
      }

      if (this.step === 1) {
        this.detail.price_type = this.process.price_type
        if (this.detail.pk == '') {
          this.submit()
        }
        this.step += 1
      }
    },
    /**
     * getDetails
     * Método que consulta y filtra los detalles de producción
     * por ítem y subítem relacionados a una plantilla o a un producto
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getDetails(item) {
      if (item == undefined) {
        if (this.$store.getters['utils/getIsloading']) {
          this.$store.dispatch('utils/actionLoading')
          if (this.process.main_matrix != null)
            this.viewTemplateMatrix(this.process.main_matrix)
        }
        if (this.process !== null) {
          this.detail = {
            pk: '',
            item: this.mode === 'subitem' ? this.process.pk : null,
            product: this.product !== null ? this.product.pk : null,
            formula: this.mode === 'subitem' ? this.process.formula : '',
            template: this.process.template,
            price_type: this.mode === 'subitem' ? this.process.price_type : '',
            name: '',
            cost: 0,
            have_dimensions:
              this.mode === 'subitem' ? this.process.have_dimensions : false,
            multi_choice:
              this.mode === 'subitem' ? this.process.multi_choice : false,
            main_matrix: this.process.main_matrix
          }
          this.step = 1
        }
      } else {
        this.loading = true
        if (this.process !== undefined && this.mode === 'item') {
          // ítem
          this.detail = this.process
          if (this.detail.main_matrix > 0)
            this.viewTemplateMatrix(this.detail.main_matrix)
        } else {
          // subítem
          if (this.product === null) {
            // subítem process
            const subitem = await this.$api.product.processsubitem.show({
              pk: item
            })
            this.detail = subitem.data
            this.step = 1
          } else {
            // subítem product
            const subitem = await this.$api.product.subdetails.show({
              pk: item
            })
            this.detail = subitem.data
          }
        }
      }
    },
    /**
     * submit
     * Método que guarda un ítem o subítem de una plantilla de
     * proceso de producción que a su vez puede estar o no
     * relacionanada a un producto
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submit(closed) {
      if (this.detail.price_type === 'bool') {
        this.createPrices()
        if (this.detail.subitems) {
          this.detail.cost = null
        }
      }
      try {
        this.isLoading3 = true
        if (this.product === null) {
          if (this.mode === 'subitem') {
            // subítem process
            this.detailsContainer =
              this.detail.pk === '' || this.detail.pk === undefined
                ? await this.$api.product.processsubitem.create({
                    form: this.detail
                  })
                : await this.$api.product.processsubitem.edit({
                    pk: this.detail.pk,
                    form: this.detail
                  })
            this.detail = this.detailsContainer.data
          } else {
            // ítem process
            this.detailsContainer =
              this.detail.pk === '' || this.detail.pk === undefined
                ? await this.$api.product.processitem.create({
                    form: this.detail
                  })
                : await this.$api.product.processitem.edit({
                    pk: this.detail.pk,
                    form: this.detail
                  })
            this.detail = this.detailsContainer.data
          }
        } else {
          // subítem product
          if (this.mode === 'subitem') {
            this.detailsContainer =
              this.detail.pk === '' || this.detail.pk === undefined
                ? await this.$api.product.subdetails.create({
                    form: this.detail
                  })
                : await this.$api.product.subdetails.edit({
                    pk: this.detail.pk,
                    form: this.detail
                  })
            this.detail = this.detailsContainer.data
          } else {
            // ítem product
            this.detailsContainer =
              this.detail.pk === '' || this.detail.pk === undefined
                ? await this.$api.product.details.create({
                    form: this.detail
                  })
                : await this.$api.product.details.edit({
                    pk: this.detail.pk,
                    form: this.detail
                  })
            this.detail = this.detailsContainer.data
          }
        }
        if (this.detail !== null && this.detail.cost > 0 && this.step === 3) {
          this.$toast.success(
            `${this.$tc('details_production', 1)} ${this.$tc(
              this.detail.pk !== '' ? 'edited' : 'created',
              2
            )}`
          )
          if (this.container === 'container') {
            this.$router.push({ name: 'details' })
          }
        } else {
          if (
            this.container === 'modal' &&
            !this.detail.price_type === 'matrix'
          )
            this.$toast.error(`${this.$tc('add', 1)} ${this.$tc('cost', 2)}`)
        }
        if (closed) {
          this.$emit('input', false)
          this.step = 1
        }
        if (this.newMainMatrix != null)
          this.detail.main_matrix = this.newMainMatrix.pk
        this.$emit('done', this.detail)
      } finally {
        this.isLoading3 = false
      }
    },
    /**
     * addFormula
     * Método para actualizar el campo de fórmula con la fórmula creada
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    addFormula(evt) {
      this.formulas.push(evt)
      this.detail.formula = evt.pk
    },
    /**
     * getFormulas
     * Método consultar la lista de fórmulas
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getFormulas() {
      const formula = await this.$api.formula.list({})
      this.formulas = formula.data.results
    },
    /**
     * createPrices
     * Método para crear precios por tipo de cliente en ítems y subítems
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async createPrices() {
      await this.$api.product.pricescreate({
        opt: {
          params: {
            product: this.product != null ? this.product.pk : null,
            mode: 'detail',
            template: this.detail.template,
            detail: this.mode === 'subitem' ? this.detail.item : this.detail.pk,
            subdetail: this.mode === 'subitem' ? this.detail.pk : null,
            company_id: this.company.pk
          }
        }
      })
    },
    /**
     * copyTemplateMatrix
     * Método para copiar los rangos de la matriz
     * principal
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async copyTemplateMatrix(pk) {
      if (pk == null) {
        //this.$toast.error(`${this.$tc('no_matrix', 1)}`)
      } else {
        const response = await this.$api.matrix.template.show({
          pk: pk,
          opt: {
            params: {
              productionId: this.detail.template
            }
          }
        })
        this.templateMatrix = response.data
        this.$toast.success(`${this.$tc('copy_ranges', 1)}`)
      }
    },
    /**
     * getMatrixTemplates
     * Método para consultar las matrices tipo 'producto'
     * con el finde seleccionar la que va a ser la matriz
     * principal de la plantilla de proceso de producción
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getMatrixTemplates() {
      const matrix = await this.$api.matrix.template.list({
        opt: {
          params: {
            matrix_use: 'product'
          }
        }
      })
      this.matrixs = matrix.data.results
    },
    /**
     * openMatrix
     * Método para abrir el diálogo de edición de
     * plantilla de la matriz principal de la
     * plantilla de proceso de producción
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    openMatrix(pk) {
      this.viewTemplateMatrix(pk)
      this.dialog = true
    },
    /**
     * viewTemplateMatrix
     * Método para consultar la plantilla de la matriz
     * principal de la plantilla de proceso de producción
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async viewTemplateMatrix(pk) {
      let matrix = await this.$api.matrix.template.show({
        pk: pk
      })
      this.newMainMatrix = matrix.data === undefined ? null : matrix.data
      if (this.newMainMatrix != null)
        this.detail.main_matrix = this.newMainMatrix.pk
    }
  },
  watch: {
    'detail.formula': {
      handler(val) {
        let inx = this.formulas.findIndex((m) => val == m.pk)
        if (inx > -1) {
          this.formula = this.formulas[inx]
        }
      }
    },
    'detail.product': {
      handler(val) {
        if (this.product == undefined) {
          let inx = this.prods.findIndex((m) => val == m.pk)
          if (inx > -1) {
            this.prod = this.prods[inx]
          }
        }
      }
    }
  },
  mounted() {
    this.getFormulas()
    if (this.process.main_matrix === null) this.getMatrixTemplates()
  }
}
</script>
