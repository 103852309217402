<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('typedate', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row>
      <!-- Nombre del tipo de fecha -->
      <v-col cols="12" :sm="template === 0 ? 6 : 12">
        <ValidationProvider
          vid="extra-name"
          :name="$tc('name', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :error-messages="errors[0]"
            v-model="typedate.name"
            outlined
            color="secondary"
            :label="$tc('name', 1)"
            key="extra-name-input"
          />
        </ValidationProvider>
      </v-col>

      <!-- Plantilla de proceso de producción realcionado al tipo de fecha -->
      <v-col cols="12" sm="6" v-if="template === 0">
        <dialtemplate
          v-model="dialog2"
          :to-edit="toEdit2"
          :company="company.pk"
          @done="addTemplate"
          ref="dialtemplate"
        />
        <ValidationProvider
          vid="template"
          :name="$tc('template', 1)"
          rules=""
          v-slot="{ errors }"
        >
          <v-autocomplete
            outlined
            v-model="typedate.template"
            autocomplete="off"
            :clearable="templates.length > 10"
            :error-messages="errors[0]"
            :items="templates"
            :loading="isLoading"
            class="secondary--text"
            item-text="name"
            item-value="pk"
            key="template-input"
            :append-icon="templates.length > 10 ? 'fa-search' : ''"
            prepend-inner-icon="fa-plus"
            :label="$tc('template', 1)"
            @click:prepend-inner="open()"
          />
        </ValidationProvider>
      </v-col>

      <!-- Alerta referente a la plantilla de proceso de producción -->
      <v-col cols="12" v-if="template === 0">
        <v-alert
          outlined
          type="info"
          dismissible
          :class="{
            'font-weight-black': true,
            title: !$vuetify.breakpoint.xsOnly,
            'subtitle-1': $vuetify.breakpoint.xsOnly
          }"
        >
          {{ $t('typedatealert') }}
        </v-alert>
      </v-col>
    </v-row>
  </i-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import dialtemplate from '../templatesProduct/modal.vue'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: Object,
      default: null
    },
    template: {
      type: Number,
      default: 0
    }
  },
  components: {
    dialtemplate
  },
  data() {
    return {
      load: false,
      dialog: false,
      templates: [],
      isLoading: false,
      loading: false,
      typedate: {
        template: 0,
        name: ''
      },
      edit: false,
      dialog2: false,
      toEdit2: null
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    })
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.typedate = this.toEdit
            ? { ...this.toEdit }
            : { template: 0, name: '' }
        }
      }
    }
  },
  methods: {
    /**
     * open
     * Método para abrir el diálogo de selección o edición de plantilla.
     *
     * @param {Object} item - Plantilla a editar, si existe.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    open(item) {
      this.toEdit2 = item || null
      this.dialog2 = true
    },
    /**
     * addTemplate
     * Método para añadir una nueva plantilla a la lista.
     *
     * @param {Object} evt - Evento que contiene la plantilla añadida.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    addTemplate(evt) {
      this.templates.push(evt)
      this.typedate.template = evt.pk
    },
    /**
     * getTemplateProduct
     * Método para obtener la lista de plantillas desde la API.
     *
     * Este método establece un estado de carga, realiza la llamada a la API
     * y maneja el resultado, ya sea asignando las plantillas o gestionando
     * errores.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getTemplateProduct() {
      this.isLoading = true
      try {
        const response = await this.$api.product.template.list({
          opt: {
            params: {
              order: true,
              company: this.company.pk
            }
          }
        })
        this.templates = response.data
      } catch (error) {
        console.error('Error fetching templates:', error)
      } finally {
        this.isLoading = false
      }
    },
    /**
     * submit
     * Método para edición y creación de una plantilla de proceso productivo.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submit() {
      if (this.loading) return

      this.loading = true
      try {
        if (this.template > 0) this.typedate.template = this.template

        const type = this.edit
          ? await this.$api.dashboard.typedate.edit({
              pk: this.typedate.pk,
              form: this.typedate
            })
          : await this.$api.dashboard.typedate.create({ form: this.typedate })

        this.$emit('done', type.data)
        this.$emit('input', false)
        this.$toast.success(
          `${this.$tc('typedate', 1)} ${this.$tc(
            this.edit ? 'edited' : 'created',
            1
          )}`
        )
      } catch (error) {
        console.error('Error during submission:', error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.getTemplateProduct()
  }
}
</script>
