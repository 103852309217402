<template>
  <i-modal
    :title="setModalTitle"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row class="my-0 align-items-center">
      <!-- Autocomplete mode special -->
      <v-col v-if="mode === 'special'" class="pt-0" cols="12">
        <ValidationProvider
          vid="products"
          :name="$tc('product', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-autocomplete
            autocomplete="off"
            outlined
            v-model="productprice.product"
            :items="products"
            item-text="name"
            item-value="pk"
            :error-messages="errors[0]"
            :label="$tc('product', 1)"
            class="secondary--text"
            key="selectProduct-input"
            clearable
            @change="setCost"
          ></v-autocomplete>
        </ValidationProvider>
      </v-col>

      <!-- Por porcentaje -->
      <v-col cols="12" sm="4" class="pt-0">
        <v-skeleton-loader v-if="load" type="button" tile></v-skeleton-loader>
        <v-switch
          v-show="prodCost > 0 && !load"
          class="v-switch"
          color="secondary"
          key="autoprice-input"
          name="autoprice"
          :label="$t('bypercent')"
          v-model="productprice.autoprice"
        ></v-switch>
      </v-col>

      <!-- Costos -->
      <v-col cols="6" sm="4" class="pt-0" outlined>
        <i-money
          class="font-weight-black grey--text"
          mode="text"
          :class="{ title: !$vuetify.breakpoint.xsOnly }"
          :label="$tc('cost', 2)"
          :value="prodCost"
        />
      </v-col>

      <!-- Costos de producción -->
      <v-col
        v-if="prodCost > 0 && productprice.product !== null"
        class="pt-0"
        cols="6"
        sm="4"
      >
        <i-money
          outlined
          :class="{
            'font-weight-black': true,
            title: !$vuetify.breakpoint.xsOnly,
            'error--text': productprice.price - prodCost < 0,
            'success--text': productprice.price - prodCost >= 0
          }"
          :value="productprice.price - prodCost"
          mode="text"
          :label="`${$tc('profit', 2)}:`"
        />
      </v-col>

      <!-- Porcentaje -->
      <v-col
        sm="6"
        cols="12"
        v-show="prodCost > 0"
        :class="{
          'pr-3': $vuetify.breakpoint.smAndUp
        }"
      >
        <v-skeleton-loader v-if="load" type="text" tile></v-skeleton-loader>
        <ValidationProvider
          vid="percent"
          :name="$tc('percent', 1)"
          rules="required|double:2"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            v-show="!load"
            v-model="productprice.percent"
            :error-messages="errors[0]"
            :label="$t('percent')"
            :disabled="!productprice.autoprice"
            key="percent-input"
          ></v-text-field>
        </ValidationProvider>
      </v-col>

      <!-- Precio del producto -->
      <v-col
        sm="6"
        cols="12"
        v-if="prodCost > 0 && productprice.product !== null"
      >
        <v-skeleton-loader v-if="load" type="text" tile></v-skeleton-loader>
        <ValidationProvider
          vid="price"
          :name="$tc('price', 0)"
          :rules="{
            required: true,
            min_value: Number(prodCost)
          }"
          v-slot="{ errors }"
        >
          <i-money
            outlined
            v-show="!load"
            v-model="productprice.price"
            :error-messages="errors[0]"
            name="price"
            :label="$tc('price', 0)"
            :disabled="productprice.autoprice"
            key="price-input"
          ></i-money>
        </ValidationProvider>
      </v-col>

      <!-- Alerta costo para costos menores o iguales a cero -->
      <v-col cols="12">
        <v-alert
          type="warning"
          outlined
          v-show="prodCost <= 0 && mode !== 'special'"
          :value="true"
        >
          <span class="font-weight-black title">{{
            `${$tc('costalert')}`
          }}</span>
        </v-alert>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
import { VueEditor } from 'vue2-editor'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    cost: {
      type: [Number, String],
      default: 0
    },
    product: {
      type: Number,
      default: null
    },
    companyId: {
      type: Number,
      default: null
    },
    customer: {
      type: Number,
      default: null
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      productprice: {
        name: '',
        price: 0,
        autoprice: false,
        percent: 0,
        product: null,
        customer: null,
        customerName: ''
      },
      dialog: false,
      load: false,
      edit: false,
      valid: false,
      products: [],
      prodCost: this.cost,
      selectProduct: null
    }
  },
  watch: {
    'productprice.product': {
      handler(val) {
        let inx = this.products.findIndex((m) => val == m.pk)
        if (inx > -1) {
          this.selectProduct = this.products[inx]
          this.setCost(this.selectProduct)
        }
      }
    },
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.productprice =
            this.toEdit !== null
              ? { ...this.toEdit }
              : {
                  name: '',
                  price: 0,
                  autoprice: false,
                  percent: 0,
                  product: null,
                  customer: this.customer
                }
        }
      }
    },
    'productprice.price': {
      handler(val) {
        if (val < 0) {
          val *= -1
          this.productprice.price *= -1
        }
        if (!this.productprice.autoprice) {
          this.productprice.percent =
            Math.floor((val * 100) / this.prodCost) - 100
        }
      }
    },
    'productprice.percent': {
      handler(val) {
        if (this.productprice.autoprice) {
          let estimated = parseFloat(this.prodCost * val) / 100
          this.productprice.price = (
            parseFloat(this.prodCost) + parseFloat(estimated)
          ).toFixed(2)
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          if (this.selectProduct !== null && this.level !== undefined) {
            this.productprice.product = this.selectProduct.pk
            this.productprice.level = this.level
          }
          if (this.product !== null || this.customer !== null) {
            let price = !this.edit
              ? await this.$api.product.prices.create({
                  form: this.productprice
                })
              : await this.$api.product.prices.edit({
                  pk: this.productprice.pk,
                  form: this.productprice
                })
            this.$emit('done', price.data)
          } else {
            let price = !this.edit
              ? await this.$api.product.processprices.create({
                  form: this.productprice
                })
              : await this.$api.product.processprices.edit({
                  pk: this.productprice.pk,
                  form: this.productprice
                })
            this.$emit('done', price.data)
          }
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('price', 1)} ${this.$tc('edited', 1)}`
          )
        } finally {
          this.loading = false
        }
      }
      this.getProducts()
    },
    async getProducts() {
      const product = await this.$api.product.list({
        opt: {
          params: {
            dropdown_list: true,
            active: true,
            company_id: this.companyId
          }
        }
      })
      this.products = product.data
    },
    async getLevel() {
      const customer = await this.$api.customer.show({
        pk: this.customer
      })
      this.level = customer.data.level.id
    },
    setCost(selectProduct) {
      this.prodCost =
        (this.mode === 'product' || this.mode === 'special') && selectProduct
          ? selectProduct.cost
          : this.cost
      if (this.customer !== null) {
        this.getLevel()
      }
    }
  },
  computed: {
    /**
     * setModalTitle
     * Método para asignarle el valor al título de la modal
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    setModalTitle() {
      if (this.mode === 'product' && this.productprice.name) {
        return this.productprice.name
      }
      if (this.mode === 'customer' && this.productprice.customer) {
        return this.productprice.customerName
      }
      if (this.mode === 'customer' && this.productprice.customer) {
        return this.productprice.customer
      }
      if (this.mode === 'special' && this.productprice.productName) {
        return this.productprice.productName
      }
      if (this.productprice.name) {
        return `
          ${this.$t('add', { value: this.$tc('price', 0) })}:
          ${this.productprice.name}
        `
      }
      return this.$t('add', { value: this.$tc('new') })
    }
  },
  mounted() {
    this.getProducts()
  }
}
</script>
