<route>
{
  "meta": {
    "permission": [
      "Invoices.view_invoices"
    ]
  }
}
</route>

<template>
  <v-container class="px-5" fluid>
    <v-row>
      <v-col cols="12">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <i-btn
              :title="$t('print', { value: $tc('invoice', 1) })"
              small
              v-on="on"
              absolute
              right
              color="error"
              icon="fa-print"
              classes="mx-1"
              outlined
            >
            </i-btn>
          </template>
        </v-tooltip>
      </v-col>
      <v-col
        cols="12"
        :class="{
          'grey--text': true,
          'text-uppercase': true,
          'font-weight-black': true,
          headline: $vuetify.breakpoint.lgAndUp,
          title: $vuetify.breakpoint.mdAndDown,
          'pr-3': $vuetify.breakpoint.smAndUp
        }"
        sm="6"
        md="4"
      >
        {{ $t('receivable') }} #{{ receivable.code }}
      </v-col>
      <v-col
        cols="12"
        :class="{
          'grey--text': true,
          'text-uppercase': true,
          'font-weight-black': true,
          headline: $vuetify.breakpoint.lgAndUp,
          title: $vuetify.breakpoint.mdAndDown
        }"
        sm="6"
        md="4"
      >
        {{ `${$t('date')} ${dateFormat(receivable.date_init)}` }}
      </v-col>
      <v-col
        cols="12"
        :class="{
          'pr-3': $vuetify.breakpoint.smAndUp
        }"
        sm="6"
        md="4"
      >
        <i-money
          :class="{
            'grey--text': true,
            'text-uppercase': true,
            'font-weight-black': true,
            headline: $vuetify.breakpoint.lgAndUp,
            title: $vuetify.breakpoint.mdAndDown
          }"
          :value="receivable.balance"
          :label="`${$tc('balance', 1)}:`"
          mode="text"
        >
        </i-money>
      </v-col>
    </v-row>
    <v-subheader
      :class="{
        'primary--text': true,
        'font-weight-black': true
      }"
    >
      {{
        $t('section', {
          value: $tc('customer', 1)
        })
      }}
    </v-subheader>
    <v-row no-gutters>
      <v-col
        cols="12"
        :class="{
          'pr-3': $vuetify.breakpoint.smAndUp
        }"
        sm="6"
        md="4"
        lg="3"
      >
        <v-text-field
          outlined
          name="customer"
          disabled
          :value="receivable.customer.company_name"
          readonly
          :label="$tc('customerCompany', 1)"
        />
      </v-col>
      <v-col
        cols="12"
        :class="{
          'pr-3': $vuetify.breakpoint.smAndUp
        }"
        sm="6"
        md="4"
        lg="3"
      >
        <v-text-field
          outlined
          name="contact"
          disabled
          :value="
            receivable.customer.first_name + ' ' + receivable.customer.last_name
          "
          readonly
          :label="$tc('contact', 1)"
        />
      </v-col>
      <v-col
        cols="12"
        :class="{
          'pr-3': $vuetify.breakpoint.smAndUp
        }"
        sm="6"
        md="4"
        lg="3"
      >
        <v-text-field
          outlined
          name="phone"
          disabled
          :value="receivable.customer.phone"
          readonly
          :label="$tc('phone', 1)"
        />
      </v-col>
      <v-col
        cols="12"
        :class="{
          'pr-3': $vuetify.breakpoint.smAndUp
        }"
        sm="6"
        md="4"
        lg="3"
      >
        <v-text-field
          outlined
          name="email"
          disabled
          :value="receivable.customer.email"
          readonly
          :label="$tc('email', 1)"
        />
      </v-col>
      <v-col
        cols="12"
        :class="{
          'pr-3': $vuetify.breakpoint.smAndUp
        }"
        sm="6"
        md="4"
        lg="3"
      >
        <v-text-field
          v-if="receivable.customer.address_set"
          outlined
          :name="'SHIP'"
          disabled
          :value="
            receivable.customer.address_set[1].address1 +
            ', ' +
            receivable.customer.address_set[1].address2 +
            ', ' +
            receivable.customer.address_set[1].city +
            ', ' +
            receivable.customer.address_set[1].state
          "
          readonly
          :label="$tc('customerAddress', 1)"
        />
      </v-col>
      <v-col
        cols="12"
        :class="{
          'pr-3': $vuetify.breakpoint.smAndUp
        }"
        sm="6"
        md="4"
        lg="3"
      >
        <v-text-field
          v-if="receivable.customer.address_set"
          outlined
          :name="'BILL'"
          disabled
          :value="
            receivable.customer.address_set[0].address1 +
            ', ' +
            receivable.customer.address_set[0].address2 +
            ', ' +
            receivable.customer.address_set[0].city +
            ', ' +
            receivable.customer.address_set[0].state
          "
          readonly
          :label="$tc('shippingAddress', 1)"
        />
      </v-col>
    </v-row>
    <v-subheader
      :class="{
        'primary--text': true,
        'font-weight-black': true
      }"
    >
      {{
        $t('section', {
          value: $tc('invoice', 1)
        })
      }}
    </v-subheader>
    <v-row>
      <v-col
        class="listcard"
        v-for="invoice in invoices"
        :key="invoice.pk"
        cols="12"
        sm="6"
        md="4"
      >
        <div
          :class="{
            'primary--text': true,
            'font-weight-bold': true
          }"
          @click="open(invoice.order)"
        >
          <dialinvoice
            v-if="toEdit != null"
            v-model="dialog"
            :to-edit="toEdit"
            @done="reload = true"
          />
          <span> {{ $t('invoice') }} #{{ invoice.invoice_number }} </span>
          <v-chip
            x-small
            :color="
              invoice.status === 'paid'
                ? 'success'
                : invoice.status === 'canceled'
                ? 'red'
                : 'info'
            "
          >
            {{ $tc(invoice.status, 2) }}
          </v-chip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <i-btn
                v-if="getPermissions([`Invoices.view_payments`]) || isAdmin"
                :title="$t('print', { value: $tc('invoice', 1) })"
                small
                v-on="on"
                color="error"
                classes="mx-1"
                outlined
                icon="fa-print"
                @click="open(invoice.order)"
              >
              </i-btn>
            </template>
          </v-tooltip>
        </div>
        <div
          :class="{
            'primary--text': true,
            'font-weight-bold': true,
            'subtitle-2': true,
            'mb-1': true
          }"
        >
          {{
            `${$tc('order', 1)} #${invoice.extraInformation.orderNumber} - ${
              invoice.extraInformation.orderName
            }`
          }}
        </div>
        <v-list style="margin-top: -0.3rem" dense class="py-0">
          <v-list-group @click.stop="">
            <v-icon slot="appendIcon" size="12">fas fa-arrow-down</v-icon>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="primary--text subtitle-2 font-weight-black">{{
                    $tc('product', 2)
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              dense
              v-for="param in invoice.extraInformation.products"
              :key="'param-' + param.code"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text"
                  style="font-size: 12px; font-weight: 400"
                >
                  <b>&middot;</b>
                  <span class="font-weight-black">{{
                    `${param.own_prod}:`
                  }}</span>
                  <br />
                  <i-money mode="text" :value="param.price">
                    <span class="font-weight-black">{{
                      `${$tc('price', 1)}:`
                    }}</span>
                  </i-money>
                  <i-money mode="text" :value="param.tax">
                    <span class="font-weight-black">{{
                      `${$tc('tax', 1)}:`
                    }}</span>
                  </i-money>
                  <br />
                  <i-money mode="text" :value="param.subtotal">
                    <span class="font-weight-black">{{
                      `${$tc('subtotal', 1)}:`
                    }}</span>
                  </i-money>
                  <i-money mode="text" :value="param.total">
                    <span class="font-weight-black">{{
                      `${$tc('total', 1)}:`
                    }}</span>
                  </i-money>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-divider />
        <v-row no-gutters>
          <v-col cols="6" sm="4" md="3">
            <span
              :class="{
                'primary--text': true,
                'text-uppercase': true,
                'font-weight-black': true,
                'subtitle-2': true
              }"
            >
              {{ `${$tc('subtotal', 1)}:` }}
            </span>
            <br />
            <i-money
              :class="{
                'primary--text': true,
                'text-uppercase': true,
                'font-weight-black': true,
                'subtitle-2': true
              }"
              :value="invoice.subtotal"
              mode="text"
            >
            </i-money>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <span
              :class="{
                'primary--text': true,
                'text-uppercase': true,
                'font-weight-black': true,
                'subtitle-2': true
              }"
            >
              {{ `${$tc('tax', 1)}:` }}
            </span>
            <br />
            <i-money
              :class="{
                'primary--text': true,
                'text-uppercase': true,
                'font-weight-black': true,
                'subtitle-2': true
              }"
              :value="invoice.tax"
              mode="text"
            >
            </i-money>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <span
              :class="{
                'primary--text': true,
                'text-uppercase': true,
                'font-weight-black': true,
                'subtitle-2': true
              }"
            >
              {{ `${$tc('total', 1)}:` }}
            </span>
            <br />
            <i-money
              :class="{
                'primary--text': true,
                'text-uppercase': true,
                'font-weight-black': true,
                'subtitle-2': true
              }"
              :value="invoice.total"
              mode="text"
            >
            </i-money>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <span
              :class="{
                'primary--text': true,
                'text-uppercase': true,
                'font-weight-black': true,
                'subtitle-2': true
              }"
            >
              {{ `${$tc('balance', 1)}:` }}
            </span>
            <br />
            <i-money
              :class="{
                'primary--text': true,
                'text-uppercase': true,
                'font-weight-black': true,
                'subtitle-2': true
              }"
              :value="invoice.balance"
              mode="text"
            >
            </i-money>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-subheader
      :class="{
        'primary--text': true,
        'font-weight-black': true
      }"
    >
      {{
        $t('section', {
          value: $tc('payment', 1)
        })
      }}
    </v-subheader>
    <payments
      v-if="receivable.pk !== ''"
      @createdPayment="updateValues()"
      @changeMode="$emit('changeMode', $event)"
      :balance="receivable.balance"
      :receivable="receivable.pk"
    />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import payments from '../../components/invoice/payments.vue'
import dialinvoice from '../../components/invoice/modal.vue'
export default {
  components: {
    payments,
    dialinvoice
  },
  data: () => ({
    receivable: {
      balance: 0.0,
      customer: null,
      customer: {
        customer: '',
        contact: '',
        phone: '',
        email: '',
        SHIP: '',
        BILL: ''
      },
      code: '',
      date_end: null,
      date_init: null,
      deadline: null,
      pk: null,
      state: 'draft'
    },
    loading: false,
    invoices: [],
    reload: false,
    dialog: false,
    toEdit: null
  }),
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    })
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    updateValues() {
      this.getReceivable()
      this.getInvoices()
    },
    getReceivable() {
      let validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      this.isLoading = true
      this.$api.invoice.receivable
        .show({
          pk: validation
        })
        .then((res) => {
          this.receivable = res.data
          this.getInvoices()
        })
        .finally(() => (this.isLoading = false))
    },
    getInvoices() {
      this.loading = true
      if (this.receivable.code !== '') {
        this.$api.invoice
          .show({
            pk: this.receivable.code,
            opt: {
              params: {
                receivable: true
              }
            }
          })
          .then((res) => {
            this.invoices = res.data
          })
          .finally(() => (this.loading = false))
      }
    }
  },
  mounted() {
    this.getReceivable()
  }
}
</script>
