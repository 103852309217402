var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"my-0"},[_c('dialprice',{attrs:{"to-edit":_vm.toEditPrice,"cost":_vm.cost,"customer":_vm.customer,"mode":_vm.mode,"product":_vm.product,"companyId":_vm.company.pk},on:{"done":function($event){_vm.reload = true}},model:{value:(_vm.dialogPrice),callback:function ($$v) {_vm.dialogPrice=$$v},expression:"dialogPrice"}}),_c('diallevel',{attrs:{"to-edit":_vm.toEditLevel},on:{"done":_vm.getLevels},model:{value:(_vm.dialogLevel),callback:function ($$v) {_vm.dialogLevel=$$v},expression:"dialogLevel"}}),(_vm.mode)?_c('v-col',{staticClass:"pt-0 pb-3",attrs:{"cols":"12"}},[_c('i-card-list',{staticClass:"mt-2 px-2",attrs:{"dontSearch":"","app":_vm.mode === 'process' ? 'product.processprices' : 'product.productprices',"api":_vm.mode === 'process' ? 'product.processprices' : 'product.prices',"dontCreate":_vm.otherMode,"headers":_vm.headers,"title":_vm.setTitle(_vm.mode),"opt":{
        mode: _vm.mode,
        customer: _vm.customer,
        product: _vm.product,
        template: _vm.template,
        detail: _vm.detail,
        subdetail: _vm.subdetail != '' ? _vm.subdetail : null
      },"reload":_vm.reload},on:{"update:reload":function($event){_vm.reload=$event},"click:edit":function($event){return _vm.open($event)},"click:create":function($event){return _vm.openLevel()}},scopedSlots:_vm._u([(_vm.mode === 'product')?{key:"noData",fn:function({ value }){return [(value)?_c('span',{staticClass:"headline font-weight-black error--text"},[_vm._v(" ("+_vm._s(_vm.$t('levelRequired'))+") ")]):_vm._e()]}}:null,(_vm.mode === 'product' || _vm.mode === 'process')?{key:`item.pk`,fn:function({ value }){return [_c('span',{staticClass:"title font-weight-black primary--text"},[_vm._v(" "+_vm._s(value)+" ")])]}}:null,(_vm.mode === 'product' || _vm.mode === 'process')?{key:`item.detail`,fn:function({ value }){return [_c('span',{staticClass:"title font-weight-black primary--text"},[_vm._v(" "+_vm._s(value)+" ")])]}}:null,(_vm.mode === 'product' || _vm.mode === 'process')?{key:`item.subdetail`,fn:function({ value }){return [_c('span',{staticClass:"title font-weight-black primary--text"},[_vm._v(" "+_vm._s(value)+" ")])]}}:null,(_vm.mode === 'product' || _vm.mode === 'process')?{key:`item.name`,fn:function({ value }){return [_c('p',{staticClass:"title font-weight-black primary--text mb-2"},[_vm._v(" "+_vm._s(value)+" ")])]}}:null,(_vm.mode === 'customer')?{key:`item.customerName`,fn:function({ value }){return [_c('span',{staticClass:"title font-weight-black primary--text"},[_vm._v(" "+_vm._s(value)+" ")])]}}:null,(_vm.mode === 'special')?{key:`item.productName`,fn:function({ value }){return [_c('span',{staticClass:"title font-weight-black primary--text"},[_vm._v(" "+_vm._s(value)+" ")])]}}:null,{key:`item.price`,fn:function({ value }){return [_c('i-money',{staticClass:"primary--text",attrs:{"value":value,"mode":"text"}}),_c('p',{staticClass:"mb-0"},[_c('span',{class:value - _vm.cost < 0
                ? 'error--text font-weight-black'
                : 'primary--text font-weight-black'},[_vm._v(" "+_vm._s(_vm.$t('profit'))+": ")]),_c('i-money',{staticClass:"primary--text",attrs:{"mode":"text","value":value - _vm.cost}})],1)]}},{key:`item.percent`,fn:function({ value }){return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(`${value}%`)+" ")])]}},(_vm.mode === 'product')?{key:"alert",fn:function({ value }){return [(value)?_c('v-alert',{attrs:{"outlined":"","type":"info","dismissible":""}},[_c('ul',[_c('li',{staticClass:"title-responsive"},[_vm._v(" "+_vm._s(_vm.$t('priceZero'))+" ")]),_c('li',{staticClass:"title-responsive"},[_vm._v(" "+_vm._s(_vm.$t('with_autoprice'))+" ")])])]):_vm._e()]}}:null],null,true)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }