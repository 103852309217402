<route>
{
  "meta": {
    "permission": [
      "emails.view_credentials"
    ]
  }
}
</route>

<template>
  <div>
    <dialsettings
      v-model="dialog"
      :to-edit="toEdit"
      @done="reload = true"
      :main="true"
    />
    <i-card-list
      class="mt-2 px-2"
      :title="$t('email_setting.email_SMTP_setting')"
      :headers="headers"
      app="emails.credentials"
      api="email.config"
      :method="'list'"
      :reload.sync="reload"
      :opt="{
        company: this.company.pk,
        main: true
      }"
      @click:create="open()"
      @click:edit="open($event)"
      @deleted="updateList(value)"
    >
      <template v-slot:[`item.default_setting`]="{ value }">
        <span class="primary--text">
          <v-icon
            small
            :color="value ? 'success' : 'error'"
            v-text="value ? 'fa-check' : 'fa-times'"
          />
        </span>
      </template>
    </i-card-list>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialsettings from '../../components/email/emailSettings.vue'
export default {
  components: {
    dialsettings
  },
  data: () => ({
    reload: false,
    dialog: false,
    toEdit: null
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$tc('email', 1),
          value: 'account'
        },
        {
          text: this.$t('email_setting.host'),
          value: 'host'
        },
        {
          text: this.$tc('email_setting.host_port', 2),
          value: 'host_port'
        },
        {
          text: this.$t('email_setting.default_setting'),
          value: 'default_setting'
        }
      ]
    },
    ...mapGetters({
      company: 'company/getCompanyData'
    })
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    updateList() {
      this.reload = true
    }
  }
}
</script>
