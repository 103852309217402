<route>
{
  "meta": {
    "permission": [
      "emails.view_maillist"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <i-table
      :title="$tc('email', 2)"
      :headers="headers"
      app="emails.maillist"
      api="email"
      :reload.sync="reload"
      @click:create="changeRoute({ pk: 'create' })"
      @click:edit="changeRoute"
    >
      <template v-slot:item.body="{ item }">
        <span v-html="item.body"></span>
      </template>
      <template v-slot:item.sended="{ item }">
        <span>{{ getDatestamp(item.sended) }}</span>
      </template>
      <template v-slot:item.success="{ item }">
        <v-icon
          :color="item.success ? 'success' : 'error'"
          v-text="item.success ? 'mdi-check' : 'mdi-close'"
        ></v-icon>
        <v-btn
          plain
          @click="resend(item)"
          v-if="!item.success"
          :disabled="loading"
        >
          <v-icon>mdi-reload</v-icon>
          <v-tooltip activator="parent" location="start">
            {{ $t('resend') }}
          </v-tooltip>
        </v-btn>
      </template>
    </i-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      reload: false
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    }),
    headers() {
      return [
        {
          text: this.$t('addressee'),
          value: 'recipient_list'
        },
        {
          text: this.$t('subject'),
          value: 'subject'
        },
        {
          text: this.$t('body'),
          value: 'body'
        },
        {
          text: this.$t('created_date'),
          value: 'sended'
        },
        {
          text: this.$t('status'),
          value: 'success'
        }
      ]
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'sendEmail-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    getDatestamp(date) {
      let d = new Date(date)
      let format = `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(
        -2
      )}-${('0' + d.getDate()).slice(-2)}`
      return `${this.dateFormat(format)} ${d.toLocaleTimeString()}`
    },
    resend(item) {
      this.loading = true
      this.reload = false
      this.$api.email
        .send({
          form: { ...item, company_id: this.company.pk },
          opt: {
            params: {
              template: false
            }
          }
        })
        .then((response) => {
          if (response.status == 422) {
            this.$toast.error(response.data.message)
            console.log(response.status)
          } else {
            this.$toast.success(`${this.$tc('email_sent', 1)}`)
            this.reload = true
            this.$emit('createEmail', response.data)
            this.$emit('clearStoreEmail')
            this.$emit('done')
            var audio = new Audio(require('@/../public/sounds/sound2.wav'))
            audio.play()
          }
        })
        .catch((err) => {
          this.$toast.error(`${this.$t('error_send_email')}`)
          this.reload = true
          this.$emit('createEmail', response.data)
          this.$emit('clearStoreEmail')
          this.$emit('done')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
