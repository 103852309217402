<template>
  <v-dialog
    class="standard-size"
    content-class="dialog-product--modal"
    persistent
    scrollable
    width="97vw"
    v-model="dialog"
    ref="dialog_product"
  >
    <v-card class="dialog-product--modal-card">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit(submit)">
          <getBodyEmail
            app_label="sales"
            model="orderproduct"
            :condition="
              subOrder.status !== oldStatus && subOrder.status !== null
            "
            :custumerNotification="subOrder.clientNotification"
            :stateMain="subOrder.orderMain"
            :stateID="subOrder.status"
            :template="subOrder.template"
            :subject="
              $t('orderupdate', {
                product: subOrder.own_prod,
                old: oldStatusName,
                new: subOrder.orderStatus
              })
            "
            :recipient_list="subOrder.clientEmail"
            ref="getbodyemail"
          />
          <i-toolbar
            dark
            color="secondary"
            dontSave
            :title="`${subOrder.own_prod}`"
            :loading="loading"
          >
            <template v-slot:close>
              <i-btn
                text
                icon="fa-times"
                :loading="load"
                :small="$vuetify.breakpoint.smAndDown"
                :title="$t('close')"
                @click="close()"
              >
              </i-btn>
            </template>
            <template v-slot:arrowLeft>
              <v-divider class="mx-4" inset vertical></v-divider>
              <div style="display: block; min-width: 64px">
                <i-btn
                  v-if="step > 1 && creatingDetail === 1"
                  color="text-dark"
                  icon="fa-arrow-left"
                  text
                  :small="$vuetify.breakpoint.smAndDown"
                  :title="$t('back')"
                  @click="back()"
                >
                </i-btn>
              </div>
            </template>
            <template v-slot:arrowRight>
              <div style="display: block; min-width: 64px">
                <i-btn
                  v-if="showNext()"
                  color="text-dark"
                  icon="fa-arrow-right"
                  text
                  :small="$vuetify.breakpoint.smAndDown"
                  :title="$t('next')"
                  @click="next()"
                >
                </i-btn>
              </div>
            </template>
            <template v-slot:submit>
              <i-btn
                v-if="creatingDetail === 1"
                icon="fa-save"
                text
                :small="$vuetify.breakpoint.smAndDown"
                :title="$tc('save', 1)"
                @click="submit()"
              >
              </i-btn>
            </template>
          </i-toolbar>
          <v-card-text class="px-0">
            <v-container fluid>
              <v-stepper style="z-index: 0" class="elevation-0" v-model="step">
                <!-- Lista de pasos -->
                <v-stepper-header class="elevation-0">
                  <v-stepper-step :step="1" edit-icon="$complete" editable>
                    {{ $t('basic') }}
                  </v-stepper-step>
                  <v-divider v-show="subOrder.pk"></v-divider>
                  <v-stepper-step
                    v-show="subOrderBtn.details && subOrder.pk"
                    edit-icon="$complete"
                    :step="2"
                    :editable="subOrder.pk !== ''"
                  >
                    {{ $tc('price_calculation', 2) }}
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <!-- Paso 1 -->
                  <v-stepper-content :step="1">
                    <v-row class="my-0">
                      <!-- Imagen -->
                      <v-col cols="12" md="3">
                        <div v-if="!clone" class="position-relative">
                          <v-skeleton-loader
                            v-if="loading || loadingIMG"
                            type="image"
                            tile
                          ></v-skeleton-loader>
                          <i-image
                            v-else
                            class="image-repo"
                            v-model="imageUrl"
                            :readonly="
                              subOrder.orderMain == 'canceled' ||
                              subOrder.orderMain == 'finished'
                            "
                            :file.sync="subOrder.thumbnail"
                          />
                          <span
                            v-if="!loading || !loadingIMG"
                            class="clone-image-button"
                          >
                            <v-icon
                              color="primary"
                              medium
                              @click="cloneImage()"
                              v-text="'fa-clone'"
                            />
                          </span>
                        </div>
                        <div v-else class="position-relative">
                          <v-skeleton-loader
                            v-if="loading || loadingIMG"
                            type="image"
                            tile
                          ></v-skeleton-loader>
                          <i-image
                            v-else
                            class="image-repo"
                            v-model="imageUrl0"
                            :readonly="
                              subOrder.orderMain == 'canceled' ||
                              subOrder.orderMain == 'finished'
                            "
                            :file.sync="subOrder.thumbnail"
                          />
                          <span
                            v-if="loading || loadingIMG"
                            class="clone-image-button"
                          >
                            <v-icon
                              color="primary"
                              medium
                              @click="cloneImage()"
                              v-text="'fa-clone'"
                            />
                          </span>
                        </div>
                      </v-col>

                      <!-- Demás datos -->
                      <v-col cols="12" md="9">
                        <v-row class="my-0">
                          <v-col cols="12">
                            <v-row class="mt-0 mb-5">
                              <v-col
                                v-if="subOrder.code !== ''"
                                cols="12"
                                sm="6"
                                md="4"
                              >
                                <v-skeleton-loader
                                  v-if="loading"
                                  type="text"
                                  tile
                                ></v-skeleton-loader>
                                <v-text-field
                                  v-else
                                  color="secondary"
                                  disabled
                                  key="code-input"
                                  outlined
                                  :label="$tc('code', 1)"
                                  v-model="subOrder.code"
                                >
                                </v-text-field>
                              </v-col>

                              <!-- Nombre de la suborden -->
                              <v-col
                                cols="12"
                                sm="6"
                                v-if="
                                  subOrder !== undefined && subOrder !== null
                                "
                                :md="
                                  subOrder.orderMain === 'in_process' ||
                                  subOrder.orderMain === 'finished'
                                    ? 4
                                    : 6
                                "
                              >
                                <v-skeleton-loader
                                  v-if="loading"
                                  type="text"
                                  tile
                                ></v-skeleton-loader>
                                <ValidationProvider
                                  vid="name"
                                  :name="$tc('name', 1)"
                                  :rules="{ required: step == 1 }"
                                  v-slot="{ errors }"
                                >
                                  <v-text-field
                                    outlined
                                    v-show="!loading"
                                    :disabled="
                                      !(
                                        subOrder.orderMain == 'draft' ||
                                        subOrder.orderMain == 'quote'
                                      )
                                    "
                                    :error-messages="errors[0]"
                                    v-model="subOrder.name"
                                    color="secondary"
                                    :label="$tc('subname', 1)"
                                    key="name-input"
                                  >
                                    <div
                                      slot="prepend"
                                      v-if="
                                        projectName !== '' &&
                                        projectName !== null &&
                                        projectName !== undefined
                                      "
                                    >
                                      <v-icon
                                        medium
                                        @click="cloneNameProject()"
                                        color="primary"
                                        v-text="'fa-clone'"
                                      />
                                    </div>
                                  </v-text-field>
                                </ValidationProvider>
                              </v-col>

                              <!-- Nombre del cliente -->
                              <v-col
                                v-show="subOrderBtn.customer"
                                cols="12"
                                sm="6"
                                :md="
                                  subOrder.orderMain === 'in_process' ||
                                  subOrder.orderMain === 'finished'
                                    ? 4
                                    : 6
                                "
                              >
                                <v-skeleton-loader
                                  v-if="loading"
                                  type="text"
                                  tile
                                ></v-skeleton-loader>
                                <v-text-field
                                  v-show="!loading"
                                  color="secondary"
                                  outlined
                                  disabled
                                  :value="
                                    subOrder.clientName == undefined
                                      ? customer
                                      : subOrder.clientName
                                  "
                                  :label="$tc('customer', 1)"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col
                                v-if="
                                  (subOrder.orderMain === 'in_process' ||
                                    subOrder.orderMain === 'finished') &&
                                  stages.length > 0 &&
                                  subOrder.template
                                "
                                cols="12"
                                sm="6"
                                md="4"
                              >
                                <v-skeleton-loader
                                  v-if="loading"
                                  type="list-item-avatar"
                                  tile
                                ></v-skeleton-loader>
                                <v-select
                                  v-show="!loading"
                                  color="primary"
                                  item-value="pk"
                                  outline
                                  outlined
                                  :disabled="subOrder.orderMain === 'finished'"
                                  :item-text="getNameStatus"
                                  :items="stages"
                                  :label="$t('suborderstatus')"
                                  v-model="subOrder.status"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <div class="container-buttons">
                          <!-- Botón de Descripción -->
                          <v-btn
                            @click="activate(1)"
                            :color="
                              divDescription
                                ? 'secondary'
                                : subOrder.description != ''
                                ? 'success'
                                : ''
                            "
                          >
                            {{ $tc('description', 1) }}
                          </v-btn>
                          <!-- Botón de Información adicional -->
                          <v-btn
                            @click="activate(2)"
                            :color="
                              divAditionalSide
                                ? 'secondary'
                                : subOrder.additional_side != ''
                                ? 'success'
                                : ''
                            "
                          >
                            {{ $tc('additional_side', 1) }}
                          </v-btn>
                          <!-- Botón de Repositorio -->
                          <v-btn
                            v-if="
                              subOrder.pk !== '' && subOrderBtn.imageRepository
                            "
                            :color="divRepository ? 'secondary' : ''"
                            @click="activate(3)"
                          >
                            {{ $tc('repository', 1) }}
                          </v-btn>
                          <!-- Botón de Logs -->
                          <v-btn
                            v-if="subOrder.pk !== ''"
                            :color="divLogs ? 'secondary' : ''"
                            @click="activate(5)"
                          >
                            Logs
                          </v-btn>
                          <!-- Botón de Fechas -->
                          <v-btn
                            v-if="subOrder.pk !== ''"
                            :color="divDates ? 'secondary' : ''"
                            @click="activate(6)"
                          >
                            {{ $tc('dates', 1) }}
                          </v-btn>
                          <!-- Botón de Notas/Chat -->
                          <div
                            v-if="
                              subOrder.orderMain === 'in_process' ||
                              subOrder.orderMain === 'in_production' ||
                              subOrder.orderMain === 'production_ready' ||
                              subOrder.orderMain === 'delivery' ||
                              subOrder.orderMain === 'finished' ||
                              subOrder.orderMain === 'correction' ||
                              subOrder.orderMain === 'canceled'
                            "
                          >
                            <v-btn
                              v-if="subOrder.pk !== ''"
                              :color="divNotes ? 'secondary' : ''"
                              :class="hasChatMessages ? 'has-messages' : ''"
                              @click="activate(4)"
                            >
                              {{ $tc('notes_chat', 1) }}
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <!-- Descripción -->
                        <div v-show="divDescription">
                          <v-skeleton-loader
                            v-if="loading"
                            type="article"
                            tile
                          ></v-skeleton-loader>
                          <label v-if="!loading" for="description">
                            {{ $t('description') }}
                          </label>
                          <vue-editor
                            v-if="!loading"
                            id="description"
                            name="description"
                            v-model="subOrder.description"
                            :disabled="
                              subOrder.orderMain === 'in_process' ||
                              subOrder.orderMain === 'finished'
                            "
                            :readonly="
                              subOrder.orderMain === 'in_process' ||
                              subOrder.orderMain === 'finished'
                            "
                            :editorToolbar="customToolbar"
                          >
                          </vue-editor>
                        </div>
                        <!-- Información adicional -->
                        <div v-show="divAditionalSide">
                          <v-skeleton-loader
                            v-if="loading"
                            type="article"
                            tile
                          ></v-skeleton-loader>
                          <label v-if="!loading" for="additional_side">
                            {{ $t('additional_side') }}
                          </label>
                          <vue-editor
                            v-if="!loading"
                            id="additional_side"
                            v-model="subOrder.additional_side"
                            :disabled="
                              subOrder.orderMain === 'in_process' ||
                              subOrder.orderMain === 'finished'
                            "
                            :readonly="
                              subOrder.orderMain === 'in_process' ||
                              subOrder.orderMain === 'finished'
                            "
                            :editorToolbar="customToolbar"
                          >
                          </vue-editor>
                        </div>
                        <!-- Repositorio -->
                        <div v-show="divRepository">
                          <repository
                            v-if="
                              subOrder.pk !== '' && subOrderBtn.imageRepository
                            "
                            :subOrderPk="subOrder.pk"
                          />
                        </div>
                        <!-- Notas/Chat -->
                        <div ref="scrollTarget" v-show="divNotes">
                          <orderMessages
                            ref="orderMessagesComponent"
                            :chat_id="subOrder.chat_id"
                            @file-picked="scrollToBottom"
                          />
                        </div>
                        <!-- Logs -->
                        <div v-show="divLogs">
                          <div
                            v-for="(value, key) in logs"
                            :key="key"
                            class="border rounded mb-2 py-2 px-3"
                          >
                            <p class="mb-1">
                              <span class="font-weight-bold">
                                {{ $tc('suborder', 1) }}:
                              </span>
                              {{
                                $tc(answerList[value.action]) ||
                                $tc(answerList[4])
                              }}
                            </p>
                            <p class="mb-1">
                              <span class="font-weight-bold">
                                {{ $tc('date') }}:
                              </span>
                              {{ value.date }}
                            </p>
                            <p class="mb-1">
                              <span class="font-weight-bold">
                                {{ $tc('time') }}:
                              </span>
                              {{ value.time }}
                            </p>
                            <p class="mb-1 font-weight-bold">
                              {{ $tc('action') }}:
                            </p>
                            <ul>
                              <li
                                v-for="(item, attr) in value.changes"
                                :key="attr"
                              >
                                {{ attr }}: {{ item[0] }}
                                <span
                                  class="font-weight-bold"
                                  style="color: var(--success)"
                                >
                                  ->
                                </span>
                                {{ item[1] }}
                              </li>
                            </ul>
                            <p class="mb-1">
                              <span class="font-weight-bold">Usuario:</span>
                              {{ value.fullname }}
                            </p>
                          </div>
                        </div>
                        <!-- Fechas -->
                        <div v-show="divDates">
                          <typedate
                            v-if="
                              subOrderBtn.typedate &&
                              subOrder.pk !== '' &&
                              product !== null
                            "
                            :order="subOrder.pk"
                            :template="subOrder.template"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <!-- Paso 2 -->
                  <v-stepper-content
                    v-if="subOrder.pk"
                    class="dialog-product--modal-second-step"
                    :step="2"
                  >
                    <!-- ???? -->
                    <div v-show="false">
                      <showProductPrice
                        :pkProduct="subOrder.product"
                        :priceSuggest="priceSuggest"
                        :type_customer="subOrder.level"
                        :customer="customer"
                        @change="subOrder.price = $event"
                      />
                    </div>

                    <!-- El tipo de precio de la suborden es matriz -->
                    <v-row v-if="subOrder.price_type === 'matrix'">
                      <v-col>
                        <i-matrix
                          :type_customer="subOrder.level"
                          :productId="subOrder.product"
                          :priceString="subOrder.priceString"
                          :price="subOrder.price"
                          :qty="subOrder.quantity"
                          :subOrder.sync="subOrder"
                          :showMatrix="false"
                          :matrixUse="'details'"
                          @change="subOrder.price = $event"
                        >
                        </i-matrix>
                      </v-col>
                    </v-row>

                    <!-- El tipo de precio de la suborden no es matriz -->
                    <v-row v-if="subOrder.price_type !== 'matrix'">
                      <!-- El aviso del f2 -->
                      <v-col class="pt-0" cols="12">
                        <p class="font-weight-bold body-2 my-0">
                          <span v-show="!showCost">{{ $t('f2key') }}</span>
                          <i-money
                            v-show="showCost"
                            class="font-weight-black primary--text"
                            mode="text"
                            :value="subOrder.costprod"
                            :label="`${$tc('cost', 1)} = `"
                          />
                        </p>
                      </v-col>

                      <!-- switch redondeo -->
                      <v-col v-if="measure" cols="12">
                        <v-skeleton-loader
                          v-if="
                            loading &&
                            measure !== undefined &&
                            measure !== null &&
                            measure.rounded
                          "
                          type="list-item-avatar"
                          tile
                        ></v-skeleton-loader>
                        <v-switch
                          v-show="!loading"
                          class="v-switch mt-0"
                          color="primary"
                          key="rounded-input"
                          name="rounded"
                          :disabled="
                            !(
                              subOrder.orderMain == 'draft' ||
                              subOrder.orderMain == 'quote'
                            )
                          "
                          :label="$tc('rounded', 1)"
                          v-model="subOrder.rounded"
                        ></v-switch>
                      </v-col>

                      <!-- Select de fórmula de venta -->
                      <v-col
                        v-show="
                          subOrderBtn.soldby &&
                          subOrder.price_type === 'formula'
                        "
                        cols="12"
                        sm="6"
                        lg="3"
                      >
                        <v-skeleton-loader
                          v-if="loading"
                          type="list-item-avatar"
                          tile
                        ></v-skeleton-loader>
                        <ValidationProvider
                          vid="measure"
                          :name="$tc('measure', 1)"
                          :rules="{ required: subOrder.pk !== '' && step == 2 }"
                          v-slot="{ errors }"
                        >
                          <v-select
                            v-show="!loading"
                            color="primary"
                            item-text="name"
                            item-value="pk"
                            outline
                            outlined
                            :disabled="
                              !(
                                subOrder.orderMain == 'draft' ||
                                subOrder.orderMain == 'quote'
                              ) || !subOrderBtn.soldbyEdit
                            "
                            :error-messages="errors[0]"
                            :items="measures"
                            :label="$t('soldby')"
                            v-model="subOrder.measure"
                          ></v-select>
                        </ValidationProvider>
                      </v-col>

                      <!-- Cantidad -->
                      <v-col cols="12" sm="6" lg="3">
                        <ValidationProvider
                          vid="amount"
                          :name="$tc('amount', 1)"
                          :rules="{ required: step == 2 }"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            outlined
                            :disabled="
                              !(
                                subOrder.orderMain == 'draft' ||
                                subOrder.orderMain == 'quote'
                              )
                            "
                            type="text"
                            v-model="subOrder.quantity"
                            color="secondary"
                            :label="$tc('amount', 1)"
                            :error-messages="errors[0]"
                          />
                        </ValidationProvider>
                      </v-col>

                      <!-- Precio si es base -->
                      <v-col
                        cols="12"
                        sm="4"
                        md="3"
                        v-show="subOrder.price_type === 'base'"
                      >
                        <v-alert outlined color="primary">
                          <i-money
                            mode="text"
                            :label="`${$tc('price', 1)}:`"
                            v-model="subOrder.price"
                          />
                        </v-alert>
                      </v-col>

                      <!-- Subtotal si es base -->
                      <v-col
                        cols="12"
                        sm="4"
                        md="3"
                        v-show="subOrder.price_type === 'base'"
                      >
                        <v-alert type="info" outlined color="primary">
                          <i-money
                            mode="text"
                            :value="subOrder.price * subOrder.quantity"
                            :label="$t('subtotal')"
                          />
                        </v-alert>
                      </v-col>

                      <!-- Si es fórmula -->
                      <v-col v-if="measure" class="pt-0" cols="12">
                        <i-formula
                          v-show="
                            step === 2 &&
                            measure !== undefined &&
                            measure !== null &&
                            subOrder.price_type === 'formula'
                          "
                          :readonly="
                            !(
                              subOrder.orderMain == 'draft' ||
                              subOrder.orderMain == 'quote'
                            )
                          "
                          showResult
                          :dimensions="subOrder.dimensions"
                          :defaultMeasure="defaultmeasure"
                          :price="
                            subOrder.prodPrice === 0
                              ? priceSuggest
                              : subOrder.prodPrice
                          "
                          :cost="subOrder.costprod"
                          :measure="measure.name.split('(')[1].replace(')', '')"
                          :formula="measure.formula"
                          :rounded="
                            !(
                              measure !== undefined &&
                              measure !== null &&
                              measure.rounded
                            )
                              ? false
                              : subOrder.rounded
                          "
                          :qty="subOrder.quantity"
                          :subOrder="subOrder"
                          @change="subOrder.price = $event"
                          @changedimensions="subOrder.dimensions = $event"
                          v-model="subOrder.priceString"
                        />
                      </v-col>
                    </v-row>

                    <!-- Detalles de producción -->
                    <v-row v-if="process.details.length > 0" class="mt-0 mb-5">
                      <v-col
                        class="d-flex flex-row g-4 align-items-center"
                        cols="12"
                        md="6"
                        v-for="(item, key) in process.details"
                        :key="key.pk"
                      >
                        <div class="text-right" style="width: 150px">
                          {{ item.name }}
                        </div>
                        <v-select
                          v-if="item.subitems"
                          outlined
                          hide-details
                          :disabled="disableItem(item.pk, item.multi_choice)"
                          :items="filterSubItems(item.pk)"
                          item-text="name"
                          item-value="pk"
                          :key="'select' + item.pk"
                          :label="item.name"
                          @change="activeDetail(process.subdetail, item.pk)"
                          v-model="process.subdetail"
                        ></v-select>
                        <v-switch
                          v-else
                          class="v-switch mt-3"
                          color="primary"
                          :key="'btn' + item.pk"
                          name="hidden"
                          :disabled="disableItem(item.pk, item.multi_choice)"
                          :label="$t('add2', { value: $tc('suborder', 1) })"
                          :value="disableItem(item.pk, item.multi_choice)"
                          @click="activeDetail(item)"
                        ></v-switch>
                      </v-col>
                    </v-row>

                    <!-- Detalles de producción guardados en la suborden -->
                    <detailsByOrder
                      :creatingDetail.sync="creatingDetail"
                      :defaultMeasure="defaultmeasure"
                      :details="details"
                      :measure="
                        measure !== undefined && measure !== null
                          ? measure.name.split('(')[1].replace(')', '')
                          : ''
                      "
                      :order="subOrder.pk"
                      :qty="subOrder.quantity"
                      :readonly="
                        !(
                          subOrder.orderMain == 'draft' ||
                          subOrder.orderMain == 'quote'
                        )
                      "
                      :reload.sync="reload"
                      :rounded="
                        !(
                          measure !== undefined &&
                          measure !== null &&
                          measure.rounded
                        )
                          ? false
                          : subOrder.rounded
                      "
                      :subOrder="subOrder"
                      :type_customer="subOrder.level"
                      ref="detailsForm"
                      @done="getProductDetails()"
                      v-model="detailPrices"
                    />
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-container>
          </v-card-text>
          <v-card-actions class="dialog-product--fixed-actions">
            <v-row v-if="product !== null && step === 2" class="my-0">
              <!-- Detalles de prod / Extra-descuento -->
              <v-col class="dialog-product--fixed-left" cols="12" md="8">
                <div class="dialog-product--fixed-details p-3">
                  <!-- Detalles de producción asociados a la venta -->
                  <p
                    v-if="detailPrices.length > 0"
                    class="mb-2 font-weight-bold"
                  >
                    {{ $t('details_production') }}
                  </p>
                  <p
                    v-for="(item, key) in detailPrices.filter(
                      (element) => element.hidden === false
                    )"
                    :key="`pricesExtra-${key}`"
                    class="details-selected-sumatory white--text mb-0"
                  >
                    <span>
                      &bull; {{ item.name }}
                      <template v-if="item.hidden">(hidden)</template>
                    </span>
                    <span class="dotted-span"></span>
                    <span>
                      <template v-if="item.is_multipliable">
                        ({{ item.price + 'x' + subOrder.quantity }}):
                      </template>
                      <i-money
                        mode="text"
                        :value="
                          itemMultiply(
                            item.is_multipliable,
                            item.price,
                            subOrder.quantity
                          )
                        "
                      />
                    </span>
                  </p>
                  <p
                    v-if="hiddenDetailsSum()"
                    class="details-selected-sumatory white--text mb-0"
                    key="pricesExtraHidden"
                  >
                    <span> &bull; {{ $t('hidden') }}</span>
                    <span class="dotted-span"></span>
                    <span>
                      <i-money mode="text" :value="hiddenDetailsSum()" />
                    </span>
                  </p>

                  <!-- Extra/Descuento -->
                  <v-col class="d-none py-2" col="12" md="4" lg="3">
                    <changeprice
                      v-if="subOrder.pk"
                      :readonly="
                        !(
                          subOrder.orderMain == 'draft' ||
                          subOrder.orderMain == 'quote'
                        )
                      "
                      :price="subOrder.price"
                      :order="subOrder.pk"
                      @changeTotal="rewriteprice = $event"
                      @input="input = $event"
                      v-model="discount"
                    />
                  </v-col>
                </div>
              </v-col>

              <!-- Sumatorias -->
              <v-col class="dialog-product--fixed-right" cols="12" md="4">
                <div class="dialog-product--fixed-total">
                  <!-- Precio del producto -->
                  <p class="text-sumatory mb-0">
                    <span>Product price</span>
                    <span class="dotted-span"></span>
                    <i-money
                      class="main--text pl-1"
                      mode="text"
                      :value="subOrder.price * subOrder.quantity"
                    />
                  </p>

                  <!-- Detalles de producción -->
                  <p v-if="detailPrices.length > 0" class="text-sumatory mb-0">
                    <span>Detalles de producción</span>
                    <span class="dotted-span"></span>
                    <i-money
                      class="main--text pl-1"
                      mode="text"
                      :value="detailsSum(detailPrices)"
                    />
                  </p>

                  <!-- Subtotal -->
                  <p class="text-sumatory mb-0">
                    <span>{{ $t('subtotal') }}</span>
                    <span class="dotted-span"></span>
                    <i-money
                      class="main--text pl-1"
                      mode="text"
                      :value="subtotal()"
                    />
                  </p>

                  <!-- Impuestos -->
                  <p class="text-sumatory mb-0">
                    <span>{{ $tc('tax', 1) }}</span>
                    <span class="dotted-span"></span>
                    <i-money
                      class="main--text"
                      mode="text"
                      :value="subOrder.tax"
                    />
                  </p>

                  <!-- Descuento -->
                  <p v-if="discount < 0" class="text-sumatory mb-0">
                    <span>{{ $t('title_discount') }}</span>
                    <span class="dotted-span"></span>
                    <i-money
                      class="main--text pl-1"
                      mode="text"
                      :value="discount"
                    />
                  </p>

                  <!-- Total -->
                  <p class="text-sumatory mb-0">
                    <span>{{ $t('total') }}</span>
                    <span class="dotted-span"></span>
                    <i-money
                      mode="text"
                      :value="total"
                      class="main--text pl-1"
                    />
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
import showProductPrice from '../showProductPrice/list.vue'
import repository from './repository.vue'
import typedate from './date.vue'
import detailsByOrder from './detailsByOrder.vue'
import changeprice from './changePrice.vue'
import getBodyEmail from '../email/getBodyEmail.vue'
import orderMessages from '../chat/order-messages.vue'
import { mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
    typedate,
    repository,
    detailsByOrder,
    changeprice,
    showProductPrice,
    getBodyEmail,
    orderMessages
  },
  mounted() {
    this.getTax()
    window.addEventListener(
      'keydown',
      function (e) {
        if (this.dialog && e.keyCode === 113) {
          e.preventDefault()
          this.showCost = !this.showCost
        }
      }.bind(this)
    )
  },
  data() {
    return {
      divDescription: false,
      divAditionalSide: false,
      divRepository: false,
      divNotes: false,
      divLogs: false,
      divDates: false,
      reload: false,
      load: false,
      loadingIMG: false,
      imageUrl: null,
      imageUrl0: null,
      oldStatus: null,
      oldStatusName: null,
      panel: [],
      rewriteprice: 0,
      input: 0,
      discount: 0,
      detailPrices: [],
      creatingDetail: 1,
      projectName: this.orderName,
      taxes: [],
      sale: true,
      hasChatMessages: false,
      subOrder: {
        pk: '',
        name: '',
        order: '',
        status: null,
        product: null,
        dimensions: {},
        price: 0,
        description: '',
        additional_side: '',
        quantity: 1,
        rounded: false,
        subtotal: 0,
        total: 0,
        tax: 0,
        level: 0,
        quoteSelect: '',
        own_prod: '',
        orderStatus: '',
        orderMain: '',
        measure: null,
        template: null,
        customerName: '',
        clientEmail: '',
        clientNotification: '',
        thumbnail: null,
        prodPrice: 0,
        price_type: '',
        chat_id: 0
      },
      product: null,
      priceSuggest: null,
      showCost: false,
      dialog: false,
      step: 1,
      loading: false,
      logs: [],
      menu: false,
      menu1: false,
      menu2: false,
      measures: [],
      measure: null,
      stages: [],
      stage: null,
      details: ['preproduction', 'production', 'postproduction', 'extra'],
      matrix: [],
      clone: false,
      customToolbar: [
        [{ header: [] }],
        [
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'code',
          'code-block'
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }]
      ],
      answerList: {
        0: 'suborder_action.created',
        1: 'suborder_action.updated',
        2: 'suborder_action.deleted',
        3: 'suborder_action.access',
        4: 'action'
      },
      process: {
        selected: [],
        details: [],
        subdetails: [],
        subdetail: ''
      },
      saleDetail: {
        price: 0,
        priceString: '',
        dimensions: {},
        multi_choice: false,
        detail: '',
        formula: '',
        subdetail: '',
        subOrder: '',
        select: false
      }
    }
  },
  props: {
    order: {
      type: [Number, String]
    },
    statusOrder: {
      type: String
    },
    customer: {
      type: [Object, String]
    },
    customerTax: {
      default: false,
      type: Boolean
    },
    orderName: {
      type: [String]
    },
    orderImage: {
      type: [File, String]
    }
  },
  methods: {
    /**
     * activate
     * Método que muestra u oculta divs dependiendo del botón que se presione
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    activate(d) {
      this.divDescription = false
      this.divAditionalSide = false
      this.divRepository = false
      this.divNotes = false
      this.divLogs = false
      this.divDates = false

      // Descripción
      if (d == 1) {
        this.divDescription = true
        return
      }
      // Información adicional
      if (d == 2) {
        this.divAditionalSide = true
        return
      }

      // Repositorio de imágenes
      if (d == 3 && this.subOrderBtn.imageRepository) {
        this.divRepository = true
        return
      }

      // Notas / Chat
      if (d == 4) {
        this.divNotes = true
        this.$nextTick(() => {
          // Espera a que `divNotes` se haga visible.
          this.scrollToBottom()
        })
        return
      }

      // Logs
      if (d == 5) {
        this.divLogs = true
        this.getLogs()
        return
      }

      // Fechas
      if (d == 6 && this.subOrderBtn.typedate) {
        this.divDates = true
        return
      }
    },

    /**
     * scrollToBottom
     *
     * Al hacer click en el botón que muestra el chat se hace scroll hasta el
     * final del modal dialog y también del contenedor del chat.
     *
     * Ing. Argenis Osorio (argenisosorio10 at gmail)
     */
    scrollToBottom() {
      const target = this.$refs.scrollTarget // Referencia al contenedor desplazable
      const orderMessagesComponent = this.$refs.orderMessagesComponent // Contenedor del chat

      if (target) {
        target.scrollIntoView({
          top: target.scrollHeight, // Desplazar hasta el final
          behavior: 'smooth' // Desplazamiento suave
        })
      }

      // Esperar hasta que el DOM se haya actualizado.
      this.$nextTick(() => {
        // Ejecutar la lógica la primera vez
        setTimeout(() => {
          if (orderMessagesComponent) {
            // Referencia del componente hijo
            const chatContainer = orderMessagesComponent.$refs.chatContainer
            if (chatContainer) {
              chatContainer.scrollTop = chatContainer.scrollHeight
            }
          }
        }, 500)

        setTimeout(() => {
          if (orderMessagesComponent) {
            // Referencia del componente hijo
            const chatContainer = orderMessagesComponent.$refs.chatContainer
            if (chatContainer) {
              chatContainer.scrollTop = chatContainer.scrollHeight
            }
          }
        }, 1000) // Ejecutar luego de 1 segundo

        setTimeout(() => {
          if (orderMessagesComponent) {
            // Referencia del componente hijo
            const chatContainer = orderMessagesComponent.$refs.chatContainer
            if (chatContainer) {
              chatContainer.scrollTop = chatContainer.scrollHeight
            }
          }
        }, 1800) // Ejecutar luego de 1,8 segundo

        setTimeout(() => {
          if (orderMessagesComponent) {
            // Referencia del componente hijo
            const chatContainer = orderMessagesComponent.$refs.chatContainer
            if (chatContainer) {
              chatContainer.scrollTop = chatContainer.scrollHeight
            }
          }
        }, 2500) // Ejecutar luego de 2,5 segundos
      })
    },

    cloneNameProject() {
      this.subOrder.name = this.projectName
    },

    /**
     * getLogs
     * Método que consulta los logs del API y los convierte en información
     * útil para el usuario
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async getLogs() {
      if (this.subOrder.pk) {
        try {
          let responseDecoded = []
          let response = await this.$api.suborder.logs.show({
            pk: this.subOrder.pk
          })

          if (response.data && response.data.length > 0) {
            response.data.forEach((value) => {
              let name = `${value.actor.first_name} ${value.actor.last_name}`
              let formatedDate = new Date(value.timestamp)
              responseDecoded.push({
                action: value.action,
                date: formatedDate.toDateString(),
                time: formatedDate.toTimeString(),
                changes: JSON.parse(value.changes),
                fullname: name
              })
            })
          }

          this.logs = responseDecoded
        } catch (err) {
          console.error(err)
        }
      }
    },
    /**
     * cloneImage
     * Método para clonar la imagen de la orden en la subOrden
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async cloneImage() {
      this.clone = true
      let response = await this.$api.sale.show({ pk: this.order })
      this.imageUrl0 = response.data.thumbnail
      this.subOrder.thumbnail = this.orderImage
      this.imageUrl = this.subOrder.thumbnail
    },
    /**
     * getNameStatus
     * Método para consultar los nombres de los subestados
     * con su correspondiente estado, llevando el formato
     * Nombre estado - nombre subestado
     * Aplicar traducciones
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getNameStatus: (item) =>
      item.dad === undefined || item.dad === null
        ? item.name
        : `${item.dad.name} - ${item.name}`,
    /**
     * getOrderStatus
     * Método para consultar los subEstados de subOrden, según su estado
     * en la plantilla de proceso de producción asociada al producto
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getOrderStatus() {
      let response = await this.$api.sale.status.list({
        opt: {
          params: {
            template: this.subOrder.template
          }
        }
      })
      let sta = []
      for (var value in response.data) {
        if (response.data[value].substatusVals.length > 0) {
          sta = sta.concat(response.data[value].substatusVals)
        } else {
          sta.push(response.data[value])
        }
      }
      let root_filter = sta.filter(
        (element) => element.pk === this.subOrder.status
      )
      if (root_filter.length > 0) {
        const {
          dad: { name },
          root
        } = root_filter[0]
        if (name !== 'preprocess') {
          this.stages = sta.filter((element) => element.root === root)
        }
      }
    },
    /**
     * getProductDetails
     * Método para consultar detalles de producción de acuerdo a la etapa en la
     * que se encuentre, pre-producción, producción, post-producción o extra
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getProductDetails() {
      for (let type of this.details) {
        let response = await this.$api.suborder.production.show({
          pk: this.subOrder.pk,
          opt: {
            params: {
              subOrder: true
            }
          }
        })
        this.details[type] = response.data.filter(
          (event) => event.typeProcess == type
        )
      }
    },
    /**
     * getProduct
     * Método para consultar el producto de la subOrden
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getProduct() {
      const response = await this.$api.product.show({
        pk: this.subOrder.product
      })
      this.product = response.data
    },
    /**
     * getProductSold
     * Método para consultar las unidades de medida si el tipo de precio
     * del producto es fórmula
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     */
    async getProductSold() {
      const response = await this.$api.product.measure.show({
        pk: this.subOrder.product,
        opt: {
          params: {
            product: true
          }
        }
      })
      this.measures = response.data
      if (this.subOrder.measure === null && this.measures.length > 0) {
        let defaultMel = this.measures.find((m) => m.default === true)
        if (defaultMel === undefined) {
          defaultMel = this.measures[0]
        }
        this.subOrder.measure = defaultMel.pk
        this.measure = defaultMel
      } else if (this.subOrder.measure !== null && this.measures.length > 0) {
        let defaultMel = this.measures.find(
          (m) => m.pk === this.subOrder.measure
        )
        this.measure = defaultMel !== undefined ? defaultMel : null
      }
    },
    /**
     * getTax
     * Método para consultar la tasa aplicada a la compañia
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     */
    async getTax() {
      let response = await this.$api.tax.list({
        opt: {
          params: {
            company: this.company != undefined ? this.company.pk : null
          }
        }
      })
      this.taxes = response.data
    },
    async open(item) {
      this.priceSuggest = item.priceSuggest
      this.step = 1
      this.showCost = false
      this.menu = false
      this.menu1 = false
      this.menu2 = false
      this.creatingDetail = 1
      this.projectName = this.orderName
      this.dialog = true
      let prod = {}
      if (typeof item !== 'object') {
        try {
          this.loading = true
          let response = await this.$api.sale.product.show({
            pk: item
          })
          prod = response.data
          if (prod.prodPrice === 0) {
            let res = await this.$api.product.show({
              pk: prod.product
            })
            prod.prodPrice = res.data.priceSuggest
          }
          this.$route.meta.title = this.$t('edit', { model: '' })
        } catch (err) {
          this.dialog = false
        } finally {
          this.loading = false
        }
      }
      if (
        this.projectName === undefined &&
        prod.projectName !== '' &&
        prod.projectName !== undefined
      ) {
        this.projectName = prod.projectName
      }
      if (prod.dimensions === '') {
        prod.dimensions = {}
      }
      this.subOrder =
        typeof item === 'object'
          ? {
              pk: '',
              name: '',
              code: '',
              order: this.order,
              status: null,
              product: item.pk,
              measure: null,
              price: 0,
              priceString: '',
              dimensions: {},
              quantity: 1,
              subtotal: 0,
              description: '',
              additional_side: '',
              quoteSelect: '',
              rounded: false,
              thumbnail: null,
              tax: 0,
              total: 0,
              own_prod: `${item.internalCode} - ${item.name}`,
              template: item.template_id,
              orderStatus: '',
              orderMain: this.statusOrder,
              costprod: item.cost,
              customerName: this.customer,
              customerEmail: this.clientEmail,
              customerTax: this.customerTax,
              productTax: item.tax,
              prodPrice: item.price == 0 ? item.priceSuggest : item.price,
              price_type: item.price_type,
              level: 0,
              chat_id: item.chat_id
            }
          : prod
      if (this.subOrder.chat_id != 0 && this.subOrder.chat_id != undefined) {
        this.$api.chat.product
          .unreadMessages({ pk: this.subOrder.chat_id })
          .then((response) => {
            if (response.data.messages_count > 0) {
              this.hasChatMessages = true
            } else {
              this.hasChatMessages = false
            }
          })
      }
      this.oldStatus = this.subOrder.status
      this.oldStatusName = this.subOrder.orderStatus
      this.getProduct()
      if (this.subOrder.orderMain == 'in_process' && this.subOrder.template)
        this.getOrderStatus()
      this.getProductSold()
      if (this.subOrder.pk != '') {
        this.getProductDetails()
        this.getItems()
        this.getSavedItems()
      }
      try {
        this.imageUrl = this.subOrder.thumbnail
        this.loadingIMG = false
      } catch (error) {
        this.imageUrl = require('@/assets/default_image.png')
        this.loadingIMG = false
      }
    },
    back() {
      this.step -= 1
    },
    next() {
      this.$refs.form.validate().then((success) => {
        if (success || this.subOrder.name !== '') {
          this.step += 1
        }
      })
    },
    /**
     * showNext
     * Método para mostrar flecha siguiente
     *
     * Paul Rojas
     */
    showNext() {
      if (this.subOrder.pk && this.step < 2) {
        return true
      }
      return false
    },
    /**
     * save
     * Método para guardar o editar una subOrden
     *
     * Paul Rojas
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async save(form_data) {
      const response =
        this.subOrder.pk === '' || this.subOrder.pk === undefined
          ? await this.$api.sale.product.create({
              form: form_data
            })
          : await this.$api.sale.product.edit({
              pk: this.subOrder.pk,
              form: form_data
            })
      if (this.subOrder.pk == '') {
        this.subOrder = response.data
        this.$toast.success(
          `${this.$tc('suborder', 1)} ${this.$tc('created', 2)}`
        )
      } else if (this.subOrder.pk != '') {
        //this.$store.dispatch('utils/createdsubOrder', true)
        this.$emit('prod', response.data)
        this.close()
        this.$toast.success(
          `${this.$tc('suborder', 1)} ${this.$tc(
            this.subOrder.pk != undefined ? 'edited' : 'created',
            2
          )}`
        )
      }
    },
    /**
     * assignedUsers
     * Método para consultar si la subOrden tiene diseñadores o productores asignados
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    assignedUsers() {
      let in_wait = this.stages.find((m) => m.name === 'in_wait')
      if (
        in_wait != undefined &&
        this.subOrder.leader_status != undefined &&
        (this.subOrder.status != 'in_wait' ||
          this.subOrder.status != 'rejected')
      ) {
        if (
          this.subOrder.leader_status.designer == undefined &&
          in_wait.dad.name == 'design'
        ) {
          this.subOrder.status = in_wait.pk
          this.$toast.error(`${this.$tc('assignDesigners', 1)}`)
        } else {
          if (
            this.subOrder.leader_status.production == undefined &&
            in_wait.dad.name == 'production'
          ) {
            this.subOrder.status = in_wait.pk
            this.$toast.error(`${this.$tc('assignProductions', 1)}`)
          }
        }
      }
    },
    /**
     * submit
     * Método para recolectar la información a guardar de una subOrden,
     * una vez verificado que tenga usuarios asignados y se realice la
     * notificación en caso de cambio de estado
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submit() {
      this.assignedUsers()
      this.$refs.form.validate().then((success) => {
        if (success || this.step == 2 || this.subOrder.status != '') {
          this.$refs['getbodyemail'].getBodyEmail()
          let form_data = new FormData()
          for (var key in this.subOrder) {
            if (this.subOrder[key] == null) {
              continue
            }
            form_data.append(
              key,
              key !== 'dimensions'
                ? this.subOrder[key]
                : JSON.stringify(this.subOrder[key])
            )
            if (
              key == 'thumbnail' &&
              (this.subOrder.thumbnail == null ||
                String(this.imageUrl).indexOf('http') != -1 ||
                String(this.subOrder.thumbnail).indexOf(';base64') != -1 ||
                String(this.subOrder.thumbnail).indexOf('http') != -1 ||
                String(this.imageUrl).indexOf('/sources/') != -1)
            ) {
              form_data.delete('thumbnail')
            }
          }
          try {
            this.load = true
            this.save(form_data)
          } finally {
            this.load = false
          }
        } else {
          console.log(success)
        }
      })
    },
    close() {
      if (this.$refs.imgAppr !== undefined) {
        this.$refs.imgAppr.clearForm()
      }
      this.dialog = false
      document.oncontextmenu = function () {
        return true
      }
    },
    /**
     * getItems
     * Método para consultar los procesos de producción de un producto
     * y sus subprocesos (también llamados items y subitems respectivamente).
     *
     * @var process.details: Items del template del producto (es la copia del
     *                       template que se puede haber modificado - sin hijos)
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async getItems() {
      let subOrderProduct = this.subOrder.product
      try {
        let response = await this.$api.product.details.list({
          opt: {
            params: {
              product: subOrderProduct
            }
          }
        })
        this.process.details = response.data
        this.process.details.filter((item) => {
          if (item.subitems == true) {
            this.process.subdetails = [] // evita el overflow del copiado
            this.getSubItems(item.pk)
          }
        })
      } catch (error) {
        console.error('Error en la consulta de product details')
        console.error(error)
      }
    },
    /**
     * getSubItems
     * Método para consultar los subprocesos de producción (subitems) del
     * proceso de producción (item) al que pertenecen.
     *
     * @param itemPK: id del Item
     * @var process.subdetails: Subitems de los items del template del producto
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async getSubItems(itemPK) {
      let subitems = []
      try {
        let response = await this.$api.product.subdetails.list({
          opt: {
            params: {
              detail: itemPK
            }
          }
        })
        subitems = response.data
        this.process.subdetails = [...this.process.subdetails, ...subitems]
      } catch (error) {
        console.error('Error en la consulta de subdetails')
        console.error(error)
      }
    },
    /**
     * getSavedItems
     * Método para consultar los procesos de producción (items) asociados a una
     * suborden de compra.
     *
     * @var process.selected: Items y subitems guardados en la suborden
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async getSavedItems() {
      let subOrderPK = this.subOrder.pk
      try {
        let response = await this.$api.suborder.production.show({
          pk: subOrderPK,
          opt: {
            params: {
              subOrder: true
            }
          }
        })
        this.process.selected = response.data
      } catch (error) {
        console.error('Error en la consulta de los items guardados')
        console.error(error)
      }
    },
    /**
     * filterSubItems
     * Método para filtrar los subitems de procesos de producción por pk
     * del item al que pertenecen
     *
     * @param itemPK: id del Item
     * @return filter: Array de subitems que le pertenecen al itemPK
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    filterSubItems(itemPK) {
      let filter = []
      filter = this.process.subdetails.filter((item) => item.item == itemPK)
      return filter
    },
    /**
     * getSingleSubitem
     * Método para buscar un subitem de procesos de producción específico
     *
     * @param itemPK: id del Item
     * @return arrayDeCositas: Array de subitems que le pertenecen al itemPK
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    getSingleSubitem(itemPK, subItemPK) {
      let getSubItem = []
      getSubItem = this.process.subdetails.filter(
        (item) => item.item == itemPK && item.pk == subItemPK
      )
      return getSubItem
    },
    /**
     * activeDetail
     * Método para activar (guardar) el (item o subitem) en la orden de venta
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async activeDetail(item, itemPK = '') {
      if (itemPK) {
        let selectedSubitem = (await this.getSingleSubitem(itemPK, item))[0]
        this.saleDetail.subdetail = selectedSubitem.pk
        this.saleDetail.multi_choice = selectedSubitem.multi_choice
        this.saleDetail.detail = selectedSubitem.item
        this.saleDetail.formula = selectedSubitem.formula
      } else {
        this.saleDetail.subdetail = null
        this.saleDetail.multi_choice = item.multi_choice
        this.saleDetail.detail = item.pk
        this.saleDetail.formula = item.formula
      }

      this.saleDetail.price = 0
      this.saleDetail.dimensions = {}
      this.saleDetail.subOrder = this.subOrder.pk

      try {
        await this.$api.suborder.production.create({
          form: this.saleDetail,
          opt: {
            params: {
              detail: this.saleDetail.detail,
              level: this.subOrder.level
            }
          }
        })
        this.reload = true
        this.$toast.success(
          `${this.$tc(this.title, 1)} ${this.$tc(
            this.saleDetail.pk !== undefined ? 'edited' : 'created',
            2
          )}`
        )
        this.getSavedItems()
      } catch (error) {
        console.error('Error en activeDetail')
        console.error(error)
      }
    },
    /**
     * disableItem
     * Método para desactivar los checkbox de los detalles de producción en la
     * orden de venta
     *
     * @param {number} itemPK
     * @param {boolean} itemMulti
     *
     * @return {boolean}
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    disableItem(itemPK, itemMulti = false) {
      if (itemPK && this.process.selected.length && !itemMulti) {
        return (
          this.process.selected.filter((event) => event.detail == itemPK)
            .length > 0
        )
      }
      return false
    },
    /**
     * itemMultiply
     * Método para calcular el precio del item/subitem por la cantidad del
     * producto
     *
     * @param {number} itemPK
     * @param {boolean} itemMulti
     *
     * @return {boolean}
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    itemMultiply(is_multipliable, price, quantity) {
      if (is_multipliable) {
        return price * quantity
      }
      return price
    },
    /**
     * hiddenDetailsSum
     * Método para calcular la cantidad de subitems
     *
     *
     * @return {number}
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    hiddenDetailsSum() {
      let hiddenItems = this.detailPrices.filter(
        (element) => element.hidden === true
      )

      if (hiddenItems.length) {
        let sum = hiddenItems.reduce((accumulator, item) => {
          return (
            accumulator +
            Number(
              this.itemMultiply(
                item.is_multipliable,
                item.price,
                this.subOrder.quantity
              )
            )
          )
        }, 0)
        return sum
      }

      return 0
    },
    /**
     * detailsSum
     * Método para calcular la sumatoria de los subtotales de items/subitems
     *
     * @param {array} priceArray
     *
     * @return {number}
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    detailsSum(priceArray) {
      let sum = priceArray.reduce((accumulator, item) => {
        return (
          accumulator +
          Number(
            this.itemMultiply(
              item.is_multipliable,
              item.price,
              this.subOrder.quantity
            )
          )
        )
      }, 0)
      return sum
    },
    /**
     * subtotal
     * Método para calcular el subtotal de la orden
     *
     * @param {number} itemPK
     * @param {boolean} itemMulti
     *
     * @return {boolean}
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    subtotal() {
      let var1 = Number(this.discount)
      let var2 = Number(this.rewriteprice)
      let var3 = Number(this.subOrder.price)
      let var4 = this.detailsSum(this.detailPrices)
      this.subOrder.subtotal = (var1 + var2 + var3 + var4).toFixed(2)
      return this.subOrder.subtotal
    }
  },
  watch: {
    'subOrder.measure': {
      handler() {
        if (this.subOrder.measure !== null) {
          this.measure = this.measures.find(
            (m) => m.pk === this.subOrder.measure
          )
          if (
            typeof this.measure === 'object' &&
            this.measure.rounded === false
          ) {
            this.subOrder.rounded = false
          }
        }
      }
    },
    'subOrder.status': {
      handler() {
        if (this.subOrder.status !== null && this.subOrder.template) {
          this.stage = this.stages.find((m) => m.pk === this.subOrder.status)
          this.subOrder.orderStatus =
            this.stage === undefined ? '' : this.stage.name
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData',
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      me: 'session/me',
      createdsubOrder: 'utils/createdsubOrder'
    }),
    changePrice() {
      return {
        add: this.getPermissions([`sales.add_orderchangeprice`]) || this.isAdmin
      }
    },
    subOrderBtn() {
      return {
        changeprice:
          this.getPermissions([`sales.view_orderchangeprice`]) || this.isAdmin,
        customer:
          this.getPermissions([`customer.view_customer`]) || this.isAdmin,
        designerorder:
          this.getPermissions([`sales.view_assigneduser`]) || this.isAdmin,
        details:
          this.getPermissions([`product.view_productiondetails`]) ||
          this.isAdmin,
        imageapproval:
          this.getPermissions([`sales.view_imageapproval`]) || this.isAdmin,
        imageRepository:
          this.getPermissions([`sales.view_imagerepository`]) || this.isAdmin,
        orderproduct:
          this.getPermissions([`sales.add_orderproduct`]) || this.isAdmin,
        soldby:
          this.getPermissions([`base_config.view_soldby`]) || this.isAdmin,
        soldbyEdit:
          this.getPermissions([`base_config.change_soldby`]) || this.isAdmin,
        typedate: this.getPermissions([`sales.view_typedate`]) || this.isAdmin,
        process: this.getPermissions([`product.view_process`]) || this.isAdmin
      }
    },
    defaultmeasure() {
      let defaultMel = this.measures.find((m) => m.default === true)
      if (defaultMel === undefined) {
        defaultMel = this.measures[0]
      }
      defaultMel =
        defaultMel === undefined
          ? ''
          : defaultMel.name.split('(')[1].replace(')', '')
      return defaultMel
    },
    showprice() {
      let price = 0
      price += Number(this.subOrder.price)
      price += Number(this.rewriteprice)
      price *= Number(this.subOrder.quantity)
      return price.toFixed(2)
    },
    total() {
      return (
        Number(this.subOrder.subtotal) + Number(this.subOrder.tax)
      ).toFixed(2)
    }
  }
}
</script>
<style lang="sass">
.dialog-product
  &--modal
    background-color: var(--v-auxbg-base)
    &.v-dialog:not(.v-dialog--fullscreen)
      // Modal contenedora de la suborden
      align-self: stretch
  &--modal-second-step
    // Paso 2 de la modal de la suborden
    overflow: auto
    max-height: calc(100dvh - 540px)
    @media (width >= 960px)
      max-height: calc(100dvh - 378px)
  &--fixed-actions
    // Contenedor de la sección fija de cálculo de precios
    bottom: 0
    position: fixed
    right: 0
    width: 100%
  &--fixed-details
    // Sección fija (izquierda) de cálculo de precios
    background-color: var(--v-primary-base)
    color: var(--white)
    margin: 0
    height: 157px
    overflow-y: auto
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .3), 0 4px 5px 0 rgba(0, 0, 0, .3), 0 1px 10px 0 rgba(0, 0, 0, .3)
    @media (width >= 960px)
      border-radius: 20px 0px 0px 20px
  &--fixed-total
    // Sección fija (derecha) de cálculo de precios
    background-color: var(--v-auxbg-base)
    border-radius: 20px
    border: 1px solid var(--v-primary-base)
    padding: 10px 20px
    height: 157px
    overflow-y: auto
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .3), 0 4px 5px 0 rgba(0, 0, 0, .3), 0 1px 10px 0 rgba(0, 0, 0, .3)
    @media (width >= 960px)
      border-radius: 0px 20px 20px 0px
  &--fixed-left
    padding-top: 0
    @media (width >= 960px)
      padding-right: 0
  &--fixed-right
    @media (width >= 960px)
      padding-top: 0
      padding-left: 0
.container-buttons
  display: flex
  flex-wrap: wrap
  flex-direction: row
  gap: 15px
  margin-bottom: 15px

.text-sumatory
  font-size: 18px
  display: flex
  justify-content: space-between
  .dotted-span
    flex: 1 1 auto
    border-bottom: 1px dotted var(--v-auxtext-base)
    margin: 0 5px 7px 5px
  .main--text
    font-weight: bold

.details-selected-sumatory
  display: flex
  justify-content: space-between
  .dotted-span
    flex: 1 1 auto
    border-bottom: 1px dotted #fff
    margin: 0 5px 7px 5px
  .main--text
    font-weight: bold

.descriptiveMoney
  font-weight: bold
  font-size: larger
  @media (width >= 960px)
    font-size: medium

.v-stepper__content
  padding: 1em !important

.v-stepper__wrapper
  padding: 2em !important

.clone-image-button
  position: absolute
  top: 0
  right: 0
  border: 2px solid var(--secundary)
  top: calc(50% + 32px)
  margin-right: 8px
  border-radius: 50%
  & > button
    width: 40px
    height: 40px

.position-relative #image.image-repo > button
  top: 30%
  &:nth-child(1)
    top: calc(30% - 5px)
</style>
