import Vue from 'vue'
import Router from 'vue-router'
import routes from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'
import store from '@/store'

Vue.use(Router)

const RouterLayout = createRouterLayout((layout) => {
  return import('@/layouts/' + layout + '.vue')
})

const rout = new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      component: RouterLayout,
      children: routes
    },
    {
      path: '*',
      redirect: { name: 'error-404' }
    }
  ]
})

rout.beforeEach(async (to, from, next) => {
  /*if (
    localStorage.getItem('subdomain') === null ||
    localStorage.getItem('subdomain') === undefined
  ) {
    if (!store.getters['utils/getIsloading']) {
      store.dispatch('utils/actionLoading')
    }
    //await store.dispatch('subdomain/consultSubdomain', location.hostname)
    if (store.getters['utils/getIsloading']) {
      store.dispatch('utils/actionLoading')
    }
  }*/

  if (
    store.getters['session/isAuth'] &&
    !['auth-login', 'auth-forgot', 'submenu-menu', 'error-404'].includes(
      to.name
    )
  ) {
    store.dispatch('utils/actionLoading')
  } else if (store.getters['utils/getIsloading']) {
    store.dispatch('utils/actionLoading')
  }

  //if (store.getters['subdomain/getSubdomainFound']) {
  if (
    !store.getters['session/isAuth'] &&
    !['auth-login', 'auth-forgot'].includes(to.name)
  ) {
    next({ name: 'auth-login' })
  }
  if (store.getters['session/isAuth'] && to.name === 'auth-login') {
    next({ name: 'index' })
  }
  if (
    ![
      'auth-login',
      'auth-forgot',
      'index',
      'settings',
      'overview',
      'submenu-menu',
      'error-404'
    ].includes(to.name) &&
    !store.getters['session/isAdmin'] &&
    (to.meta['permission'] === undefined ||
      !store.getters['session/getPermissions'](to.meta['permission']))
  ) {
    next('/error-404')
  }
  /*} else if (to.name !== 'error-404') {
    next({ name: 'error-404' })
  }*/
  next()
})

export default rout
