<route>
{
  "meta": {
    "permission": [
      "product.add_priceformula",
      "product.change_priceformula",
      "product.add_priceformulaattributes",
      "product.view_priceformulaattributes"
    ]
  }
}
</route>

<template>
  <i-container
    :opts="opts"
    :mode="container"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <!-- Formulario -->
    <v-card class="mb-4">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit()">
          <i-toolbar
            :dontSave="container !== 'container'"
            :title="
              container == 'modal'
                ? `${$tc('formula', 1)}`
                : formula.pk != undefined && formula.pk != ''
                ? `${$t('edit')} ${$tc('formula', 1)}`
                : `${$t('add', { value: $tc('formula', 1) })}`
            "
            :loading="load"
          >
          </i-toolbar>
          <v-card-text>
            <!-- Formulario -->
            <v-row class="my-0">
              <v-col cols="12" sm="6" md="3">
                <!-- Modal de crear fórmula -->
                <v-dialog v-model="dialogName" max-width="390" persistent>
                  <v-card>
                    <v-toolbar dark color="primary" class="mb-5">
                      <v-toolbar-items>
                        <v-btn
                          text
                          dark
                          @click="$router.back()"
                          :small="$vuetify.breakpoint.smAndDown"
                        >
                          <v-icon>fa-arrow-left</v-icon>
                        </v-btn>
                      </v-toolbar-items>
                      <v-spacer></v-spacer>
                      <v-toolbar-title class="font-weight-black">
                        {{ $t('add_item_value') }}
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn
                          text
                          dark
                          @click="addName"
                          :small="$vuetify.breakpoint.smAndDown"
                          :loading="isLoading"
                          v-show="btnSaveName"
                        >
                          <template v-slot:loader>
                            <v-progress-circular
                              color="white"
                              indeterminate
                              width="3"
                            >
                              <v-icon
                                size="20"
                                v-text="'$vuetify.icons.loading'"
                              ></v-icon>
                            </v-progress-circular>
                          </template>
                          <v-icon>fa-save</v-icon>
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text class="mt-5">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            :label="$t('name')"
                            outlined
                            v-model="formula.name"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>

                <!-- Input: Editar nombre -->
                <ValidationProvider
                  vid="name"
                  :name="$tc('name', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    color="secondary"
                    maxlength="64"
                    outlined
                    :error-messages="errors[0]"
                    :label="$tc('name', 1)"
                    v-model="formula.name"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Atributos -->
              <v-col v-if="attributesBtn.view" cols="12" sm="6" md="9">
                <dialattributes
                  v-model="dialog"
                  :to-edit="toEdit"
                  @done="addAttribute"
                />
                <ValidationProvider
                  vid="attributes"
                  :name="$tc('attributes', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :append-icon="uoms.length > 10 ? 'fa-search' : ''"
                    autocomplete="off"
                    chips
                    class="secondary--text attibutes"
                    deletable-chips
                    key="attributes-input"
                    multiple
                    outlined
                    :error-messages="errors[0]"
                    :items="uoms"
                    item-text="name"
                    item-value="pk"
                    :label="$t('attributes')"
                    :loading="isLoading"
                    :menu-props="{ closeOnContentClick: true }"
                    :clearable="uoms.length > 10"
                    :prepend-inner-icon="attributesBtn.add ? 'fa-plus' : ''"
                    :search-input.sync="searchAttributes"
                    @click:prepend-inner="createAttr()"
                    v-model="formula.attributes"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="data">
                      <v-chip
                        class="white--text"
                        close
                        color="secondary"
                        small
                        :input-value="data.selected"
                        @click:close="removeAttr(data.item)"
                        @click="data.select"
                        v-bind="data.attrs"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>

            <!-- Modelo -->
            <v-row>
              <v-col cols="12" md="6" class="mb-5">
                <div v-if="!isLoading" class="w-100 mb-4 border rounded-lg">
                  <v-switch
                    class="v-switch mt-0 pt-0 px-3"
                    color="primary"
                    name="tax"
                    hide-details
                    :label="$tc('formula_model')"
                    v-model="formula.model"
                  ></v-switch>
                </div>
              </v-col>
            </v-row>

            <!-- Laboratorio de fórmulas -->
            <v-row class="my-0">
              <v-col cols="12" lg="8">
                <!-- Título -->
                <h3 class="headline font-weight-bold grey--text">
                  {{ `${$t('section', { value: $t('formula') })}` }}
                </h3>

                <!-- Sandbox de la fórmula -->
                <div class="formula-pk--formula-container">
                  <div class="formula-pk--formula-name">{{ formula.name }}</div>
                  <p
                    class="font-weight-bold text-center mb-0"
                    color="primary"
                    text-color="white"
                  >
                    =
                  </p>
                  <draggable
                    v-if="show"
                    class="formula-pk--chip-container mb-0"
                    :class="{
                      'error lighten-1': show_formula == 'formulaNoValid',
                      'warning lighten-1': show_formula == 'formulaDivisionZero'
                    }"
                    v-model="formula.formula"
                  >
                    <transition-group>
                      <v-chip
                        class="formula elementList formula-pk--chip-formula"
                        close
                        text-color="white"
                        color="secondary"
                        :key="'chipsFormula-' + attr_selected + index"
                        @click:close="removeChip(index)"
                        v-for="(attr_selected, index) in formula.formula"
                      >
                        <span class="font-weight-bold">
                          {{
                            attr_selected.indexOf('var_') != -1
                              ? show_attrs.find(
                                  (o) => attr_selected === `var_${o.pk}`
                                ) == undefined
                                ? ''
                                : show_attrs.find(
                                    (o) => attr_selected === `var_${o.pk}`
                                  ).name
                              : attr_selected
                          }}
                        </span>
                      </v-chip>
                    </transition-group>
                  </draggable>
                </div>

                <!-- Alerta en caso que la fórmula sea inválida -->
                <div class="formula-pk--formula-container">
                  <span></span>
                  <span></span>
                  <p
                    v-if="
                      show_formula == 'formulaNoValid' ||
                      show_formula == 'formulaDivisionZero'
                    "
                    class="font-weight-bold mt-4"
                    :class="`${
                      show_formula == 'formulaDivisionZero'
                        ? 'warning--text'
                        : 'error--text'
                    }`"
                  >
                    {{ $t(show_formula) }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" lg="4">
                <!-- Atributos -->
                <div class="mb-5">
                  <p class="font-weight-bold mb-1">
                    {{ $t('attributes') }}
                  </p>
                  <v-chip
                    v-for="attr_selected in show_attrs"
                    :key="'chips-' + attr_selected.pk"
                    class="mr-1"
                    color="secondary"
                    text-color="white"
                    @click="addAttr(attr_selected)"
                  >
                    {{ attr_selected.name }}
                  </v-chip>
                </div>

                <!-- Teclado tipo calculadora -->
                <div class="mb-5">
                  <p class="font-weight-bold mb-1">
                    {{ $t('operators') }}
                  </p>
                  <div
                    class="d-flex justify-content-start g-1 flex-wrap"
                    style="width: 160px"
                  >
                    <v-chip
                      v-for="operator in operators"
                      class="formula-pk--operator-chip"
                      :class="operator.value == 0 ? 'formula-pk--zero' : ''"
                      :color="operator.color"
                      :text-color="operator.text"
                      :key="'chips-' + operator.value"
                      @click="addAttr(operator.value)"
                    >
                      {{ operator.value }}
                    </v-chip>
                  </div>
                </div>

                <!-- Cantidad específica -->
                <div>
                  <p class="font-weight-bold mb-n2">
                    {{ $t('add_quantity') }}
                  </p>
                  <ValidationProvider
                    vid="number"
                    :name="$tc('number', 1)"
                    rules="double"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="mt-4"
                      type="number"
                      append-icon="mdi-send"
                      autocomplete="off"
                      color="secondary"
                      outlined
                      :error-messages="errors[0]"
                      :label="$tc('number', 1)"
                      @click:append="addAttr(number, 1)"
                      @keydown.enter.prevent="addAttr(number, 1)"
                      v-model="number"
                    />
                  </ValidationProvider>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>

    <!-- Sandbox de la fórmula -->
    <v-alert
      class="font-weight-black title"
      outlined
      :type="
        show_formula == 'formulaNoValid'
          ? 'error'
          : show_formula == 'formulaDivisionZero'
          ? 'warning'
          : 'info'
      "
      :value="true"
    >
      <p class="formula-pk--alert-title">{{ $t('test_formula') }}:</p>
      <i-formula
        ref="refformula"
        :formula="formula.pk"
        @change="total = $event"
        v-model="show_formula"
      />
      <v-row class="my-0">
        <v-col
          cols="12"
          md="5"
          order="1"
          order-md="2"
          class="pt-0 grey--text darker-2"
        >
          {{ show_formula_example }}
        </v-col>
        <v-col class="pt-0" cols="12" md="7" order="2" order-md="1">
          <p class="mb-0">
            {{ $t('formula') }}:
            {{
              show_formula == 'formulaNoValid' ||
              show_formula == 'formulaDivisionZero'
                ? $t(show_formula)
                : show_formula
            }}
          </p>
          <p>Total: {{ isNaN(total) ? 0 : total }}</p>
        </v-col>
      </v-row>
    </v-alert>
  </i-container>
</template>
<script>
import { mapGetters } from 'vuex'
import dialattributes from '../../components/attributes/modal.vue'
import draggable from 'vuedraggable'
export default {
  props: {
    container: {
      type: String,
      default: 'container'
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    dialattributes,
    draggable
  },
  data() {
    return {
      formula: {
        pk: '',
        name: '',
        attributes: [],
        formula: []
      },
      show_attrs: [],
      show_formula: '',
      show_formula_example: '',
      number: null,
      operators: [
        { value: '^', color: 'primary', text: 'white' },
        { value: '(', color: 'primary', text: 'white' },
        { value: ')', color: 'primary', text: 'white' },
        { value: '/', color: 'secondary', text: 'white' },
        { value: '7', color: 'muted', text: 'auxtext' },
        { value: '8', color: 'muted', text: 'auxtext' },
        { value: '9', color: 'muted', text: 'auxtext' },
        { value: '*', color: 'secondary', text: 'white' },
        { value: '4', color: 'muted', text: 'auxtext' },
        { value: '5', color: 'muted', text: 'auxtext' },
        { value: '6', color: 'muted', text: 'auxtext' },
        { value: '-', color: 'secondary', text: 'white' },
        { value: '1', color: 'muted', text: 'auxtext' },
        { value: '2', color: 'muted', text: 'auxtext' },
        { value: '3', color: 'muted', text: 'auxtext' },
        { value: '+', color: 'secondary', text: 'white' },
        { value: '0', color: 'muted', text: 'auxtext' },
        { value: '.', color: 'primary', text: 'white' }
      ],
      total: 0,
      searchAttributes: '',
      uoms: [],
      dialog: false,
      isLoading: false,
      isLoading2: false,
      loading: false,
      load: false,
      valid: false,
      show: true,
      toEdit: null,
      reload: false,
      dialogName: false
    }
  },
  computed: {
    opts() {
      switch (this.container) {
        case 'modal':
          return {
            title: this.$t(this.edit ? 'editing' : 'add', {
              value: ''
            }),
            value: this.value,
            load: this.loading,
            maxWidth: '80%'
          }
        default:
          return { fluid: true }
      }
    },
    ...mapGetters({
      i_formula_formula: 'formula/show_formula',
      i_formula_attrs: 'formula/show_attrs',
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    attributesBtn() {
      return {
        add:
          this.getPermissions([`product.add_priceformulaattributes`]) ||
          this.isAdmin,
        view:
          this.getPermissions([`product.view_priceformulaattributes`]) ||
          this.isAdmin
      }
    },
    btnSaveName() {
      if (this.formula.name != '') {
        return true
      }
      return false
    }
  },
  methods: {
    addName() {
      if (this.formula.name != '') {
        this.dialogName = false
      }
    },
    createAttr() {
      this.toEdit = null
      this.dialog = true
      this.reload = true
      this.getUom()
    },
    removeAttr(item) {
      const index = this.formula.attributes.findIndex((m) => m == item.pk)
      if (index >= 0) {
        this.formula.attributes.splice(index, 1)
        this.formula.formula = this.formula.formula.filter(
          (m) => m != `var_${item.pk}`
        )
      }
    },
    removeChip(index) {
      this.formula.formula.splice(index, 1)
    },
    addAttr(val, num) {
      if (typeof val === 'object') {
        this.formula.formula.push(`var_${val.pk}`)
      } else {
        if (num == undefined) {
          this.formula.formula.push(val)
        } else {
          this.number = ''
          this.formula.formula.push(`${Number(val)}`)
        }
      }
    },
    /**
     * addAttribute
     * Método para agregar un nuevo atributo a la lista, si se proporciona un
     * evento.
     *
     * @param {Object} evt - Evento que puede contener un nuevo atributo
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    addAttribute(evt) {
      this.formula.attributes.push(evt.pk)
      this.getUom()
    },
    async getUom() {
      let response = await this.$api.formula.attributes.list({})
      this.uoms = await this.i_formula_attrs(this.formula.attributes)
      this.uoms = Array.from(new Set(this.uoms.concat(response.data)))
      this.isLoading = false
    },
    async submit() {
      try {
        this.load = true
        const formulaContainer =
          this.formula.pk === '' || this.formula.pk === undefined
            ? await this.$api.formula.create({
                form: this.formula
              })
            : await this.$api.formula.edit({
                pk: this.formula.pk,
                form: this.formula
              })
        this.$emit('input', false)
        this.$toast.success(
          `${this.$tc('formula', 1)} ${this.$tc(
            this.formula.pk !== '' ? 'edited' : 'created',
            2
          )}`
        )
        if (this.container === 'container') {
          this.$router.push({ name: 'formula' })
        } else {
          this.$emit('updated', formulaContainer.data)
        }
      } finally {
        this.load = false
      }
    },
    getFormulas() {
      this.loading = true
      let URLDecoded = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (URLDecoded == 'create') {
        if (this.$store.getters['utils/getIsloading']) {
          this.$store.dispatch('utils/actionLoading')
          this.$route.meta.title = this.$t('create', { model: '' })
        }
        this.formula = {
          pk: '',
          name: '',
          attributes: [],
          formula: [],
          model: false
        }
        this.dialogName = true
      } else {
        this.$api.formula
          .show({ pk: URLDecoded })
          .then((res) => {
            this.formula = res.data
            this.$route.meta.title = this.$t('edit', { model: '' })
          })
          .catch(() => this.$router.post({ name: 'formula' }))
      }
      this.getUom()
    }
  },
  watch: {
    searchAttributes(val) {
      if (val != undefined && val.length >= 2) {
        this.isLoading = true
        this.getUom(val)
      } else {
        this.getUom()
      }
    },
    'formula.attributes': {
      async handler(val) {
        this.show_attrs = await this.i_formula_attrs(val)
      }
    },
    'formula.formula': {
      async handler(val) {
        this.show_formula_example = await this.i_formula_formula({
          formula: val,
          attributes: this.formula.attributes
        })
        this.show_formula_example = this.show_formula_example['formula']
      }
    },
    show_formula(val) {
      if (val == 'formulaNoValid' || val == 'formulaDivisionZero') {
        this.$toast.error(`${this.$tc(val, 1)}`)
      }
    }
  },
  mounted() {
    if (this.container === 'container') {
      this.getFormulas()
    }
  }
}
</script>
<style lang="sass">
.formula-pk
  &--alert-title
    margin-bottom: 12px
    border-bottom: 1px solid var(--v-info-base)
    padding-bottom: 8px
    line-height: 1.5rem
  &--formula-container
    display: grid
    grid-template-columns: 1fr 14px 6fr
    align-items: center
    gap: 5px
  &--formula-name
    display: block
    padding: .5rem
    color: #FFFFFF
    font-weight: 700
    border-radius: 18px
    text-align: center
    background-color: var(--v-primary-base)
  &--chip-container
    padding: 20px
    background-color: rgb(0 0 0 / 14%)
    border-radius: 18px
    width: 100%
    & > span
      display: flex
      gap: 5px
      flex-wrap: wrap
  &--chip-formula
    font-size: 18px
    line-height: 18px
    &:hover
      cursor: grab
    &.elementList
      span.v-chip__content
        padding-left: 5px
        padding-right: 5px
    // .v-chip__close
    //   display: none
  &--operator-chip
    min-height: 34px
    min-width: 34px
    align-items: center
    justify-content: center
    &.v-chip.v-size--default
      font-size: 18px
      line-height: 18px
  &--zero
    width: 112px

.theme--dark
  .formula-pk--chip-container
    background-color: rgb(255 255 255 / 14%)

.attibutes.v-select.v-select--chips:not(.v-text-field--single-line)
  .v-select__selections
    min-height: 42px
</style>
