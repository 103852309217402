<route>
{
  "meta": {
    "permission": [
      "product.view_priceformula",
      "product.view_priceformulaattributes"
    ]
  }
}
</route>

<template>
  <v-container fluid class="formula">
    <v-tabs
      color="primary"
      prev-icon="fa-chevron-left"
      next-icon="fa-chevron-right"
      centered
      show-arrows
      :icons-and-text="$vuetify.breakpoint.smAndUp"
      v-model="tabs"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab v-if="formulaBtn.view" href="#tab-formula">
        <span
          v-if="$vuetify.breakpoint.smAndUp"
          class="mt-2"
          v-text="$t('formula')"
        ></span>
        <v-icon :large="$vuetify.breakpoint.smAndUp" dark>fa-cube</v-icon>
      </v-tab>
      <v-tab v-if="formulaBtn.view" href="#tab-formula-model">
        <span
          v-if="$vuetify.breakpoint.smAndUp"
          class="mt-2"
          v-text="$t('formula_model')"
        ></span>
        <v-icon :large="$vuetify.breakpoint.smAndUp" dark>fa-clone</v-icon>
      </v-tab>
      <v-tab
        v-if="formulaBtn.view && formulaBtn.attributes"
        href="#tab-attributes"
      >
        <span
          v-if="$vuetify.breakpoint.smAndUp"
          class="mt-2"
          v-text="$t('formulattributes')"
        ></span>
        <v-icon :large="$vuetify.breakpoint.smAndUp" dark
          >fa-puzzle-piece</v-icon
        >
      </v-tab>
      <v-tabs-items v-model="tabs" class="py-5">
        <v-tab-item value="tab-formula">
          <v-card v-if="tabs === 'tab-formula'" class="px-2" flat>
            <i-table
              :title="$tc('formula', 1)"
              :headers="headers"
              app="product.priceformula"
              api="formula"
              :opt="{ model: 0 }"
              @click:create="changeRoute({ pk: 'create' })"
              @click:edit="changeRoute"
            >
              <template v-slot:item.name="{ value }">
                {{ $tc(value.toLowerCase(), 1) }}
              </template>
              <template v-slot:item.attributes="{ item }">
                <v-skeleton-loader
                  v-if="
                    attributes == undefined || attributes[item.pk] == undefined
                  "
                  type="chip"
                  class="mx-auto"
                ></v-skeleton-loader>
                <div v-else>
                  <v-chip
                    v-for="attr_selected in attributes[item.pk].attributes"
                    class="mr-1"
                    color="secondary"
                    small
                    text-color="white"
                    :key="'chips-' + attr_selected.pk"
                  >
                    {{ $tc(attr_selected.name, 1) }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.formula="{ item }">
                <v-skeleton-loader
                  v-if="
                    attributes == undefined || attributes[item.pk] == undefined
                  "
                  type="text"
                  class="mx-auto"
                ></v-skeleton-loader>
                <span v-else>
                  {{ attributes[item.pk].formula }}
                </span>
              </template>
            </i-table>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-formula-model">
          <v-card v-if="tabs === 'tab-formula-model'" class="px-2" flat>
            <i-table
              :title="$tc('formula', 1)"
              :headers="headers"
              app="product.priceformula"
              api="formula"
              :opt="{ model: 1 }"
              @click:create="changeRoute({ pk: 'create' })"
              dontEdit
              otherBtn
            >
              <template v-slot:otherBtn="{ item }">
                <i-btn
                  :title="$t('clone')"
                  outlined
                  classes="mx-1"
                  icon="fa-clone"
                  color="info"
                  @click="clone(item)"
                />
              </template>
              <template v-slot:item.name="{ value }">
                {{ $tc(value.toLowerCase(), 1) }}
              </template>
              <template v-slot:item.attributes="{ item }">
                <v-skeleton-loader
                  v-if="
                    attributes == undefined || attributes[item.pk] == undefined
                  "
                  type="chip"
                  class="mx-auto"
                ></v-skeleton-loader>
                <div v-else>
                  <v-chip
                    v-for="attr_selected in attributes[item.pk].attributes"
                    class="mr-1"
                    color="secondary"
                    small
                    text-color="white"
                    :key="'chips-' + attr_selected.pk"
                  >
                    {{ $tc(attr_selected.name, 1) }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.formula="{ item }">
                <v-skeleton-loader
                  v-if="
                    attributes == undefined || attributes[item.pk] == undefined
                  "
                  type="text"
                  class="mx-auto"
                ></v-skeleton-loader>
                <span v-else>
                  {{ attributes[item.pk].formula }}
                </span>
              </template>
            </i-table>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-attributes">
          <attrs v-if="tabs === 'tab-attributes'" ref="attrsTab"></attrs>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>
<script>
import attrs from '../attributes/index.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    attrs
  },
  data() {
    return {
      formulas: [],
      attributes: {},
      loading: false,
      tabs: 'tab-formula'
    }
  },
  computed: {
    ...mapGetters({
      show_formula: 'formula/show_formula',
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    formulaBtn() {
      return {
        delete:
          this.getPermissions([`product.delete_priceformula`]) || this.isAdmin,
        edit:
          this.getPermissions([`product.change_priceformula`]) || this.isAdmin,
        view:
          this.getPermissions([`product.view_priceformula`]) || this.isAdmin,
        attributes:
          this.getPermissions([`product.view_priceformulaattributes`]) ||
          this.isAdmin
      }
    },
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.formulaBtn.attributes ? this.$t('attributes') : '',
          value: this.formulaBtn.attributes ? 'attributes' : ''
        },
        {
          text: this.$t('formula'),
          value: 'formula'
        },
        {
          text:
            this.formulaBtn.delete || this.formulaBtn.edit
              ? this.$t('action')
              : '',
          value: this.formulaBtn.delete || this.formulaBtn.edit ? 'actions' : ''
        }
      ]
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'formula-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    async getFormula() {
      this.loading = true
      const formula = await this.$api.formula.list({})
      this.formulas = formula.data.results
      if (this.$store.getters['utils/getIsloading']) {
        this.$store.dispatch('utils/actionLoading')
      }
      let attributes = {}
      for (let i = 0; i < this.formulas.length; i++) {
        let form = this.formulas[i]
        let attrs = await this.show_formula({
          formula: form.formula,
          attributes: form.attributes
        })
        attributes[form.pk] = attrs
      }
      this.attributes = attributes
      this.loading = false
    },
    clone(item) {
      console.log(item)
      this.loading = true
      this.$api.formula
        .clone({ pk: item.pk })
        .then(() => {
          this.$toast.success(`${this.$t('formula')} ${this.$tc('created', 1)}`)
          this.getFormula()
          this.loading = false
          this.tabs = 'tab-formula'
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    this.getFormula()
  }
}
</script>
<style>
.v-autocomplete__content .v-list__tile--active {
  background-color: var(--primary) !important;
}
.v-autocomplete__content .v-list__tile--active .v-list__tile__title,
.v-autocomplete__content .v-list__tile--active .v-list__tile__sub-title {
  color: white !important;
}
.elementList span.v-chip__content {
  padding-right: 5px;
  padding-left: 20px;
}
.elementList .v-chip__close {
  display: none;
}
.elementList:hover .v-chip__close {
  display: flex;
  margin-right: -5px !important;
}
.elementList:hover {
  padding-right: 0px;
}
</style>
