const path = 'backups/'

const list = ({ opt = {} }) => axios.get(path, opt)
const list2 = ({ opt = {} }) => axios.get(`${path}list-dumpdata-backup/`, opt)
const create = () => axios.post(`${path}backup-select-schema/`, {})
const remove = ({ pk }) => axios.delete(`${path}${pk}/remove/`)
const sendEmail = ({ pk, opt = {} }) =>
  axios.get(`${path}${pk}/send-email-dumpdata/`, opt)

export default { list, create, list2, remove, sendEmail }
