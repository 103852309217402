<template>
  <v-menu open-on-click bottom offset-y>
    <!-- Activador del menú, que muestra la campana de notificaciones -->
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        offset-x="30"
        offset-y="10"
        color="secondary"
        bottom
        :content="
          notifications.length == ''
            ? '0' // Muestra '0' si no hay notificaciones
            : notifications.length > 99
            ? '+99' // Muestra '+99' si hay más de 99 notificaciones
            : notifications.length
        "
      >
        <v-btn
          dark
          text
          v-bind="attrs"
          v-on="on"
          style="border: ridge 1px transparent"
        >
          <v-img
            @click="getEventsNotification"
            :src="
              mode == 'notifications'
                ? require('@/assets/color-icons/settings/notifications.png')
                : require('@/assets/color-icons/toolbar/notification.png')
            "
            height="32"
            width="32"
          ></v-img>
        </v-btn>
      </v-badge>
    </template>

    <!-- Contenedor para la lista de notificaciones con scroll -->
    <div
      style="
        max-height: 500px; /* Altura máxima del área de desplazamiento */
        overflow-y: auto; /* Habilitar scroll */
        width: 400px; /* Ancho fijo del contenedor */
      "
    >
      <!-- Lista de notificaciones si hay alguna -->
      <v-list
        v-if="notifications.length > 0"
        style="border: ridge 1px transparent; padding: 0px"
      >
        <v-list-item
          @click="open(item)"
          v-for="(item, index) in notifications"
          :key="index"
          text-color="primary"
          :style="{
            'background-color': item.color + ' !important',
            border: 'ridge 1px #DCDCDC',
            'font-size': '13px',
            width: '100%',
            color: item.statusDark || item.paid ? 'white' : 'black'
          }"
        >
          <!-- Notificaciones de subOrdenes -->
          <v-list
            class="py-0"
            dense
            v-if="mode === 'events'"
            :style="{
              'background-color': item.color + ' !important',
              border: 'ridge 1px transparent',
              'font-size': '13px',
              width: '100%',
              color: item.statusDark || item.paid ? 'white' : 'black'
            }"
          >
            <span style="border: ridge 1px transparent">
              <v-icon class="mr-3" size="15" style="color: #345d48">
                fas fa-tag
              </v-icon>
              <b>{{ $tc('suborder', 1) }}:</b>
              <span
                class="item.statusDark || item.paid ? 'white' : 'black'--text"
              >
                {{ item.title }}
              </span>
              {{ $t('next_sub_state') }}:
              <span
                class="item.statusDark || item.paid ? 'white' : 'black'--text"
              >
                {{ $tc(item.statusName?.split(' - ')[0], 1) }} -
                {{ $tc(item.statusName?.split(' - ')[1], 1) }}
              </span>
              <span
                class="item.statusDark || item.paid ? 'white' : 'black'--text"
              >
                {{ item.updated_date }}
              </span>
            </span>
            <v-list-group @click.stop="" style="display: none">
              <v-icon
                slot="appendIcon"
                size="12"
                :color="item.statusDark || item.paid ? 'white' : 'black'"
              >
                fas fa-arrow-down
              </v-icon>
              <template v-slot:activator>
                <v-list-item-content text-color="primary" class="mb-2 ma-0">
                  <v-list-item-title></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-col
                cols="4"
                :style="{
                  'background-color': item.color + ' !important',
                  'font-size': '13px',
                  width: '100%',
                  color: item.statusDark || item.paid ? 'white' : 'black'
                }"
              >
                <v-col
                  v-for="date in item.type_dates"
                  :key="`date-${date.name}`"
                >
                  <span>{{ date.name }}:</span>
                  <span
                    class="item.statusDark || item.paid ? 'white' : 'black'--text"
                  >
                    {{ dateFormat(date.date) }}
                  </span>
                </v-col>
                <v-col>
                  {{ $t('promise_date') }}:
                  <span
                    class="item.statusDark || item.paid ? 'white' : 'black'--text"
                  >
                    {{ dateFormat(item.promise_date) }}
                  </span>
                </v-col>
                <suborder
                  class="primary--text front-weight-primary"
                  :order="item.order"
                  :subOrder.sync="item.subOrder"
                  :calendar="true"
                  :miniSubOrder.sync="subOrder"
                ></suborder>
              </v-col>
            </v-list-group>
          </v-list>

          <!-- Notificaciones de gastos, mantenimiento de equipos y chat -->
          <v-list-item-title
            v-if="mode === 'notifications'"
            style="border: ridge 1px transparent; font-size: 13px"
          >
            <!-- Notificaciones de chat -->
            <div v-if="item.content_type === 'message'">
              <v-icon class="mr-3" size="15" style="color: #345d48">
                fas fa-comments
              </v-icon>
              {{ $t(item.title) }} {{ item.details }}
            </div>

            <!-- Notificaciones de mantenimientos -->
            <div v-if="item.content_type === 'maintenance'">
              <v-icon class="mr-3" size="15" style="color: #345d48">
                fas fa-wrench
              </v-icon>
              {{ $t(item.content_type) }}
              : {{ item.title }} {{ item.date }}
            </div>

            <!-- Notificaciones de gastos -->
            <div v-if="item.content_type === 'expense'">
              <v-icon class="mr-3" size="15" style="color: #345d48">
                fas fa-money-bill-alt
              </v-icon>
              {{ $tc(item.content_type, 2) }}
              : {{ item.title }} {{ item.date }}
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item>
          <v-list-item-title>{{ $t('no_notifications') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import suborder from '../sale/MiniSuborder.vue'

export default {
  components: {
    suborder
  },
  props: {
    mode: {
      type: String
    }
  },
  data() {
    return {
      notifications: [],
      current: 0,
      subOrder: {}
    }
  },
  computed: {
    ...mapGetters({
      getTotalNotifications: 'notifications/getTotalNotifications',
      isAdmin: 'session/isAdmin',
      me: 'session/me'
    })
  },
  methods: {
    open(item) {
      this.current = item.pk
      this.$router.push({
        name:
          item.content_type === 'expense'
            ? 'expensesRecurring-pk'
            : item.content_type === 'maintenance'
            ? 'maintenance-pk'
            : item.content_type === 'message'
            ? 'sale'
            : '',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    /**
     * getEventsNotification
     * Método para consultar los eventos de:
     *  Gastos recurrentes, mantenimiento de equipo recurrente y subOrdenes de
     *  venta.
     * Incluye los filtros:
     *  - Date:
     *    - Las notificaciones de gastos y mantenimiento de equipos no pagados,
     *      se muestran con texto negro.
     *    - Si se paga y no está vencida cambia su texto a color blanco y se
     *      muestra por 15 días, pasando al final de la lista.
     *    - Si se paga y está vencida, desaparece de la lista.
     *  - Mode: Notificación para la campana de gastos y mantenimiento de equipo
     *  y evento para la campana de sobOrdenes.
     *  - User: para mostrar al usuario no administrador sólo las notificaciones
     *   que le corresponden, mientras que el administrador las puede visualizar
     *   todas.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getEventsNotification() {
      const back_date = this.$moment()
        .subtract(365, 'days')
        .format('YYYY-MM-DD')
      const start_date = this.$moment()
        .subtract(15, 'days')
        .format('YYYY-MM-DD')
      const end_date = this.$moment().add(1, 'days').format('YYYY-MM-DD')
      await this.$api.event
        .list({
          opt: {
            params: {
              date__back: back_date,
              date__gte: start_date,
              date__lte: end_date,
              mode: this.mode,
              user: this.isAdmin ? null : this.me.id
            }
          }
        })
        .then((response) => {
          let res_events = response.data
          let notifications = res_events.map((event) => {
            return {
              pk:
                event.model === 'expense'
                  ? event.expense
                  : event.model === 'maintenance'
                  ? event.maintenance
                  : event.identifier,
              title: event.title,
              details: event.details,
              date: event.date,
              color:
                event.subOrder != undefined
                  ? event.statusColor
                  : event.background,
              app_label: event.app_label,
              content_type: event.model,
              statusName: event.statusName,
              statusDark: event.statusDark,
              paid: event.paid,
              order: event.order,
              subOrder: event.subOrder,
              promise_date: event.promise_date,
              type_dates: event.type_dates
            }
          })
          this.notifications = notifications
          for (const notification of this.notifications) {
            this.$store.dispatch('notifications/addNotification', notification)
          }
        })
    }
  },
  mounted() {
    this.getEventsNotification()
  }
}
</script>
