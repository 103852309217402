export default {
  namespaced: true,
  state: {
    notifications: [],
    designer_notifications: []
  },
  getters: {
    getTotalNotifications: (state) => {
      return String(state.notifications.length)
    },
    allNotifications: (state) => {
      return state.notifications
    }
  },
  mutations: {
    ['ADD_NOTIFICATION'](state, notification) {
      state.notifications.push(notification)
    }
  },
  actions: {
    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification)
    }
  }
}
