<template>
  <i-modal
    :title="
      soldby.pk != undefined
        ? `${$t('edit')} ${$tc('soldby', 1)}`
        : `${$t('add', { value: $tc('soldby', 1) })}`
    "
    :value="value"
    :load="load"
    :max-width="'1200px'"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-card-text>
      <v-row class="my-0">
        <!-- Redondeo -->
        <v-col class="pt-0" cols="12" sm="6">
          <v-skeleton-loader v-if="load" type="button" tile></v-skeleton-loader>
          <v-switch
            v-show="!load"
            class="v-switch mt-0"
            color="primary"
            key="rounded-input"
            :label="$tc('rounded', 2)"
            v-model="soldby.rounded"
          ></v-switch>
        </v-col>

        <!-- Por defecto -->
        <v-col class="pt-0" cols="12" sm="6">
          <v-skeleton-loader v-if="load" type="button" tile></v-skeleton-loader>
          <v-switch
            v-show="!load"
            class="v-switch mt-0"
            color="primary"
            key="default-input"
            :disabled="firstRegister"
            :label="$t('default')"
            v-model="soldby.default"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row class="my-0">
        <!-- Unidad -->
        <v-col class="pt-0" cols="12" sm="6">
          <dialsold
            v-model="dialog2"
            :to-edit="toEdit2"
            ref="dialsold"
            @done="addSold"
          />
          <v-skeleton-loader
            v-if="load"
            type="list-item-avatar"
            tile
          ></v-skeleton-loader>
          <ValidationProvider
            vid="measure"
            :name="$tc('measure', 1)"
            rules="required"
            v-slot="{ errors }"
          >
            <v-autocomplete
              outlined
              v-model="soldby.soldBy"
              autocomplete="off"
              :error-messages="errors[0]"
              :items="measures"
              :loading="isLoading"
              :clearable="measures.length > 10"
              :search-input.sync="searchMeasure"
              class="secondary--text"
              :item-text="nameSold"
              item-value="pk"
              key="measure-input"
              :append-icon="measures.length > 10 ? 'fa-search' : ''"
              :prepend-inner-icon="
                getPermissions([`base_config.add_soldby`]) || isAdmin
                  ? 'fa-plus'
                  : ''
              "
              :label="$tc('measure', 1)"
              @click:prepend-inner="open()"
            ></v-autocomplete>
          </ValidationProvider>
        </v-col>

        <!-- Fórmula -->
        <v-col class="pt-0" cols="12" sm="6">
          <dialformula
            container="modal"
            v-model="formulafield"
            @updated="addFormula"
          ></dialformula>
          <v-skeleton-loader
            v-if="load"
            type="list-item-avatar"
            tile
          ></v-skeleton-loader>
          <ValidationProvider
            vid="formula"
            :name="$tc('formula', 1)"
            rules="required"
            v-slot="{ errors }"
          >
            <v-autocomplete
              outlined
              v-model="soldby.formula"
              autocomplete="off"
              :clearable="formulas.length > 10"
              :error-messages="errors[0]"
              :items="formulas"
              :loading="isLoading2"
              :search-input.sync="searchFormula"
              class="secondary--text"
              item-text="name"
              item-value="pk"
              key="formula-input"
              :append-icon="formulas.length > 10 ? 'fa-search' : ''"
              :prepend-inner-icon="
                getPermissions([`product.add_priceformula`]) || isAdmin
                  ? 'fa-plus'
                  : ''
              "
              :label="$t('formula')"
              @click:prepend-inner="formulafield = true"
            ></v-autocomplete>
          </ValidationProvider>
        </v-col>

        <!-- Ejemplo -->
        <v-col class="pt-0" cols="12">
          <v-alert
            v-show="soldby.formula != null"
            class="font-weight-black title mb-0"
            color="primary"
            outlined
            type="info"
            :value="true"
          >
            <p>{{ $t('example') }}:</p>
            <i-formula
              v-if="soldby.formula != null"
              showFormulaString
              :formula="soldby.formula"
              :isExtraCost="true"
              @change="example.price = $event"
              v-model="example.priceString"
            />
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </i-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import dialformula from '../../pages/formula/_pk.vue'
import dialsold from '../sold/modal.vue'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    product: {
      required: true,
      type: Number
    }
  },
  components: {
    dialsold,
    dialformula
  },
  data() {
    return {
      formulafield: false,
      load: false,
      step: 1,
      searchMeasure: '',
      measures: [],
      measure: null,
      isLoading: false,
      searchFormula: '',
      formulas: [],
      formula: null,
      isLoading2: false,
      firstRegister: false,
      example: {
        priceString: '',
        price: 0
      },
      soldby: {
        product: this.product,
        formula: null,
        soldBy: null,
        rounded: false,
        default: false
      },
      edit: false,
      dialog2: false,
      toEdit2: null
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    })
  },
  methods: {
    open(item) {
      this.toEdit2 = item === undefined ? null : item
      this.dialog2 = true
    },
    nameSold: (item) =>
      item.abbr == null || item.abbr == ''
        ? item.name
        : `${item.name} (${item.abbr})`,
    async getSolds(item, first) {
      this.firstRegister = first
      this.load = true
      this.soldby =
        item != undefined
          ? JSON.parse(JSON.stringify(item))
          : {
              pk: '',
              product: this.product,
              formula: null,
              soldBy: null,
              rounded: false,
              default: this.firstRegister
            }
      if (this.soldby.formula != null) {
        let formula = await this.$api.formula.show({
          pk: this.soldby.formula
        })
        if (formula.status == 200) {
          this.formula = formula.data
        } else {
          this.soldby.formula = null
        }
      }
      if (this.soldby.soldBy != null) {
        let soldBy = await this.$api.sold.show({
          pk: this.soldby.soldBy
        })
        if (soldBy.status == 200) {
          this.soldBy = soldBy.data
        } else {
          this.soldby.soldBy = null
        }
      }
      this.load = false
    },
    addFormula(evt) {
      this.formulas.push(evt)
      this.soldby.formula = evt.pk
    },
    addSold(evt) {
      this.measures.push(evt)
      this.soldby.soldBy = evt.pk
    },
    getMeasure() {
      this.isLoading = true
      this.$api.sold
        .list({})
        .then((response) => {
          this.measures = response.data.results
        })
        .finally(() => (this.isLoading = false))
    },
    getFormula() {
      this.isLoading2 = true
      this.$api.formula
        .list({})
        .then((response) => {
          this.formulas = response.data.results
        })
        .finally(() => (this.isLoading2 = false))
    },
    async submit() {
      if (!this.load) {
        try {
          this.load = true
          !this.edit
            ? await this.$api.product.measure.create({
                form: this.soldby
              })
            : await this.$api.product.measure.edit({
                pk: this.soldby.pk,
                form: this.soldby
              })
          this.$emit('done')
          this.$emit('input', false)
          this.$emit('first')
          this.$toast.success(
            `${this.$tc('measure', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.load = false
        }
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.soldby =
            this.toEdit !== null
              ? { ...this.toEdit }
              : {
                  product: this.product,
                  formula: null,
                  soldBy: null,
                  rounded: false,
                  default: this.firstRegister
                }
        }
      }
    },
    'soldby.soldBy': {
      handler(val) {
        let inx = this.measures.findIndex((m) => val == m.pk)
        if (inx > -1) {
          this.measure = this.measures[inx]
        }
      }
    },
    'soldby.formula': {
      handler(val) {
        let inx = this.formulas.findIndex((m) => val == m.pk)
        if (inx > -1) {
          this.formula = this.formulas[inx]
        }
      }
    },
    searchMeasure(val) {
      if (val == null || (typeof val === 'string' && val.length % 2 == 0)) {
        this.getMeasure()
      }
    },
    searchFormula(val) {
      if (val == null || (typeof val === 'string' && val.length % 2 == 0)) {
        this.getFormula()
      }
    }
  }
}
</script>
