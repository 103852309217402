<route>
  {
    "meta": {
      "permission": [
        "equipements.view_maintenance",
        "equipements.change_maintenance"
      ]
    }
  }
</route>

<template>
  <v-container fluid>
    <i-table
      :title="$tc('equipment_maintenance', 2)"
      :headers="headers"
      app="equipements.maintenance"
      api="maintenance"
      @click:create="changeRoute({ pk: 'create' })"
      @click:edit="changeRoute"
      :opt="{ equipement_id: equipmentId }"
    >
      <template v-slot:item.date="{ item }">
        <span v-text="dateFormat(item.date)"></span>
      </template>
      <template v-slot:item.next_date="{ item }">
        <span v-text="dateFormat(item.next_date)"></span>
      </template>
    </i-table>
  </v-container>
</template>

<script>
export default {
  name: 'maintenance',
  data() {
    return {
      equipmentId: 0
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('date'), value: 'date' },
        { text: this.$t('next_date'), value: 'next_date' },
        { text: this.$t('description'), value: 'description' },
        { text: this.$t('operator'), value: 'operator' },
        { text: this.$t('action'), value: 'actions' }
      ]
    }
  },
  methods: {
    /**
     * getEquipment
     * Método para consultar el equipo según el identificador proporcionado en
     * la ruta.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getEquipment() {
      this.load = true
      const equipmentParam = this.$route.params.equipment
      this.equipmentId = equipmentParam
        ? this.codification({ mode: 'decode', code: equipmentParam })
        : 0
      if (this.equipmentId === 0) this.$router.push({ name: 'equipment' })
      this.load = false
    },
    /**
     * changeRoute
     * Método para cambiar la ruta al formulario de mantenimiento de equipo.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    changeRoute(item) {
      this.$router.push({
        name: 'maintenance-pk',
        params: {
          pk: this.codification({ code: item.pk }),
          equipment: this.codification({ code: this.equipmentId })
        }
      })
    }
  },
  mounted() {
    this.getEquipment()
  }
}
</script>
