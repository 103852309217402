<route>
  {
    "meta": {
      "permission": [
        "equipements.add_maintenance",
        "equipements.change_maintenance"
      ]
    }
  }
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :title="
              maintenance.pk
                ? `${$t('edit')} ${$t('equipment_maintenance')}`
                : `${$t('add', { value: $t('equipment_maintenance') })}`
            "
            :loading="load"
          ></i-toolbar>
          <v-card-text>
            <v-row class="py-5">
              <!-- Fecha de realización del mantenimiento de equipo -->
              <v-col cols="12" sm="3">
                <i-date-picker
                  v-model="maintenance.date"
                  :load="load"
                  :label="$tc('date', 1)"
                  color="primary"
                />
              </v-col>

              <!-- Tipo de mantenimiento -->
              <v-col cols="12" sm="3">
                <ValidationProvider
                  vid="mode"
                  :name="$t('mode')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    outlined
                    :error-messages="errors[0]"
                    v-model="maintenance.mode"
                    item-text="state"
                    item-value="abbr"
                    :items="maintenanceModes"
                    :label="$t('mode')"
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <!-- Operador -->
              <v-col cols="12" sm="3">
                <ValidationProvider
                  vid="operator"
                  :name="$t('operator')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="maintenance.operator"
                    :error-messages="errors[0]"
                    :label="$t('operator')"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Descripción del mantenimiento -->
              <v-col cols="12" sm="3">
                <ValidationProvider
                  vid="description"
                  :name="$t('description')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="maintenance.description"
                    :error-messages="errors[0]"
                    :label="$t('description')"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Tipo de pago -->
              <v-col cols="12" sm="3">
                <ValidationProvider
                  vid="pay"
                  :name="$t('paymenttype')"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-select
                    autocomplete="off"
                    outlined
                    :error-messages="errors[0]"
                    v-model="maintenance.pay"
                    item-text="state"
                    item-value="abbr"
                    :items="paymentTypes"
                    :label="$t('paymenttype')"
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <!-- Pago referencial -->
              <v-col cols="12" sm="3">
                <ValidationProvider
                  vid="expense"
                  :name="$t('payment_ref')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    :disabled="maintenance.recurrent && maintenance.pk > 0"
                    v-model="maintenance.expense"
                    :error-messages="errors[0]"
                    :label="$t('payment_ref')"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Botón de clonación de pago -->
              <v-col
                cols="1"
                v-if="maintenance.recurrent && maintenance.pk > 0"
              >
                <i-btn
                  :title="$tc('clone', 1)"
                  outlined
                  classes="mx-1"
                  icon="fa-clone"
                  color="secondary"
                  @click="clonePayment"
                />
              </v-col>

              <!-- Monto de pago -->
              <v-col
                cols="12"
                sm="2"
                v-if="maintenance.pk > 0 && maintenance.recurrent"
              >
                <ValidationProvider
                  vid="payment"
                  :name="$tc('payment', 1)"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    :error-messages="errors[0]"
                    type="number"
                    step="0.01"
                    v-model="maintenance.payment"
                    color="secondary"
                    :label="$tc('payment', 1)"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'maintenance-pk',
  data() {
    return {
      tabs: 'tab-recurrent',
      load: false,
      showMaintenances: false,
      maintenance: {
        date: '',
        operator: '',
        description: '',
        equipement_id: 0,
        expense: 0.0,
        recurrent: false,
        payment: 0.0,
        day: ''
      },
      maintenanceModes: [
        { state: 'Corrective', abbr: 'corrective' },
        { state: 'Preventive', abbr: 'preventive' }
      ],
      paymentTypes: [
        { state: this.$t('cc'), abbr: 'cc' },
        { state: this.$t('cash'), abbr: 'cash' },
        { state: this.$t('check'), abbr: 'check' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    })
  },
  methods: {
    /**
     * submit
     * Método que envía los datos de mantenimiento de equipo.
     * Dependiendo de si hay un identificador de mantenimiento, se crea un nuevo
     * registro o se edita uno existente.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submit() {
      try {
        this.load = true
        const response = this.maintenance.pk
          ? await this.$api.maintenance.edit({
              pk: this.maintenance.pk,
              form: this.maintenance
            })
          : await this.$api.maintenance.create({
              form: this.maintenance
            })

        this.$toast.success(
          `${this.$tc('equipment_maintenance', 1)} ${this.$tc(
            this.maintenance.pk ? 'edited' : 'created',
            2
          )}`
        )
        if (!this.maintenance.pk) {
          const audio = new Audio(require('@/../public/sounds/sound.mp3'))
          audio.play()
        }
        this.maintenance.equipement_id
          ? this.changeRoute(this.maintenance)
          : this.$router.push({ name: 'equipment' })
      } finally {
        this.load = false
      }
    },
    /**
     * getMaintenance
     * Método para consultar los datos de mantenimiento según el identificador
     * proporcionado en la ruta.
     * Si se trata de una creación, inicializa un nuevo objeto de mantenimiento;
     * de lo contrario, carga los datos del mantenimiento existente.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getMaintenance() {
      this.loading = true
      const validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      const equipmentId = this.codification({
        mode: 'decode',
        code: this.$route.params.equipment
      })
      this.maintenance.equipement_id = equipmentId
      validation === 'create'
        ? this.initializeMaintenance(equipmentId)
        : this.loadMaintenance(validation)
    },
    /**
     * initializeMaintenance
     * Método para inicializar un nuevo objeto de mantenimiento.
     *
     * @param {string} equipmentId
     *        - ID del equipo que se asociará al mantenimiento.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    initializeMaintenance(equipmentId) {
      if (this.$store.getters['utils/getIsloading']) {
        this.$store.dispatch('utils/actionLoading')
        this.$route.meta.title = this.$t('create', { model: '' })
      }
      this.maintenance = {
        date: '',
        operator: '',
        description: '',
        equipement_id: equipmentId,
        expense: 0.0,
        recurrent: false,
        payment: 0.0,
        day: ''
      }
    },
    /**
     * loadMaintenance
     * Método para cargar los datos de un mantenimiento existente
     * a partir del identificador proporcionado.
     *
     * @param {string} pk - ID del mantenimiento a cargar.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    loadMaintenance(pk) {
      this.$api.maintenance
        .show({ pk: pk })
        .then((res) => {
          this.maintenance = res.data
          this.$route.meta.title = this.$t('edit', { model: '' })
        })
        .catch(() => this.changeRoute(this.maintenance))
    },
    changeRoute(item) {
      this.$router.push({
        name: 'maintenance',
        params: {
          equipment: this.codification({ code: item.equipement_id })
        }
      })
    },
    clonePayment() {
      this.maintenance.payment = this.maintenance.expense
    }
  },
  mounted() {
    this.getMaintenance()
  }
}
</script>
