<route>
  {
    "meta": {
      "permission": [
        "product.add_product",
        "product.change_product"
      ]
    }
  }
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <v-form
          @submit.prevent="handleSubmit(submit)"
          enctype="multipart/form-data"
        >
          <i-toolbar
            dontSave
            :loading="load"
            :title="product.pk != '' ? product.name : $tc('product', 1)"
          >
            <template v-slot:arrowLeft>
              <v-divider class="mx-4" inset vertical></v-divider>
              <i-btn
                v-if="step > 1"
                color="text-dark"
                icon="fa-arrow-left"
                text
                :title="$t('back')"
                @click="back()"
              >
              </i-btn>
            </template>
            <template v-slot:arrowRight>
              <i-btn
                v-if="showNext"
                color="text-dark"
                icon="fa-arrow-right"
                text
                :small="$vuetify.breakpoint.smAndDown"
                :title="$t('next')"
                @click="next()"
              >
              </i-btn>
            </template>
            <template v-slot:submit>
              <i-btn
                color="text-dark"
                icon="fa-save"
                text
                :loading="load"
                :title="$tc('save', 1)"
                @click="submit((closed = true))"
              ></i-btn>
            </template>
          </i-toolbar>
          <v-card-text class="p-0">
            <v-stepper v-model="step">
              <v-stepper-header class="elevation-0" style="z-index: 1">
                <!-- Paso 1 -->
                <v-stepper-step
                  :step="1"
                  edit-icon="$complete"
                  :editable="editable(1)"
                >
                  {{ $t('basic') }}
                </v-stepper-step>

                <!-- Paso 2 -->
                <template v-if="product.price_type && product.pk">
                  <v-divider></v-divider>
                  <v-stepper-step
                    edit-icon="$complete"
                    :step="2"
                    :editable="editable(2)"
                  >
                    {{ $t('specific') }}
                  </v-stepper-step>
                </template>

                <!-- Paso 3 -->
                <template
                  v-if="view.matrix && view.matrixTemplate && product.pk"
                >
                  <v-divider></v-divider>
                  <v-stepper-step
                    edit-icon="$complete"
                    :step="3"
                    :editable="editable(3)"
                  >
                    {{
                      $tc(
                        product.price_type === 'matrix' ? 'matrix' : 'cost',
                        2
                      )
                    }}
                  </v-stepper-step>
                </template>

                <!-- Paso 4a -->
                <template v-if="product.price_type !== 'matrix' && product.pk">
                  <v-divider></v-divider>
                  <v-stepper-step
                    edit-icon="$complete"
                    :editable="editable(4)"
                    :step="4"
                  >
                    {{ $t('sales_prices') }}
                  </v-stepper-step>
                </template>

                <!-- Paso 4b -->
                <template
                  v-if="
                    view.process &&
                    product.price_type === 'matrix' &&
                    product.pk &&
                    product.template != null
                  "
                >
                  <v-divider></v-divider>
                  <v-stepper-step
                    edit-icon="$complete"
                    :editable="editable(4)"
                    :step="4"
                  >
                    {{ $t('production_process') }}
                  </v-stepper-step>
                </template>

                <!-- Paso 5 -->
                <template
                  v-if="
                    view.process &&
                    product.price_type !== 'matrix' &&
                    product.pk &&
                    product.template != null
                  "
                >
                  <v-divider></v-divider>
                  <v-stepper-step
                    edit-icon="$complete"
                    :editable="editable(5)"
                    :step="5"
                  >
                    {{ $t('production_process') }}
                  </v-stepper-step>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <!-- Paso 1 -->
                <v-stepper-content :step="1">
                  <v-row>
                    <!-- Imagen del producto -->
                    <v-col
                      class="align-center justify-center align-self-center"
                      cols="12"
                      md="5"
                    >
                      <v-skeleton-loader
                        v-if="loading || loadingIMG"
                        type="image"
                        tile
                      ></v-skeleton-loader>
                      <i-image
                        v-else
                        v-model="imageUrl"
                        :file.sync="product.image"
                      />
                    </v-col>

                    <!-- Datos básicos -->
                    <v-col cols="12" md="7" class="mb-5">
                      <!-- Modelo -->
                      <div
                        v-if="!loading && isAdmin"
                        class="w-100 mb-4 border rounded-lg"
                      >
                        <v-switch
                          class="v-switch mt-0 pt-0 px-3"
                          color="primary"
                          name="tax"
                          hide-details
                          :label="$tc('model_product')"
                          v-model="product.model"
                        ></v-switch>
                      </div>
                      <v-row class="my-0">
                        <!-- Código -->
                        <v-col
                          v-if="product.internalCode != ''"
                          cols="12"
                          sm="6"
                        >
                          <v-text-field
                            color="secondary"
                            disabled
                            outlined
                            :label="$tc('code', 1)"
                            v-model="product.internalCode"
                          />
                        </v-col>

                        <!-- Tipo de precio -->
                        <v-col v-if="product.pk" cols="12" sm="6">
                          <v-text-field
                            color="secondary"
                            disabled
                            outlined
                            :label="$tc('price', 3)"
                            v-model="product.price_type"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="my-0">
                        <!-- Activo -->
                        <v-col
                          v-show="product.pk"
                          class="pt-0"
                          cols="6"
                          md="3"
                          @click="msgActive"
                        >
                          <v-switch
                            v-show="!loading"
                            class="v-switch"
                            color="primary"
                            name="active"
                            :disabled="validToActivate"
                            :label="$t('active')"
                            @click="changedByUser()"
                            v-model="product.active"
                          ></v-switch>
                        </v-col>

                        <!-- Impuesto -->
                        <v-col class="pt-0" cols="6" md="3">
                          <v-switch
                            v-show="!loading"
                            class="v-switch"
                            color="primary"
                            name="tax"
                            :label="$tc('globaltax', 1)"
                            v-model="product.tax"
                          ></v-switch>
                        </v-col>

                        <!-- Procesos de producción (template) -->
                        <v-col
                          v-show="
                            !loading && product.pk === '' && view.template
                          "
                          class="pt-0"
                          cols="6"
                          md="3"
                        >
                          <v-switch
                            class="v-switch"
                            color="primary"
                            name="template"
                            :disabled="product.price_type === 'matrix'"
                            :label="$t('production_process')"
                            v-model="template"
                          ></v-switch>
                        </v-col>

                        <!-- Inventariable -->
                        <v-col class="pt-0" cols="6" md="3">
                          <v-switch
                            v-show="!loading"
                            class="v-switch"
                            color="primary"
                            name="inventoriable"
                            :label="$tc('inventoriable', 1)"
                            v-model="inventoriable"
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-row class="my-0">
                        <!-- Nombre de producto -->
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            vid="name"
                            :name="$tc('name', 1)"
                            :rules="{ required: step == 1 }"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-show="!loading"
                              color="secondary"
                              outlined
                              :error-messages="errors[0]"
                              :label="$tc('name', 1)"
                              v-model="product.name"
                            />
                          </ValidationProvider>
                        </v-col>

                        <!-- Tipo de precio -->
                        <v-col v-if="!product.pk" cols="12" sm="6">
                          <ValidationProvider
                            vid="price"
                            :name="$tc('price', 3)"
                            :rules="{ required: step == 1 }"
                            v-slot="{ errors }"
                          >
                            <v-select
                              autocomplete="off"
                              item-text="state"
                              item-value="abbr"
                              outlined
                              :disabled="product.pk !== ''"
                              :error-messages="errors[0]"
                              :items="[
                                { state: $t('base'), abbr: 'base' },
                                { state: $t('formula'), abbr: 'formula' },
                                { state: $t('matrix'), abbr: 'matrix' }
                              ]"
                              :label="$tc('price', 3)"
                              v-model="product.price_type"
                              @change="getTemplates"
                            ></v-select>
                          </ValidationProvider>
                        </v-col>

                        <!-- Plantilla -->
                        <v-col
                          v-if="view.template && template && product.price_type"
                          cols="12"
                          sm="6"
                        >
                          <dialtemplate
                            container="modal"
                            v-model="dialog"
                            :to-edit="toEdit"
                            @done="addTemplate"
                          />
                          <ValidationProvider
                            vid="template"
                            :name="$tc('template', 1)"
                            :rules="{
                              required: template && step == 1
                            }"
                            v-slot="{ errors }"
                          >
                            <v-autocomplete
                              class="secondary--text"
                              autocomplete="off"
                              :clearable="templates.length > 10"
                              outlined
                              :error-messages="errors[0]"
                              :items="templates"
                              item-text="name"
                              item-value="pk"
                              :label="$tc('template', 1)"
                              :append-icon="
                                templates.length > 10 ? 'fa-search' : ''
                              "
                              :prepend-inner-icon="
                                view.addtemplate ? 'fa-plus' : ''
                              "
                              @click:prepend-inner="dialog = true"
                              v-model="product.template"
                            ></v-autocomplete>
                          </ValidationProvider>
                        </v-col>

                        <!-- Stock mínimo -->
                        <v-col
                          v-if="!loading && inventoriable"
                          cols="12"
                          sm="6"
                        >
                          <ValidationProvider
                            vid="minStock"
                            :name="$tc('minStock', 1)"
                            :rules="{ required: inventoriable && step == 1 }"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              color="secondary"
                              key="name-input"
                              min="0"
                              outlined
                              type="number"
                              :error-messages="errors[0]"
                              :label="$tc('minStock', 1)"
                              v-model="product.min_stock"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!-- Descripción -->
                    <v-col cols="12">
                      <label>{{ $t('description') }}</label>
                      <vue-editor
                        :editorToolbar="customToolbar"
                        v-model="product.description"
                      ></vue-editor>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <!-- Paso 2 -->
                <v-stepper-content :step="2">
                  <v-row class="my-0">
                    <!-- Condicional: Tipo de precio > Fórmula -->
                    <v-col
                      v-if="
                        product.pk &&
                        view.formula &&
                        product.price_type === 'formula'
                      "
                      cols="12"
                      md="6"
                    >
                      <measure
                        v-show="product.pk !== ''"
                        ref="measure"
                        @changeSolds="totalMeasure = $event"
                        @firstCreation="firstMeasure = true"
                        :product="product.pk"
                      />
                    </v-col>

                    <!-- Campos normales -->
                    <v-col>
                      <v-row>
                        <!-- ​Días de entrega -->
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            vid="turnaround"
                            :name="$tc('turnaround', 1)"
                            :rules="{ required: step === 2 }"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-show="!loading"
                              color="secondary"
                              outlined
                              type="number"
                              min="0"
                              :error-messages="errors[0]"
                              :label="$tc('turnaround', 1)"
                              v-model="product.turnaround"
                            />
                          </ValidationProvider>
                        </v-col>

                        <!-- Código de barras -->
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            vid="sku_code"
                            :name="$tc('sku_code', 1)"
                            :rules="{
                              numeric: true,
                              max: 13
                            }"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              outlined
                              v-show="!loading"
                              v-model="product.skuCode"
                              :error-messages="errors[0]"
                              :label="$t('sku_code')"
                              color="secondary"
                              key="sku_code-input"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>

                        <!-- Departamento de producción -->
                        <v-col v-if="view.department" cols="12" sm="6">
                          <dialdepartment
                            v-model="dialog2"
                            :to-edit="toEdit2"
                            :product="true"
                            @done="addDepartment"
                          />
                          <ValidationProvider
                            vid="department"
                            :name="$tc('department', 1)"
                            :rules="{
                              required: step === 2
                            }"
                            v-slot="{ errors }"
                          >
                            <v-autocomplete
                              v-show="!loading"
                              class="secondary--text"
                              autocomplete="off"
                              :clearable="departments.length > 10"
                              item-text="name"
                              item-value="pk"
                              key="department-input"
                              outlined
                              :error-messages="errors[0]"
                              :items="departments"
                              :label="`${$t('department')}`"
                              :loading="isLoading4"
                              :append-icon="
                                departments.length > 10 ? 'fa-search' : ''
                              "
                              :prepend-inner-icon="
                                getPermissions([
                                  `base_config.add_productiondepartment`
                                ]) || isAdmin
                                  ? 'fa-plus'
                                  : ''
                              "
                              :search-input.sync="searchDepartment"
                              @click:prepend-inner="dialog2 = true"
                              v-model="product.department"
                            ></v-autocomplete>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <!-- Paso 3 -->
                <v-stepper-content :step="3">
                  <!-- Condicional: Tipo de precio > Matriz -->
                  <v-row
                    v-if="
                      view.matrix &&
                      view.matrixTemplate &&
                      product.price_type === 'matrix' &&
                      step === 3
                    "
                  >
                    <v-col cols="12">
                      <i-matrix
                        :productId="product.pk"
                        :main_matrix="product.main_matrix"
                        :showMatrix="true"
                        :matrixUse="'product'"
                        :detailProd="null"
                        :name="product.name"
                        :active.sync="hasMatrix"
                      >
                      </i-matrix>
                    </v-col>
                  </v-row>

                  <!-- Condicional: Tipo de precio > !Matriz -->
                  <v-row v-if="product.price_type !== 'matrix'">
                    <v-col cols="12" sm="6">
                      <ValidationProvider
                        vid="pricePurchase"
                        :name="$tc('pricepurchase', 1)"
                        :rules="{
                          required: step === 3,
                          min_value: step === 3 ? '0.01' : '0'
                        }"
                        v-slot="{ errors }"
                      >
                        <i-money
                          v-show="!loading"
                          number
                          :error-messages="errors[0]"
                          :label="`${$tc('pricepurchase', 1)}`"
                          @change="verifyPriceSuggest"
                          v-model="product.pricePurchase"
                        ></i-money>
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="font-weight-black primary--text title align-self-center pt-0 pb-5"
                    >
                      <i-money
                        mode="text"
                        :label="`${$t('cost_suggest')} = `"
                        :value="product.cost"
                      />
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <!-- Paso 4a - Sin matrix -->
                <v-stepper-content
                  v-if="product.price_type !== 'matrix'"
                  :step="4"
                >
                  <v-row class="pt-5">
                    <v-col cols="12" md="6">
                      <v-skeleton-loader
                        v-if="loading"
                        type="text"
                        tile
                      ></v-skeleton-loader>
                      <ValidationProvider
                        vid="priceSuggest"
                        :name="$tc('pricesuggest', 1)"
                        v-slot="{ errors }"
                        :rules="{
                          required: step == 4,
                          min_value: step == 4 ? product.pricePurchase : '0'
                        }"
                      >
                        <i-money
                          v-show="!loading"
                          key="priceSuggest-input"
                          :error-messages="errors[0]"
                          :label="$t('pricesuggest')"
                          v-model="product.priceSuggest"
                        ></i-money>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row v-if="step === 4 && product.pk != '' && view.price">
                    <v-col cols="12" md="6">
                      <prices
                        mode="product"
                        :cost="product.cost"
                        :product="product.pk"
                        :template="product.template"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <prices
                        mode="customer"
                        :cost="product.cost"
                        :product="product.pk"
                      />
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <!-- Paso 4b - Con matrix -->
                <v-stepper-content v-else :step="4">
                  <v-col
                    v-if="
                      product.pk != '' &&
                      view.process &&
                      step === 4 &&
                      product.price_type === 'matrix' &&
                      product.template != null
                    "
                  >
                    <dialtemplate :product="product" />
                  </v-col>
                </v-stepper-content>

                <!-- Paso 5  - Con procesos de producción -->
                <v-stepper-content :step="5">
                  <v-col
                    v-if="
                      product.pk != '' &&
                      view.process &&
                      step === 5 &&
                      product.price_type !== 'matrix' &&
                      product.template != null
                    "
                  >
                    <dialtemplate :product="product" />
                  </v-col>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>
<script>
import { VueEditor } from 'vue2-editor'
import { mapGetters } from 'vuex'
import dialdepartment from '../../components/department/modal.vue'
import measure from '../../components/product/measure.vue'
import prices from '../../components/product/price.vue'
import dialtemplate from '../templatesProduct/_pk.vue'

export default {
  components: {
    VueEditor,
    dialdepartment,
    measure,
    prices,
    dialtemplate
  },
  data() {
    return {
      loading: false,
      load: false,
      isLoading4: false,
      loadingIMG: false,
      valid: false,
      step: 1,
      searchDepartment: '',
      departments: [],
      department: null,
      dialog: false,
      dialog2: false,
      toEdit: null,
      toEdit2: null,
      templates: [],
      partialProduct: true,
      imageUrl: null,
      totalExtraCost: 0,
      totalSustrate: 0,
      totalMeasure: 0,
      firstMeasure: false,
      validation: '',
      dontSpecialPrice: false,
      productPrev: null,
      pricePurchaseAux: 0,
      inventoriable: false,
      disabledByUser: false,
      hasMatrix: false,
      template: false,
      product: {
        pk: '',
        image: null,
        internalCode: '',
        name: '',
        skuCode: '',
        description: '',
        dinamic_cost: false,
        template: null,
        priceSuggest: 0,
        cost: 0,
        model: false,
        pricePurchase: 0,
        active: false,
        turnaround: 0,
        department: null,
        can_be: '',
        favorite: false,
        tax: true,
        min_stock: 0,
        price_type: '',
        company_id: ''
      },
      customToolbar: [
        [{ header: [] }],
        [
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'code',
          'code-block'
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }]
      ]
    }
  },
  methods: {
    verifyPriceSuggest() {
      if (this.product.pricePurchase > this.product.priceSuggest) {
        this.product.priceSuggest = this.product.pricePurchase
      }
    },
    msgActive() {
      if (!this.isActive) this.$toast.error(this.$tc('cant_active'))
    },
    changedByUser() {
      this.disabledByUser = true
    },
    /**
     * editable
     * Método para hacer que los v-stepper-step puedan ser clickables solamente
     * cuando el producto es activo y no es el paso que está activo
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    editable(st) {
      if (!this.isActive && !this.product.active) return false
      if (st == this.step) return false
      return true
    },
    /**
     * back
     * Método para volver atrás en caso que pase la validación
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    back() {
      this.$refs.form.validate().then((result) => {
        if (
          result ||
          (this.product.price_type === 'formula' && this.step == 2) ||
          (this.product.price_type === 'matrix' && this.step == 3)
        ) {
          this.step -= 1
        } else {
          let errors = Object.entries(this.$refs.form.errors)
          errors.forEach((error) => {
            if (error[1].length) {
              this.$toast.error(error[1].toString())
            }
          })
        }
      })
    },
    /**
     * next
     * Método para ir adelante en caso que pase la validación
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    next() {
      if (this.product.department == null && this.department != null) {
        this.product.department = this.department.pk
      }
      this.$refs.form.validate().then((result) => {
        if (result) {
          if (
            this.step == 1 ||
            (this.step == 2 && this.product.department !== null) ||
            (this.step == 3 && (this.product.cost > 0 || this.hasMatrix)) ||
            (this.step == 4 && this.product.priceSuggest > 0)
          ) {
            this.step += 1
          } else {
            this.$toast.error(this.$tc('required'))
          }
        } else {
          let errors = Object.entries(this.$refs.form.errors)
          errors.forEach((error) => {
            if (error[1].length) {
              this.$toast.error(error[1].toString())
            }
          })
        }
      })
    },
    async getDepartments() {
      const department_id = await this.$api.department.list({
        opt: {
          params: {
            company_id: this.company.pk
          }
        }
      })
      this.departments = department_id.data.results
    },
    addTemplate(evt) {
      this.templates.push(evt)
      this.product.template = evt.pk
    },
    async getTemplates() {
      const template = await this.$api.product.template.list({
        opt: {
          params: {
            company: this.company.pk
          }
        }
      })
      if (this.product.price_type !== 'matrix') {
        this.templates = template.data.filter((m) => m.main_matrix === null)
      } else {
        this.templates = template.data.filter((m) => m.main_matrix > 0)
      }
    },
    async getProduct() {
      this.validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (this.validation == 'create') {
        if (this.$store.getters['utils/getIsloading']) {
          this.$store.dispatch('utils/actionLoading')
        }
        this.product = {
          pk: '',
          image: null,
          internalCode: '',
          name: '',
          skuCode: '',
          description: '',
          dinamic_cost: false,
          template: null,
          priceSuggest: 0,
          cost: 0,
          model: false,
          pricePurchase: 0,
          active: false,
          turnaround: 0,
          department: null,
          can_be: '',
          favorite: false,
          min_stock: 0,
          price_type: '',
          company_id: ''
        }
      } else {
        try {
          this.loading = true
          let response = await this.$api.product.show({ pk: this.validation })
          this.product = response.data
          this.pricePurchaseAux = this.product.pricePurchase
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
        }
      }
      try {
        this.imageUrl = this.product.image
        this.loadingIMG = false
      } catch (error) {
        this.imageUrl = require('@/assets/default_image.png')
        this.loadingIMG = false
      }
      if (this.product.department != null) {
        try {
          let department = await this.$api.department.show({
            pk: this.product.department
          })
          this.departments = [department.data]
        } catch (err) {
          this.product.department = null
        }
      } else {
        this.getDepartments()
      }
    },
    async save(data) {
      this.productPrev = await this.$api.product.create({
        form: data
      })
      this.product = this.productPrev.data
      this.load = false
      this.$toast.success(`${this.$tc('product', 1)} ${this.$tc('created', 2)}`)
    },
    async edit(data, closed) {
      this.productPrev = await this.$api.product.edit({
        pk: this.product.pk,
        form: data
      })
      this.product = this.productPrev.data
      this.dialog = false
      this.$toast.success(
        `${this.$tc('product', 1)} ${this.$tc(
          this.product.pk !== '' ? 'edited' : 'created',
          2
        )}`
      )
      if (closed) {
        this.$router.push({ name: 'product' })
      } else {
        this.load = false
      }
    },
    async submit(closed) {
      if (this.product.min_stock == '') this.product.min_stock = 0
      if (!this.inventoriable) this.product.can_be = 'SALE'
      if (this.isActive) {
        this.partialProduct = false
        if (!this.disabledByUser && !this.product.active) {
          this.product.active = true
        }
      }
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.product.company_id = this.company.pk
          let form_data = new FormData()
          for (var key in this.product) {
            if (this.product[key] == null) {
              continue
            }
            form_data.append(key, this.product[key])
            if (
              key == 'image' &&
              (this.product.image == null ||
                String(this.imageUrl).indexOf('http') != -1 ||
                String(this.product.image).indexOf(';base64') != -1 ||
                String(this.product.image).indexOf('http') != -1 ||
                String(this.imageUrl).indexOf('/sources/') != -1)
            ) {
              form_data.delete('image')
            }
          }
          this.load = true
          if (this.isNewProduct) {
            this.$swal({
              title: this.$t('add_product'),
              text: this.$t('add_product_partial'),
              icon: 'info',
              background: 'var(--v-auxbg-base)',
              color: 'var(--v-auxtext-base)',
              showCancelButton: true,
              cancelButtonText: this.$t('cancel'),
              confirmButtonColor: 'var(--v-primary-base)',
              confirmButtonText: this.$t('add_product')
            }).then((result) => {
              if (result.isConfirmed) {
                try {
                  this.save(form_data)
                } catch (error) {
                  console.error(error)
                }
              }
              this.load = false
            })
          } else {
            if (this.partialProduct && closed == true) {
              this.product.dinamic_cost = true
              this.product.active = false
              this.$swal({
                title: this.$t('edit_product'),
                text: this.$t('edit_product_partial'),
                icon: 'info',
                background: 'var(--v-auxbg-base)',
                color: 'var(--v-auxtext-base)',
                showCancelButton: true,
                cancelButtonText: this.$t('cancel'),
                confirmButtonColor: 'var(--v-primary-base)',
                confirmButtonText: this.$t('edit_product')
              }).then((result) => {
                if (result.isConfirmed) {
                  try {
                    this.edit(form_data, closed)
                  } catch (error) {
                    console.error(error)
                  }
                }
                this.load = false
              })
            } else {
              this.edit(form_data, closed)
            }
          }
        } else {
          this.$toast.error(this.$tc('required'))
        }
      })
    },
    async createPrices() {
      await this.$api.product.pricescreate({
        opt: {
          params: {
            product: this.product.pk,
            mode: 'product',
            company_id: this.company.pk
          }
        }
      })
    },
    addDepartment(evt) {
      this.getDepartments()
      this.product.department = evt.pk
    }
  },
  computed: {
    /**
     * isNewProduct
     * Computado para verificar si el producto es nuevo o se está editando
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    isNewProduct() {
      return this.step === 1 && !this.product.pk
    },
    /**
     * validToActivate
     * Computado para validar si el producto puede activarse
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    validToActivate() {
      const { price_type, cost, priceSuggest, department, active } =
        this.product
      const hasMatrixCondition = price_type === 'matrix' && this.hasMatrix
      const isCostValid = cost > 0 && priceSuggest >= cost
      const hasFormulaCondition =
        price_type === 'formula' && isCostValid && this.totalMeasure > 0

      if (active) {
        return false
      }

      if (department === null) {
        return true
      }

      if (hasMatrixCondition) {
        return false
      }

      if (hasFormulaCondition) {
        return false
      }

      if (isCostValid) {
        return false
      }

      return true
    },
    /**
     * isActive
     * Computado para verificar si el producto está activo
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    isActive() {
      const { price_type, cost, priceSuggest, department } = this.product
      const isCostValid = cost > 0 && priceSuggest >= cost
      const hasMatrixCondition = price_type === 'matrix' && this.hasMatrix
      const hasFormulaCondition =
        price_type === 'formula' && isCostValid && this.totalMeasure > 0

      if (department === null) {
        return false
      }

      if (hasMatrixCondition) {
        return true
      }

      if (price_type === 'formula') {
        return hasFormulaCondition
      }

      return isCostValid
    },
    showNext() {
      if (this.step === 1) {
        if (this.view.formula && this.product.pk) return true
      }
      if (this.step === 2) {
        if (this.product.pk) return true
      }
      if (this.step === 3) {
        if (this.product.price_type !== 'matrix') this.createPrices()
        if (
          this.product.price_type !== 'matrix' ||
          (this.view.process &&
            this.product.template != null &&
            this.product.price_type === 'matrix')
        )
          return true
      }
      if (this.step === 4) {
        if (
          this.view.process &&
          this.product.price_type !== 'matrix' &&
          this.product.template != null
        )
          return true
      }
      return false
    },
    computed_cost() {
      return this.product.pricePurchase
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      company: 'company/getCompanyData',
      isAdmin: 'session/isAdmin'
    }),
    view() {
      return {
        addtemplate:
          this.getPermissions([`product.add_producttemplate`]) || this.isAdmin,
        template:
          this.getPermissions([`product.add_producttemplate`]) || this.isAdmin,
        department:
          this.getPermissions([`base_config.view_productiondepartment`]) ||
          this.isAdmin,
        formula:
          this.getPermissions([`product.view_priceformula`]) || this.isAdmin,
        matrix: this.getPermissions([`matrix.view_matrix`]) || this.isAdmin,
        matrixTemplate:
          this.getPermissions([`matrix.view_matrixtemplate`]) || this.isAdmin,
        price:
          this.getPermissions([`product.view_productprices`]) || this.isAdmin,
        process: this.getPermissions([`product.view_process`]) || this.isAdmin
      }
    }
  },
  watch: {
    totalMeasure(val) {
      if (val === 0) {
        this.product.active = false
      }
    },
    computed_cost(val) {
      this.product.cost = val
    },
    /*
    'product.department': {
      handler(val) {
        let inx = this.departments.findIndex((m) => val == m.pk)
        if (inx > -1) {
          this.department = this.departments[inx]
        }
      }
    },
    */
    // 'product.pricePurchase': {
    //   handler(val) {
    //     if (val > this.product.priceSuggest) {
    //       this.product.priceSuggest = val
    //     }
    //   }
    // },
    inventoriable: {
      handler(val) {
        val == true
          ? (this.product.can_be = 'PURCHASE')
          : (this.product.can_be = 'SALE')
      }
    },
    'product.can_be': {
      handler(val) {
        val == 'PURCHASE' || val == 'BOTH'
          ? (this.inventoriable = true)
          : (this.inventoriable = false)
      }
    },
    step: {
      handler(val) {
        if (
          this.product.pk &&
          this.product.price_type !== 'matrix' &&
          val !== 3
        ) {
          if (this.pricePurchaseAux != this.product.pricePurchase) {
            this.submit()
            this.pricePurchaseAux = this.product.pricePurchase
          }
        } else {
          this.submit()
        }
      }
    },
    firstMeasure(val) {
      if (val) {
        this.product.active = true
        this.firstMeasure = false
      }
    },
    searchDepartment(val) {
      if (val == null || (typeof val === 'string' && val.length % 2 == 0)) {
        this.getDepartments()
      }
    },
    'product.price_type': {
      handler(val) {
        val === 'matrix' && this.product.pk === ''
          ? (this.template = true)
          : (this.template = false)
      }
    }
  },
  mounted() {
    this.getProduct(this.$route.params.code)
    this.getDepartments()
  }
}
</script>
<style lang="sass" scoped></style>
