<template>
  <div>
    <v-menu open-on-click bottom offset-y>
      <!-- Activador del menú, que muestra la campana de notificaciones -->
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          offset-x="30"
          offset-y="10"
          color="secondary"
          bottom
          :content="
            notifications.length == ''
              ? '0' // Muestra '0' si no hay notificaciones
              : notifications.length > 99
              ? '+99' // Muestra '+99' si hay más de 99 notificaciones
              : notifications.length
          "
        >
          <v-btn dark text v-bind="attrs" v-on="on">
            <v-img
              @click="getDesignerNotification"
              :src="require('@/assets/color-icons/settings/users.png')"
              height="32"
              width="32"
            ></v-img>
          </v-btn>
        </v-badge>
      </template>

      <!-- Lista de notificaciones si hay alguna -->
      <v-list
        v-if="notifications.length > 0"
        :style="{
          'max-height': '70vh',
          'overflow-x': 'scroll'
        }"
      >
        <v-list-item
          @click="open(item)"
          v-for="(item, index) in notifications"
          :key="index"
          text-color="primary"
          :style="{
            'font-weight': 700,
            width: '100%'
          }"
          class="mb-2 ma-0"
        >
          <v-col>
            <v-list-item-title>
              {{ $t('designer') }}:
              <span>
                {{ item.user_data.name }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle class="text--primary">
              <span>
                {{ item.suborder_number }}
              </span>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="text--secondary">
              {{ $tc('suborder', 1) }}:
              <span>
                {{ item.suborder_name }}
              </span>
            </v-list-item-subtitle>

            <v-list-item-subtitle>
              {{ $t('suborderstatus') }}:
              <span>
                {{ $t(item.suborder_status) }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ $t('assignament_date') }}:
              <span>
                {{ formatDate(item.assignament_date) }}
              </span>
            </v-list-item-subtitle>
          </v-col>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item>
          <v-list-item-title>{{ $t('no_notifications') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- Diálogo de subOrden -->
    <dialprod ref="dialprod" @prod="getSuborder($event)"></dialprod>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialprod from '../sale/DialogProduct.vue'
export default {
  name: 'designer-notification',
  components: {
    dialprod
  },
  data() {
    return {
      notifications: [],
      current: 0,
      status: {},
      suborders: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData',
      me: 'session/me'
    })
  },
  methods: {
    open(item) {
      this.current = item.suborder_id
      this.$refs.dialprod.open(item.suborder_id)
    },
    formatDate(date) {
      return this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    async getDesignerNotification() {
      await this.$api.suborder.assigneduser
        .notificationByDesigner({ pk: this.company.pk })
        .then((response) => {
          this.notifications = response.data
        })
    },
    async getSuborder() {
      this.loading = true
      try {
        const response = await this.$api.sale.product.show({
          pk: this.current,
          opt: { params: { order: true } }
        })

        this.suborders = response.data

        await Promise.all(this.suborders.map(this.fetchStatusForSuborder))
        this.getDesignerNotification()
      } catch (error) {
        console.error('Error fetching suborders:', error)
      } finally {
        this.loading = false
      }
    },
    async fetchStatusForSuborder(suborder) {
      const templateId = suborder.template

      if (templateId > 0 && !this.postStatus[templateId]) {
        const stagesResponse = await this.$api.sale.status.list({
          opt: { params: { template: templateId } }
        })
        this.postStatus[templateId] = stagesResponse.data
      }

      const stages = this.postStatus[templateId] || []
      const statuses = stages
        .filter((stage) => stage.name !== 'preprocess')
        .map((stage) => this.calculateStageStatus(stage, suborder))

      this.$set(this.status, suborder.pk, statuses)
      this.$emit('update:miniSubOrder', suborder)
      this.$emit('done', suborder)
    },
    calculateStageStatus(stage, suborder) {
      let value = suborder.orderMain === 'finished' ? 100 : 0
      const subStatus = stage.substatusVals.find(
        (item) => item.pk === suborder.status
      )
      this.percent = suborder.orderStatus.includes('preprocess') ? 0 : 100

      if (subStatus) {
        this.root = subStatus.root
        this.percent = Math.round(
          ((subStatus.position - 2) * 100) /
            Math.max(stage.substatusVals.length - 2, 1)
        )
      }

      if (stage.pk > this.root) {
        this.percent = 0
      }

      const isPositioned = this.calculateStatusValue(stage, suborder)
      if (isPositioned) {
        value = Math.min(Math.max(value, 0), 100)
      }

      return {
        pk: stage.pk,
        name: stage.name,
        color: stage.color,
        value: Math.max(0, Math.min(this.percent, 100))
      }
    },
    calculateStatusValue(stage, suborder) {
      if (suborder.mainStatus === 'finished' || suborder.status === null) {
        return false
      }
      if (stage.pk === suborder.status || stage.substatusVals.length === 0) {
        return true
      }
      return stage.substatusVals.some(
        (substatus) => substatus.pk === suborder.status
      )
    }
  },
  mounted() {
    this.getDesignerNotification()
  }
}
</script>
