<route>
{
  "meta": {
    "permission": [
      "subdomains.view_subdomain"
    ]
  }
}
</route>

<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <form @submit.prevent="handleSubmit(submit)">
            <v-card-title>
              <i-toolbar :title="$t('backup_schemas')" :loading="load">
              </i-toolbar>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <i-table
                    dontEdit
                    dontCreate
                    dontRemove
                    :title="$tc('list_schemas', 2)"
                    :headers="headers"
                    api="backup"
                    method="list2"
                    otherBtn
                    otherBtn2
                  >
                    <template v-slot:otherBtn="{ item }">
                      <i-btn
                        :title="$tc('download', 1)"
                        outlined
                        classes="mx-1"
                        icon="fa-file-export"
                        color="info"
                        @click="jsonDowload(item.id, item.route)"
                      />
                    </template>
                    <template v-slot:otherBtn2="{ item }">
                      <i-btn
                        :title="`${$tc('send', 0)} ${$tc('email', 1)}`"
                        outlined
                        classes="mx-1"
                        icon="fa-paper-plane"
                        color="success"
                        @click="sendEmail(item.id)"
                      />
                    </template>
                  </i-table>
                </v-col>
              </v-row>
            </v-card-text>
          </form>
        </ValidationObserver>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export let base =
  process.env.NODE_ENV === 'production' ||
  (process.env.VUE_APP_DEBUG_SERVER !== undefined &&
    process.env.VUE_APP_DEBUG_SERVER)
    ? `${process.env.VUE_APP_SERVER_API_TYPE}${
        !(
          process.env.VUE_APP_DEBUG_SERVER !== undefined &&
          process.env.VUE_APP_DEBUG_SERVER
        )
          ? location.hostname
          : 'iprintpos.com'
      }${process.env.VUE_APP_SERVER_API_PORT}`
    : process.env.VUE_APP_BACKEND_URL

export default {
  data() {
    return {
      load: false,
      loading: false,
      schema: '',
      total: 0,
      next: null,
      prev: null,
      perPage: 10,
      page: 1,
      search: '',
      schemas: [
        {
          route: '',
          date_create: '',
          schema: ''
        }
      ]
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc('name', 1),
          value: 'filename'
        },
        {
          text: this.$t('date'),
          value: 'date_create'
        },
        {
          text: this.$t('action'),
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    getSchemas() {
      this.$api.backup
        .list({})
        .then((response) => {
          let i = 0
          for (let schema of response.data) {
            ;(response.data[i].date_create = this.$moment(
              schema.date_create
            ).format('DD/MM/YYYY - HH:mm:ss')),
              (i += 1)
          }
          this.schemas = response.data
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          if (this.$store.getters.getIsloading) {
            this.$store.dispatch('actionLoading')
          }
          this.loading = false
        })
    },
    submit() {
      this.load = true
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$api.backup
            .create()
            .then((response) => {
              this.$toast.success(`${this.$t('save_backup')}`)
              this.schema = ''
              setTimeout(() => this.getSchemas(), 5000)
            })
            .catch((err) => {
              //
            })
        }
      })
      this.load = false
    },
    jsonDowload(id, route) {
      let url = base.split('/')
      url = url.splice(0, 3).join('/')
      axios
        .get(`${url}${route}`)
        .then((response) => {
          let name = route.split('/').splice(3).join('/')
          var dataStr = +encodeURIComponent(JSON.stringify())
          const data = JSON.stringify(response.data, 0, 4)
          const blob = new Blob([data], {
            type: 'data:text/json;charset=utf-8,'
          })
          const e = document.createEvent('MouseEvents'),
            a = document.createElement('a')
          a.download = name
          a.href = window.URL.createObjectURL(blob)
          a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
          e.initEvent(
            'click',
            true,
            false,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          )
          a.dispatchEvent(e)
        })
        .finally(() => {
          if (this.$store.getters.getIsloading) {
            this.$store.dispatch('actionLoading')
          }
          this.loading = false
          this.$toast.success(`${this.$t('save_send_backup')}`)
        })
      // it is possible to enable if it is required to send email after downloading the file
      //this.sendEmail(id)
    },
    remove(pk) {
      this.$store.dispatch('confirmationValue', {
        show: true,
        model: this.$tc('schema', 1),
        title: this.$tc('delete', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.$tc('schema', 1),
          action: this.$tc('delete', 2)
        })}`,
        confirm: () => this.$api.backup.remove({ pk })
      })
      this.getSchemas()
    },
    sendEmail(id_backup) {
      this.$api.backup.sendEmail({ pk: id_backup }).then((response) => {
        this.$toast.success(`${this.$t('send_email_schema')}`)
      })
    }
  },
  mounted() {
    this.getSchemas()
    if (this.$store.getters.getIsloading) {
      this.$store.dispatch('actionLoading')
    }
  }
}
</script>
