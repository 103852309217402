<template>
  <i-modal
    :title="$t('add', { value: $tc('label', 1) })"
    :value="value"
    :field-value.sync="label.nameLabel"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
  </i-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    subOrder: {
      type: Number
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      label: {
        nameLabel: '',
        subOrder: this.subOrder
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.label =
            this.toEdit !== null
              ? { ...this.toEdit }
              : { name: '', subOrder: this.subOrder }
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          let response = await this.$api.suborder.labels.create({
            form: this.label
          })
          this.$emit('done', response.data)
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('label', 1)} ${this.$tc('created', 1)}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
